const awsAuth = {

  dev: {
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-1_PaEXm47j8",
  
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "5tnll0pc87f8ureagokc9efbv9",
  
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",
  
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "dev-pri-mix-ui-mixcodes-app-auth.auth.us-east-1.amazoncognito.com",
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: "http://localhost:3000,https://ui.dev.codes.coke.com",
      redirectSignOut: "http://localhost:3000,https://ui.dev.codes.coke.com",
      responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  staging: {
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-1_H44K0FBpE",
  
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "7hkcliiumbapge7t0sd2fbu1tb",
  
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",
  
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "stg-pri-mix-auth-mixcodes-app-auth.auth.us-east-1.amazoncognito.com",
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://ui.stg.codes.coke.com",
      redirectSignOut: "https://ui.stg.codes.coke.com",
      responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  preprod: {
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-1_adHngAnLc",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "23rdtm7fuqqc5mifhmd5up8uu9",

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "ppd-pri-mix-auth-mixcodes-app-auth.auth.us-east-1.amazoncognito.com",
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://ui.ppd.codes.coke.com",
      redirectSignOut: "https://ui.ppd.codes.coke.com",
      responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },


  prod: {
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-1_6frp5rJsS",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "5v300prbqfbf5ph1jvinrv74qh",

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "prd-pri-mix-auth-mixcodes-app-auth.auth.us-east-1.amazoncognito.com",
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://ui.prd.codes.coke.com",
      redirectSignOut: "https://ui.prd.codes.coke.com",
      responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }

};

export default awsAuth;
