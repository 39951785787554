"use client";

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";
import DisabledInput from "../components/DisabledInput";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { axiosPost, axiosPut } from "../utils/axios";
import fetcher from "../utils/fetcher";
import { Link } from "react-router-dom";
import Custom500 from "../error/500";

const defaultValues = {
  receipientOrg: "",
  codesPerFile: "",
  batchItems: [],
};

export default function BatchForm({
  open,
  handleClose,
  editMode,
  programId,
  release,
  program,
  lots,
  batch,
}) {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState({});
  const [organizationOptions, setOrganizationOptions] = useState([]);

  const {
    data: oragnizations,
    error: organizationError,
    isLoading: organizationLoading,
  } = useSWR(`${process.env.REACT_APP_DIST_URL}/orgs`, fetcher);

  let neworganizationKV = [];

  useEffect(() => {
    if (oragnizations) {
      let organizationKV = oragnizations.filter(
        (org) => program.programSummary.ownerGroup === org.ownerGroup,
      );
      neworganizationKV = organizationKV.map((org) => ({
        label: org.name,
        value: org.id,
      }));
      neworganizationKV.sort((a, b) => (a.label > b.label ? 1 : -1));
      setOrganizationOptions(neworganizationKV);
    }
  }, [oragnizations]);

  const {
    data: batchData,
    error,
    isLoading,
  } = useSWR(
    editMode
      ? `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/batches/${batch.id}`
      : null,
    fetcher,
  );
  const {
    data: batchItemData,
    error: batchItemError,
    isLoading: batchItemLoading,
  } = useSWR(
    release
      ? `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/batchItems`
      : null,
    fetcher
  );
  console.log("batchItemData:::", batchItemData);
  // Save new batch
  const onSubmit = handleSubmit((inputs) => {
    inputs.codesPerFile = Number(inputs.codesPerFile);
    console.log("inputs:::", inputs);
    editMode ? updateBatch(inputs) : createBatch(inputs);
  });

  const handleRegenerateCode = (batchItem) => {
    axiosPost(
      `${process.env.REACT_APP_CODEGEN_URL}/codegen/batches/${batchItem.batchId}/batch-items/${batchItem.id}/batch-jobs`,
    )
      .then((res) => {
        setStatus({ state: "success", message: "Code Generated Successfully" });
        mutate(`${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases`);
      })
      .catch((err) => {
        console.log(err);
        setStatus({ state: "error", message: "Unable to Generate code" });
      });
  };

  function createBatch(payload) {
    setIsSubmitting(true);
    axiosPost(
      `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/batches`,
      payload,
    )
      .then(({ data }) => {
        console.log(data);
        setStatus({ state: "success", message: "Batch created successfully" });
        closeForm();
      })
      .catch((error) => {
        console.log(error);
        setStatus({ state: "error", message: "Unable to create batch" });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  function updateBatch(payload) {
    setIsSubmitting(true);
    axiosPut(
      `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/batches/${batch.id}`,
      payload,
    )
      .then(({ data }) => {
        console.log(data);
        setStatus({ state: "success", message: "Batch updated successfully" });
        closeForm();
      })
      .catch((error) => {
        console.log(error);
        setStatus({ state: "error", message: "Unable to update batch" });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  let editBatchItems = [];

  if (editMode) {
    // Set Batch Details
    const batchDetails = batchData.batchDetails;
    const fields = ["id", "name", "receipientOrg", "codesPerFile"];
    fields.forEach((field) => setValue(field, batchDetails[field]));

    // Set Batch items
    editBatchItems = batchData.batchItems;
    const itemFields = ["lotId", "batchId", "id", "pointValue", "noOfCodes"];
    let lotsData = [];
    for (let i = 0; editBatchItems.length > i; i++) {
      // itemFields.forEach((itemField) => setValue(itemField, batchItems[itemField]));
      // let itemData = itemFields.forEach((itemField) => {
      //   return {itemField : batchItems[itemField]}
      // });
      // lotsData.push(itemData)
      itemFields.forEach((itemField) =>
        setValue(`batchItems[${i}][${itemField}]`, editBatchItems[i][itemField]),
      );
    }
  }
  const closeForm = () => {
    reset(defaultValues, { keepValues: false, keepDirty: false, keepDefaultValues: false });
    setIsSubmitting(false);
    mutate(
      `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/batches`,
    );
    mutate(`${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases`);
    handleClose();
    return;
  };

  // We have to show available codes on edit BatchItem level
  const availableCodesatEditBatchItem = (batchItemId) => {
    const batchItemInfo = batchItemData.find((batchObj) => batchObj.id === batchItemId);
    const lotInfo = lots.find((itm) => itm.id === batchItemInfo.lotId);
    const exceptBatchItemReleaseDetails = batchItemData?.filter((batchItemObj) => batchItemObj.id !== batchItemId && batchItemObj.lotId === lotInfo.id);
    const currentAllocatedCodesatLot = exceptBatchItemReleaseDetails?.reduce((acc, el) => acc + Number(el.noOfCodes), 0) || 0;
    console.log("Available Codes At Edit BatchItem", lotInfo?.codesAlloted - currentAllocatedCodesatLot);

    return lotInfo?.codesAlloted - currentAllocatedCodesatLot || 0;
  };

  // We have to show available codes on Add BatchItem level
  const availableCodesatCreateBatchItem = (lotObj) => {
    const totalAvailableCodesatBatchItem = batchItemData?.filter((batchItemObj) => batchItemObj.lotId === lotObj.id);
    const currentAllocatedCodesatLot = totalAvailableCodesatBatchItem?.reduce((acc, el) => acc + Number(el.noOfCodes), 0) || 0;
    console.log("Available Codes At ADD BatchItem", lotObj?.codesAlloted - currentAllocatedCodesatLot);

    return lotObj?.codesAlloted - currentAllocatedCodesatLot || 0;
  };

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={closeForm}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeForm} aria-label="close">
              <CloseIcon />
            </IconButton>
            {editMode ? (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit Batch
              </Typography>
            ) : (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Create a new Batch
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form>
            <FormSelect
              name="receipientOrg"
              items={organizationOptions}
              control={control}
              label="Receiving Organization"
              rules={{
                required: { value: true, message: "This is a required field" },
              }}
            />
            <span className="italic underline">Don&apos;t see an organization?</span>
            <Link
              to="/organizations"
              className="italic underline text-blue-600 dark:text-blue-500 hover:underline"
            >
              Create an organization
            </Link>
            <FormInput name="codesPerFile" control={control} label="Maximum Codes Per File" />
            {editMode &&
              editBatchItems.map((batchItem, index) => (
                <div  key={batchItem.id}>
                  <Divider className="p-1 slate-50" orientation="horizontal" />
                  <div className="mt-5">Batch Item ID: {batchItem.id}</div>
                  <div className="mt-1">Lot Name: {batchItem.lotName}</div>
                  <DisabledInput
                    name={`batchItems.${index}.lotId`}
                    rules={{
                      required: {
                        value: true,
                        message: "This is a required field",
                      },
                    }}
                    control={control}
                    label="Lot Id"
                  />
                  <FormInput
                    name={`batchItems.${index}.pointValue`}
                    control={control}
                    label="Point Value"
                    type="number"
                  />
                  <FormInput
                    name={`batchItems.${index}.noOfCodes`}
                    rules={{
                      required: {
                        value: true,
                        message: "This is a required field",
                      },
                      validate: {
                        availableCodes: (value) => {
                          if (value) {
                            const availableCodes = availableCodesatEditBatchItem(batchItem.id);
                            return (
                              value <= availableCodes ||
                              `Number of codes should be less than or equal to the available codes : # ${availableCodes}`
                            );
                          }
                        }
                      }
                    }}
                    control={control}
                    label="Number of codes"
                    type="number"
                  />
                </div>
              ))}
            {!editMode &&
              lots.map((lot, index) => (
                <div key={lot.id}>
                  <Divider className="p-3 slate-50" orientation="horizontal" />

                  <div>Lot Name: {lot.name}</div>
                  {/* <div className="flex gap-x-24 p-2">
                    <span className="p-1 w-64">Lot Id: </span>
                    <span className="w-96">{lot.id}</span>
                  </div> */}
                  <DisabledInput
                    name={`batchItems.${index}.lotId`}
                    rules={{
                      required: {
                        value: true,
                        message: "This is a required field",
                      },
                    }}
                    control={control}
                    defaultValue={lot.id}
                    label="Lot Id"
                  />
                  <FormInput
                    name={`batchItems.${index}.pointValue`}
                    rules={{
                      required: {
                        value: true,
                        message: "This is a required field",
                      },
                    }}
                    control={control}
                    label="Point Value"
                    type="number"
                    defaultValue={lot.pointValue}
                  />
                  <p className="subpixel-antialiased italic underline decoration-sky-500">{`# code available :: # ${availableCodesatCreateBatchItem(lot)}`}</p>
                  <FormInput
                    name={`batchItems.${index}.noOfCodes`}
                    rules={{
                      required: {
                        value: true,
                        message: "This is a required field",
                      },
                      validate: {
                        availableCodes: (value) => {
                          if (value) {
                            const remainingCodes = availableCodesatCreateBatchItem(lot);
                            return (
                              value <= remainingCodes ||
                              `Number of codes should be less than or equal to the available codes : # ${remainingCodes}`
                            );
                          }
                        }
                      }
                    }}
                    control={control}
                    label="Number of codes"
                    type="number"
                  />
                </div>
              ))}
          </form>
          {isSubmitting && <Loader open={isSubmitting} />}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeForm}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} variant="contained" onClick={onSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
    </>
  );
}
