import PreviewIcon from '@mui/icons-material/Preview';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import * as React from "react";

export const ViewIconButton = ({ onClick }) => (
  <IconButton onClick={ onClick } aria-label="view" size="large" color="success">
    <PreviewIcon fontSize="inherit" />
  </IconButton>
);

export const ViewButton = ({ onClick }) => (
  <Button startIcon={ <PreviewIcon /> } onClick={ onClick } variant="contained" color="success" size="small">
    View
  </Button>
);
