"use client";

import Paper from "@mui/material/Paper";
import React from "react";
import { useSearchParams } from "react-router-dom";
import ReportResult from "../../ReportResult";

const columns = [
  {
    field: "release",
    headerName: "Release Name",
    width: 250,
    minWidth: 250
  },
  {
    field: "org",
    headerName: " Organization Name",
    width: 250,
    minWidth: 250
  },
  {
    field: "lotName",
    headerName: "Lot Name",
    width: 250,
    minWidth: 250
  },
  {
    field: "lotId",
    headerName: "Lot ID#",
    width: 250,
    minWidth: 250
  },
  {
    field: "total",
    headerName: "Generated#",
    width: 250,
    minWidth: 250
  },
  {
    field: "redeemed",
    headerName: "Redeemed",
    width: 250,
    minWidth: 250
  },
  {
    field: "pointvalue",
    headerName: "Point Value",
    width: 250,
    minWidth: 250
  }
];

function LotRedemptionReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const country = searchParams.get("country");
  const programId = searchParams.get("programId");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const ownerGroup = searchParams.get("ownerGroup");

  const url = `${process.env.REACT_APP_REPORT_URL}/report/redemption/lot?country=${country}&programId=${programId}&startDate=${startDate}&endDate=${endDate}&ownerGroup=${ownerGroup}`;
  return (
    <>
      <Paper
        className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start"
        elevation={ 6 }
      >
        <p className="text-xl ml-5 mb-5">Code Redeemed by Lot/Batch </p>
      </Paper>
      <ReportResult programId={programId} columns={ columns } url={ url } programDetailsEnabled={ true } />
    </>
  );
}

export default LotRedemptionReport;
