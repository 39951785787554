import * as React from 'react';

import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function FormCheckboxGroup({ name, control, label, rules, options }) {
  return (
    <div className="flex gap-x-24 p-2">
      <span className='p-1 w-64'>{label}</span>
      <FormGroup >
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState: { error } }) => (
            <div className='flex flex-col'>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  label={option.label}
                  control={
                    <Checkbox
                      key={option.value}
                      value={option.value}
                      checked={field.value.some((existingValue) => existingValue === option.value)}
                      onChange={(event, checked) => {
                        if (checked) {
                          field.onChange([...field.value, event.target.value]);
                        } else {
                          field.onChange(
                            field.value.filter((value) => value !== event.target.value),
                          );
                        }
                      }}
                    />
                  }
                />
              ))}
              <FormHelperText className='text-red-500'>{error?.message}</FormHelperText>
            </div>
          )}
        />
      </FormGroup>
    </div>
  );
}
// checked={field.value.some((existingValue) => existingValue.value === option.value)}
