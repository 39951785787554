"use client";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React from "react";
import ProgramForm from "./ProgramForm";
import ProgramTableGrid from "./ProgramTable";

export default function Programs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Paper className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start" elevation={6}>
        <p className="text-xl ml-5 mb-5">Programs</p>
        <div className="mb-5 ml-auto flex flex-wrap gap-x-6 items-end justify-start" elevation={6}>
          <Button
            id="create-program-button"
            variant="contained"
            onClick={handleClickOpen}
            className="ml-auto mr-5 mt-4"
            startIcon={<AddIcon />}
          >
            Create New Program
          </Button>
        </div>
      </Paper>

      <ProgramTableGrid />
      <ProgramForm open={open} handleClose={handleClose} isNewProgram={true} />
    </>
  );
}
