import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  FormHelperText,
  FormGroup,
  InputLabel,
  FormControlLabel
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { Controller } from "react-hook-form";

const FormMultiSelect = ({ items, label, name, control, rules, setValue, disabled }) => {
  const [selectAll, setSelectAll] = React.useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const ops = items.map((item) => item.value);
    // setValue(name, selectAll ? [] : ops)
  };

  return (
    <FormControl className="flex gap-x-24 p-2">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <div className="flex gap-x-24 p-1">
            <span className="p-1 w-64">{label}</span>
            <Select
              className="w-96 h-10"
              {...field}
              multiple
              renderValue={(selected) => <div>{selected.length} selected</div>}
              disabled={disabled}
            >
              {/* <MenuItem key="select-all" onClick={handleSelectAll}>
                <input type="checkbox" checked={selectAll} readOnly />
                Select All
              </MenuItem> */}
              {items.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox
                    checked={(field.value && field.value.indexOf(item.value) > -1) || selectAll}
                  />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className="text-red-500">{error?.message}</FormHelperText>
          </div>
        )}
      />
    </FormControl>
  );
};

export default FormMultiSelect;
