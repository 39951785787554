/* eslint-disable no-shadow */
import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const RADIAN = Math.PI / 180;
const data = [
  { name: "Low", value: 33, color: "#008000" },
  { name: "Medium", value: 33, color: "#FFFF00" },
  { name: "High", value: 33, color: "#FF0000" },
];
const cx = 100;
const cy = 130;
const iR = 60;
const oR = 80;
const value = 50;

const needle = (value, data, cx, cy, iR, oR, color) => {
  const ang = 180.0 * value;
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return (
    (<circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />),
    (
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="#none"
        fill={color}
      />
    )
  );
};

const Gauge = ({needleValue}) => {
  return (
    <PieChart width={200} height={200}>
      <Pie
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        startAngle={180}
        endAngle={0}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
        labelLine={false}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      {needle(needleValue, data, cx, cy, iR, oR, "#d0d000")}
    </PieChart>
  );
};

export default Gauge;
