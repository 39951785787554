export const charSetSanitize = (charSet) => {
  let numberSet = charSet.numbers;
  let upperCaseSet = charSet.upperCaseAlphabets;
  let lowerCaseSet = charSet.lowerCaseAlphabets;
  let santizedNumbers = [];
  let santizedUpperCase = [];
  let santizedLowerCase = [];
  let santizedCharSet = {};

  numberSet.map((number) => {
    if (number.checked) {
      santizedNumbers.push(number);
    }
  });
  upperCaseSet.map((upperCase) => {
    if (upperCase.checked) {
      santizedUpperCase.push(upperCase);
    }
  });
  lowerCaseSet.map((lowerCase) => {
    if (lowerCase.checked) {
      santizedLowerCase.push(lowerCase);
    }
  });
  santizedCharSet.numbers = santizedNumbers;
  santizedCharSet.upperCaseAlphabets = santizedUpperCase;
  santizedCharSet.lowerCaseAlphabets = santizedLowerCase;
  return santizedCharSet;
};

export const validateCharAndSynonym = (characterSet) => {
  const merged = [
    ...(characterSet.numbers ? characterSet.numbers : []),
    ...(characterSet.upperCaseAlphabets ? characterSet.upperCaseAlphabets : []),
    ...(characterSet.lowerCaseAlphabets ? characterSet.lowerCaseAlphabets : []),
  ];
  const alphaNumSet = merged.map((item) => item?.code);
  const synonymSets = merged.map((item) => item?.synonym);
  const synonymString = synonymSets.join("");
  const synonymArray = synonymString.split("");
  const mergedSynonymSet = new Set(synonymArray); 
  if (synonymArray.length !== mergedSynonymSet.size) {
    return true;
  }
  const isDuplicate = alphaNumSet.filter(item => mergedSynonymSet.has(item));
  return isDuplicate.length > 0;
}
