import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React, { useEffect, userState } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import SwitchUser from "./SwitchUser";
import { useNavigate } from "react-router-dom";
import { getRoles } from "../../utils/session";

function Banner({ user, signOut }) {
  const [open, setOpen] = React.useState(false);
  const [isImpersonated, setIsImpersonated] = React.useState(false);
  // const [impersonatedUser, setImpersonatedUser] = React.useState(null);
  const [userId, setUserId] = React.useState(null);
  const [givenName, setGivenName] = React.useState(null);
  const [familyName, setFamilyName] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const navigate = useNavigate();

  const handleSwitchUser = () => {
    setOpen(true);
  };
  const handleBecomeAdmin = () => {
    setIsImpersonated(false);
    localStorage.removeItem("impersonated");
    navigate(0);
  };
  useEffect(() => {
    const delegatedUser = localStorage.getItem("impersonated");
    if (delegatedUser) {
      setIsImpersonated(true);
      const decodedUser = JSON.parse(atob(delegatedUser));
      console.log(decodedUser);
      setGivenName(decodedUser.user.firstName);
      setFamilyName(decodedUser.user.lastName);
      setUserId(decodedUser.user.userId);
      localStorage.setItem("userId", decodedUser.user.userId);
    } else {
      setGivenName(user.attributes.given_name);
      setFamilyName(user.attributes.family_name);
      setUserId(user.username.substring(9));
      localStorage.setItem("userId", user.username.substring(9));
    }
    const roles = getRoles(user);
    const adminRole = roles.includes("SEC-IDM-CODES-ADMIN");
    if (adminRole) {
      setIsAdmin(true);
    }
  }, []);


  const handleSignOut = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("impersonated");
    signOut();
  };  
  const handleClose = () => {
    setOpen(false);
    navigate(0);
  };

  return (
    <div className="bg-white flex flex-wrap gap-x-6 items-end justify-start">
      <Link href="/">
        <Logo />
      </Link>
      <ButtonGroup className="p-2 ml-auto text-black" variant="text" aria-label="text button group">
        {isImpersonated && (
          <span className="ml-1 mt-1 underline decoration-sky-500">Impersonating as: </span>
        )}
        <Button>
          {givenName} {familyName} ({userId})
        </Button>
        {!isImpersonated && isAdmin && <Button onClick={handleSwitchUser}>Switch User</Button>}
        {isImpersonated && <Button onClick={handleBecomeAdmin}>Become Admin</Button>}
        <Button onClick={handleSignOut}>Logout</Button>
      </ButtonGroup>
      <SwitchUser open={open} handleClose={handleClose} />
    </div>
  );
}

export default Banner;
