import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const FormDateTimePicker = ({ name, control, label, rules, timezone, disabled }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <div className="flex gap-x-24 p-2">
            <span className="p-1 w-64">{label}</span>
            <DateTimePicker
              {...field}
              slotProps={{
                textField: { size: "small", error: !!fieldState.error },
              }}
              format="YYYY-MM-DDTHH:mm:ss"
              className="w-96 h-10"
              autoOk
              timezone={timezone || "UTC"}
              disablePast
              error={!!field.error} 
              disabled={disabled}
            />
            <FormHelperText className="text-red-500">{fieldState.error?.message}</FormHelperText>
          </div>
        )}
      />
    </LocalizationProvider>
  );
};

export default FormDateTimePicker;
