"use client";

import { Button } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);

export default function RedemptionProgramForm() {
  const [startDate, setStartDate] = React.useState(dayjs().subtract(1, 'year').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = React.useState(dayjs().add(1, "day").format("YYYY-MM-DD"));

  const handleStartDateChange = (value) => {
    setStartDate(dayjs.utc(value).format("YYYY-MM-DD"));
  };
  const handleEndDateChange = (value) => {
    setEndDate(dayjs.utc(value).format("YYYY-MM-DD"));
  };

  const handleRunReport = () => {
    window.open(
      `/reports/redemption/program?startDate=${startDate}&endDate=${endDate}`,
      "_blank"
    );
  };
  return (
    <form onSubmit={ handleRunReport }>
      <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">Start Date</span>
        <LocalizationProvider dateAdapter={ AdapterDayjs }>
          <DatePicker
            slotProps={ { textField: { size: "small" } } }
            format="MM/DD/YYYY"
            className="w-96 h-10"
            autoOk
            onChange={handleStartDateChange}
            defaultValue={dayjs().subtract(1, 'year')} 
          />
        </LocalizationProvider>
      </div>
      <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">End Date</span>
        <LocalizationProvider dateAdapter={ AdapterDayjs }>
          <DatePicker
            slotProps={ { textField: { size: "small" } } }
            format="MM/DD/YYYY"
            className="w-96 h-10"
            autoOk
            onChange={handleEndDateChange}
            defaultValue={dayjs().add(1, "day")} 
          />
        </LocalizationProvider>
      </div>
      <Button type="submit" variant="contained">
        Run Report
      </Button>
    </form>
  );
}
