import React, { useEffect } from "react";
import { Authenticator, Divider } from "@aws-amplify/ui-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Amplify, Auth } from "aws-amplify";
import awsAuth from "./aws-exports";
import Header from "./components/Header";
import { Route, BrowserRouter as Router, Routes, Navigate, Outlet } from "react-router-dom";
// import "@aws-amplify/ui-react/styles.css";
import { Typography } from "@mui/material";
import { GlobalStateProvider } from "./utils/GlobalStateContext";
import closure from "./closure";
import DownloadCodes from "./downloadCodes";
import Organizations from "./organizations";
import Programs from "./programs";
import Promotions from "./promotions";
import PublicKey from "./publicKey";
import QueuedRequests from "./queuedRequests";
import RealTimeTest from "./realtimeTest";
import Release from "./release";
import Reports from "./reports";
import BatchReport from "./reports/codegen/batch";
import CodeVolumeReport from "./reports/codegen/codevolume";
import LotReport from "./reports/codegen/lot";
import MarketReport from "./reports/codegen/market";
import RealtimeReport from "./reports/codegen/realtime";
import ConsumerActivityRedemptionReport from "./reports/redemption/consumer";
import LotRedemptionReport from "./reports/redemption/lot";
import ProgramRedemptionReport from "./reports/redemption/program";
import TopConsumerAttempts from "./reports/redemption/topattempts";
import TopConsumerActivityReport from "./reports/redemption/topconsumer";
import Validate from "./validate";
import Custom404 from "./pages/404";
import { getRoles } from "./utils/session";
import Landing from "./pages/landing";

import "./App.css";
import Custom500 from "./error/500";

let awsConfig = {};
const environment = process.env.REACT_APP_STAGE || "dev";
if (environment === "dev") {
  awsConfig = awsAuth.dev;
} else if (environment === "stg") {
  awsConfig = awsAuth.staging;
} else if (environment === "ppd") {
  awsConfig = awsAuth.preprod;
} else if (environment === "prd") {
  awsConfig = awsAuth.prod;
} else {
  awsConfig = awsAuth.dev;
}
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ff0015",
      light: "#c13846",
      dark: "#6b000b",
    },
    secondary: {
      main: "#f50359",
      light: "#fb377d",
      dark: "#a8013d",
      contrastText: "#fbfbfb",
    },
    error: {
      main: "#f74336",
      light: "#f5675d",
      dark: "#af2d24",
      contrastText: "#f7f4f4",
    },
    warning: {
      main: "#fd9804",
      light: "#fdac35",
      dark: "#b56c01",
      contrastText: "#000000",
    },
    info: {
      main: "#2197f5",
      light: "#4eaaf5",
      contrastText: "#000000",
    },
    success: {
      main: "#49ad4d",
      light: "#6fbf72",
      dark: "#347b36",
      contrastText: "#000000",
    },
    divider: "#6FBF91",
    background: {
      default: "#2f2f2f",
      paper: "#1c1a1a",
    },
    text: {
      primary: "#ffffff",
      secondary: "rgba(255,245,245,0.7)",
      disabled: "rgba(230,217,217,0.5)",
      hint: "rgba(214,193,193,0.5)",
    },
    typography: {
      fontFamily: ["Montserrat"],
    },
  },
});
if (environment === "dev") {
  if (typeof window !== "undefined") {
    const isLocalhost = Boolean(
      window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
    );

    // Assuming you have two redirect URIs, and the first is for localhost and second is for production
    const [localRedirectSignIn, productionRedirectSignIn] =
      awsConfig.oauth.redirectSignIn.split(",");

    const [localRedirectSignOut, productionRedirectSignOut] =
      awsConfig.oauth.redirectSignOut.split(",");

    const updatedAwsConfig = {
      ...awsConfig,
      oauth: {
        ...awsConfig.oauth,
        redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
        redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
      },
    };

    Amplify.configure(updatedAwsConfig);
    Auth.configure();
  }
} else {
  Amplify.configure(awsConfig);
  Auth.configure();
}

const authComponents = {
  SignIn: {
    Header() {
      return (
        <div className="h-screen background text-black bg-paper">
          <div className="pt-24 grid justify-center">
            <img
              src="/Coca-Cola.png"
              className="object-scale-down h-24 w-96 "
              alt="Coca Coal MixCodes"
            />
            <div className="flex justify-center mt-10 text-xl">Welcome to Mix Codes</div>
            <div className="flex justify-center mt-5">
              <Button
                variant="contained"
                level={3}
                onClick={() => {
                  Auth.federatedSignIn({
                    customProvider: "azure-ad",
                  });
                }}
              >
                Sign in using SSO
              </Button>
            </div>
          </div>
        </div>
      );
    },
    Footer() {
      return <p></p>;
    },
  },
};

const formFields = {
  signIn: {},
};

// const ProtectedRoute = async ({ user }) => {
//   // const roles = await getRoles()
//   // console.log(roles)
//   if (!user) {
//     return <Navigate to= "/" replace />;
//   }
//   return <Outlet />;
// };

function App() {
  // useEffect(() => {
  //   Auth.federatedSignIn({
  //     customProvider: "azure-ad"
  //   });
  // }, []);

  const ProtectedMarketingRoute = ({ user, redirectPath = "/" }) => {
    const roles = getRoles(user);
    console.log(roles);
    if (roles.includes("SEC-IDM-CODES-ADMIN") || roles.includes("SEC-IDM-CODES-MKTUSERS")) {
      return <Outlet />;
    }
    return <Navigate to={redirectPath} replace />;
  };
  const ProtectedAdminOnlyRoute = ({ user, redirectPath = "/" }) => {
    const roles = getRoles(user);
    console.log(roles);
    if (roles.includes("SEC-IDM-CODES-ADMIN")) {
      return <Outlet />;
    }
    return <Navigate to={redirectPath} replace />;
  };
  const ProtectedCodeRecipientRoute = ({ user, redirectPath = "/" }) => {
    const roles = getRoles(user);
    console.log(roles);
    if (roles.includes("SEC-IDM-CODES-ADMIN") || roles.includes("SEC-IDM-CODES-RECIPIENTS") || roles.includes("SEC-IDM-CODES-SUPPORT")) {
      return <Outlet />;
    }
    return <Navigate to={redirectPath} replace />;
  };
  return (
    <ThemeProvider theme={theme}>
      <Authenticator hideSignUp={true} components={authComponents} formFields={formFields}>
        {({ signOut, user }) => (
          <GlobalStateProvider userAttr={user}>
            <Router>
              <Header user={user} signOut={signOut} />
              <main>
                {/* Render page content */}
                <div className="p-5 h-fit w-screen">
                  <Routes>
                    <Route path="/" Component={Landing} />
                    {/* Routes for different pages */}
                    <Route element={<ProtectedMarketingRoute user={user} />}>
                      <Route exact path="/programs" Component={Programs} />
                      <Route path="/organizations" Component={Organizations} />
                      <Route path="/promotions" Component={Promotions} />
                      <Route path="/closure" Component={closure} />
                      <Route path="/queuedrequests" Component={QueuedRequests} />
                      <Route path="/reports" Component={Reports} />
                      <Route path="/release" Component={Release} />
                      <Route path="/reports/codegen/batch" Component={BatchReport} />
                      <Route path="/reports/codegen/codevolume" Component={CodeVolumeReport} />
                      <Route path="/reports/codegen/lot" Component={LotReport} />
                      <Route path="/reports/codegen/market" Component={MarketReport} />
                      <Route path="/reports/codegen/realtime" Component={RealtimeReport} />
                      <Route
                        path="/reports/redemption/consumer"
                        Component={ConsumerActivityRedemptionReport}
                      />
                      <Route path="/reports/redemption/lot" Component={LotRedemptionReport} />
                      <Route
                        path="/reports/redemption/program"
                        Component={ProgramRedemptionReport}
                      />
                      <Route
                        path="/reports/redemption/topattempts"
                        Component={TopConsumerAttempts}
                      />
                      <Route
                        path="/reports/redemption/topconsumer"
                        Component={TopConsumerActivityReport}
                      />
                    </Route>
                    <Route element={<ProtectedCodeRecipientRoute user={user} />}>
                      <Route path="/downloadcodes" Component={DownloadCodes} />
                      <Route path="/publickey" Component={PublicKey} />
                      <Route path="/validate" Component={Validate} />
                      <Route path="/notfound" Component={Custom404} />
                      <Route path="/error" Component={Custom500} />
                    </Route>
                    <Route element={<ProtectedAdminOnlyRoute user={user} />}>
                      <Route path="/realtimetest" Component={RealTimeTest} />
                    </Route>
                  </Routes>
                </div>
              </main>
            </Router>
          </GlobalStateProvider>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}

export default App;
