import { Paper } from "@mui/material";
import React from "react";
import Gauge from "./Gauge";
import { Button } from "@mui/material";
import { axiosPost } from "../utils/axios";
import { charSetSanitize } from "./ProgramUtils";
import {
  numbersPayload,
  lowerCaseAlphabetsPayload,
  upperCaseAlphabetsPayload,
} from "./programDefaultValues";

const RiskChecker = ({ getValues }) => {
  const [riskMessage, setRiskMessage] = React.useState("");
  const [exampleCodes, setExampleCodes] = React.useState([]);
  const [oddsToGuess, setOddsToGuess] = React.useState(null);
  const needleValues = {
    Red: 0.2,
    Yellow: 0.5,
    Green: 0.8,
  };

  const getCharSet = (rawCharacterSet) => {
    const characterSet = charSetSanitize(rawCharacterSet);
    const merged = [
      ...(characterSet.numbers ? characterSet.numbers : []),
      ...(characterSet.upperCaseAlphabets ? characterSet.upperCaseAlphabets : []),
      ...(characterSet.lowerCaseAlphabets ? characterSet.lowerCaseAlphabets : []),
    ];
    const alphaNumSets = merged.map((item) => item?.code);
    const alphaNumString = alphaNumSets.join("");
    const synonymSets = merged.map((item) => `${item?.code}=${item?.synonym}`);
    const synonymString = synonymSets.join(",");
    const characterSetNew = `${alphaNumString}/${synonymString}`;
    return characterSetNew;
  };

  const calculateRisk = () => {
    const codeLength = getValues("codeDescriptor.length");
    const maxCodes = getValues("codeDescriptor.maxCount");
    const prefix = getValues("codeDescriptor.prefix");
    const rawCharacterSet = getValues("codeDescriptor.characterSet");
    const allCapsFormValue = getValues("allCaps");
    const allLowercaseFormValue = getValues("allLowercase");
    const numbersFormValue = getValues("allNumbers");

    if (allCapsFormValue) {
      rawCharacterSet.upperCaseAlphabets = upperCaseAlphabetsPayload;
    }
    if (allLowercaseFormValue) {
      rawCharacterSet.lowerCaseAlphabets = lowerCaseAlphabetsPayload;
    }
    if (numbersFormValue) {
      rawCharacterSet.numbers = numbersPayload;
    }
    
    if (codeLength && maxCodes && rawCharacterSet) {
      const characterSetNew = getCharSet(rawCharacterSet);
      console.log(characterSetNew);
      axiosPost(`${process.env.REACT_APP_CODE_GEN_URL}/code-descriptor/risk`, {
        charset: characterSetNew,
        codeLength: Number(codeLength),
        maxCodes: Number(maxCodes),
        prefix: prefix,
      })
        .then((response) => {
          console.log(response);
          setRiskMessage(response.data.color);
          setOddsToGuess(response.data.oddsToGuess);
        })
        .catch((error) => {
          console.log(error);
          setRiskMessage("Unable to determine. Error Occured");
        });
    } else {
      setRiskMessage("Unable to determine. Please fill all the required fields");
    }
  };

  const getExampleCodes = () => {
    const codeLength = getValues("codeDescriptor.length");
    const maxCodes = getValues("codeDescriptor.maxCount");
    const prefix = getValues("codeDescriptor.prefix");
    const rawCharacterSet = getValues("codeDescriptor.characterSet");
    const allCapsFormValue = getValues("allCaps");
    const allLowercaseFormValue = getValues("allLowercase");
    const numbersFormValue = getValues("allNumbers");

    if (allCapsFormValue) {
      rawCharacterSet.upperCaseAlphabets = upperCaseAlphabetsPayload;
    }
    if (allLowercaseFormValue) {
      rawCharacterSet.lowerCaseAlphabets = lowerCaseAlphabetsPayload;
    }
    if (numbersFormValue) {
      rawCharacterSet.numbers = numbersPayload;
    }
    if (codeLength && maxCodes && rawCharacterSet) {
      const characterSetNew = getCharSet(rawCharacterSet);
      console.log(characterSetNew);
      axiosPost(`${process.env.REACT_APP_CODE_GEN_URL}/code-descriptor/samples`, {
        charset: characterSetNew,
        codeLength: Number(codeLength),
        maxCodes: Number(maxCodes),
        prefix: prefix,
      })
        .then((response) => {
          setExampleCodes(response.data)
        })
        .catch((error) => {
          console.log(error);
          setExampleCodes(["Error Occurred!!!"])
        });
    } else {
      setRiskMessage("Unable to determine. Please fill all the required fields");
    }
  };
  return (
    <Paper elevation={3} className="w-full m-2">
      <h2 className="m-2">Risk Calculator</h2>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <Gauge needleValue={needleValues[riskMessage]} />
          <Button className="ml-10" variant="contained" color="warning" onClick={calculateRisk}>
            Check Risk
          </Button>
        </div>
        <p className="text-slate-50 text-center">
          <span className="text-slate-50">Risk Level: {riskMessage}</span>
          <br />
          {oddsToGuess && (
            <span className="text-orange-300">Odds to Guess: 1 in {oddsToGuess}</span>
          )}
        </p>

        <div>
          <Button variant="contained" color="info" onClick={getExampleCodes}>
            Show Examples
          </Button>
          <p className="text-blue-600">Example Codes</p>
          {exampleCodes.length > 0 && exampleCodes.map((code, index) => (
            <p key={index}>{code}</p>
          ))}
        </div>
      </div>
    </Paper>
  );
};

export default RiskChecker;
