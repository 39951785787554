import AnalyticsIcon from "@mui/icons-material/Analytics";
import Download from "@mui/icons-material/Download";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import StartIcon from "@mui/icons-material/Start";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";

import ReportDialog from "./ReportDialog";

const codeGenReports = [
  { name: "Codes Generated By Batch", value: "COEDGEN_BATCH" },
  { name: "Codes Generated By Lot", value: "COEDGEN_LOT" },
  { name: "Codes Generated By Market", value: "COEDGEN_MARKET" },
  { name: "Code Volume By Organization", value: "COEDGEN_ORGANIZATION" },
  { name: "Realtime Code Generation Report", value: "COEDGEN_REALTIME" },
];
const redemptionReports = [
  { name: "Code Redemption By Lot", value: "REDEMPTION_LOT" },
  { name: "Top Code Redemptions By Consumer", value: "REDEMPTION_TOP_CONSUMER" },
  { name: "Top Code Redemption Attempts By Consumer", value: "REDEMPTION_TOP_ATTEMPTS" },
  { name: "Code Redemption Attempts by Program", value: "REDEMPTION_TOP_PROGRAM" },
  { name: "Consumer PinCode Activity", value: "REDEMPTION_PINCODE" },
];
export default function ReportsList() {
  const [open, setOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});

  const handleClickOpen = (report) => {
    setOpen(true);
    setSelectedReport(report);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Paper className="grid grid-cols-2 gap-4">
      <div>
        <span>Code Generation Report</span>
        <List className="max-w-lg">
          {codeGenReports.map((report) => (
            <ListItem
              className="p-5"
              key={report.value}
              disableGutters
              secondaryAction={
                <IconButton aria-label="Run Report" onClick={() => handleClickOpen(report)}>
                  <StartIcon fontSize="large" sx={{ color: green[500] }} />
                </IconButton>
              }
            >
              <ListItemIcon>
                <StackedBarChartIcon />
              </ListItemIcon>
              <ListItemText primary={`${report.name}`} />
            </ListItem>
          ))}
        </List>
      </div>
      <div>
        <span> Mix Code Redemption Reports</span>
        <List className="max-w-lg">
          {redemptionReports.map((report) => (
            <ListItem
              className="p-5"
              key={report.value}
              disableGutters
              secondaryAction={
                <IconButton aria-label="Run Report" onClick={() => handleClickOpen(report)}>
                  <StartIcon fontSize="large" sx={{ color: green[500] }} />
                </IconButton>
              }
            >
              <ListItemIcon>
                <AnalyticsIcon />
              </ListItemIcon>
              <ListItemText primary={`${report.name}`} />
            </ListItem>
          ))}
        </List>
      </div>
      <ReportDialog open={open} handleClose={handleClose} report={selectedReport} />
    </Paper>
  );
}
