

import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
export const StyledTab = styled((props) => <Tab disableRipple { ...props } />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  "&.Mui-selected": {
    color: "#fff !important",
    backgroundColor: "#000000"
  },
  "&.Mui-hover": {
    color: "#000",
    backgroundColor: "#B10012"
  },
  "&:hover, &.Mui-focusVisible": {
    color: "#000",
  },
  "&.MuiTab-root , .MuiButtonBase-root ": {
    color: "#000",
  }
}));


export const StyledTabs = styled((props) => (
  <Tabs { ...props } TabIndicatorProps={ { children: <span className="MuiTabs-indicatorSpan" /> } } />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FE001A"
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%"
  }
});
