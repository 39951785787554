"use client";

import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ClosureMatrixForm from "./ClosureMatrixForm";
import DeleteDialog from "./DeleteDialog";
import { DeleteIconButton } from "../components/DeleteButton";
import { EditIconButton } from "../components/EditButton";
import Loader from "../components/Loader";
import useSWR, { mutate } from "swr";
import dayjs from "dayjs";
import Custom500 from "../error/500";

import fetcher from "../utils/fetcher";

export default function ClosureMatrixTable({ ownerGroup }) {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let filteredClosureType = [];

  const {
    data: closureTypes,
    error,
    isLoading,
  } = useSWR(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/closure-types`, fetcher);
  if (closureTypes) {
    const temp = closureTypes.filter((closureType) => closureType.ownerGroup === ownerGroup);
    filteredClosureType.push(...temp);
  }
  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 300,
      minWidth: 300,
    },
    {
      field: "name",
      headerName: "Name",
      width: 400,
      minWidth: 400,
    },
    {
      field: "createdBy",
      headerName: "User",
      width: 250,
      minWidth: 250,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 250,
      minWidth: 250,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const row = params.row;
          mutate(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/closure-types`);
          setSelectedRow(row);
          setEditMode(true);
          setOpen(true);
        };
        return <EditIconButton onClick={onClick} />;
      },
    },
    // {
    //   field: 'delete',
    //   headerName: 'Delete',
    //   sortable: false,
    //   width: 100,
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       const row = params.row;
    //       setSelectedRow(row);
    //       setOpenDeleteDialog(true);
    //     };

    //     return <DeleteIconButton onClick={onClick} />;
    //   },
    // },
  ];
  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  return (
    <>
      <DataGrid
        className="min-h-96"
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'desc' }],
          }
        }}
        rows={filteredClosureType}
        getRowId={(row) => row.id}
        columns={columns}
        checkboxSelection={false}
        autosizeOptions={{
          columns: ["code", "name", "description"],
          includeOutliers: true,
          includeHeaders: false,
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
      <ClosureMatrixForm
        open={open}
        handleClose={handleClose}
        editMode={editMode}
        closureType={selectedRow}
        ownerGroup={ownerGroup}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        closureType={selectedRow}
      />
    </>
  );
}
