import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { grey } from "@mui/material/colors";

function Multiselect({ label, options, setProgramList }) {
  const [selected, setSelected] = useState([]);
  const isAllSelected = options.length > 0 && selected.length === options.length;
  const optionsValues = options.map((option) => option.value);
  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === optionsValues.length ? [] : optionsValues);
      setProgramList(selected.length === optionsValues.length ? [] : optionsValues);
      return;
    }
    setSelected(value);
    setProgramList(value);
  };

  return (
    <FormControl className="flex gap-x-24 p-1">
      <div className="flex gap-x-24 p-1">
        <span className="p-1 w-64">{label}</span>
        <Select
          className="w-96 h-10"
          multiple
          onChange={handleChange}
          value={selected}
          renderValue={(selected) => <div>{selected.length} selected</div>}
        >
          <MenuItem key="select-all" value="all">
            <Checkbox
              checked={isAllSelected}
            />
            <ListItemText primary="Select All" />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={selected.indexOf(option.value) > -1}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </div>
    </FormControl>
  );
}

export default Multiselect;
