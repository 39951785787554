"use client";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import OrganizationForm from "./OrganizationForm";
import OrganizationTable from "./OrganizationTable";
import OwnerGroup from "../components/OwnerGroup";
import { useGlobalState } from "../utils/GlobalStateContext";

function Organizations() {
  const { state: globalState, dispatch } = useGlobalState();
  const { ownerGroups } = globalState;

  const [open, setOpen] = useState(false);

  const [ownerGroup, setOwnerGroup] = useState();

  useEffect(() => {
    if (ownerGroups && ownerGroups.length > 0) {
      setOwnerGroup(ownerGroups[0].id);
    }
  }, [ownerGroups]);

  const handleOwnerGroupChange = (event) => {
    setOwnerGroup(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start" elevation={ 6 }>
        <p className="text-xl ml-5 mb-5">My Organizations</p>
        <span className="m-5 ml-auto h-7">Select Owner Group</span>
        <OwnerGroup
          handleSelection={ handleOwnerGroupChange }
          selectedValue={ ownerGroup }
          options={ ownerGroups }
        />
        <Button
          variant="contained"
          onClick={ handleClickOpen }
          className="m-5"
          startIcon={ <AddIcon /> }
        >
          Create New Organization
        </Button>
      </Paper>
      <OrganizationTable ownerGroup={ ownerGroup } />
      <OrganizationForm
        open={ open }
        handleClose={ handleClose }
        isNewOrganization={ true }
        ownerGroup={ ownerGroup }
      />
    </>
  );
}

export default Organizations;
