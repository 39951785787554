import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useState } from "react";
import useSWR from "swr";
import BatchForm from "./BatchForm";
import LotsForm from "./LotsForm";
import BatchView from "./BatchView";
import { CreateButton } from "../components/CreateButton";
import { EditIconButton } from "../components/EditButton";
import { ViewIconButton } from "../components/ViewButton";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import Custom500 from "../error/500";
import fetcher from "../utils/fetcher";
import ListIcon from "@mui/icons-material/List";
import { CODEGEN_STATE_EDIT } from "./ReleaseUtils";
export default function LotsAndBatches({ programId, release, program }) {
  const [openLotsDialog, setOpenLotsDialog] = useState(false);
  const [openBatchDialog, setOpenBatchDialog] = useState(false);
  const [openBatchViewDialog, setOpenBatchViewDialog] = useState(false);
  const [selectedRelease, setSelectedRelease] = useState();
  const [status, setStatus] = useState({});
  const [lotsEditMode, setLotsEditMode] = useState(false);
  const [batchesEditMode, setBatchesEditMode] = useState(false);
  const [selectedLot, setSelectedLot] = useState();
  const [selectedBatch, setSelectedBatch] = useState();

  const handleCloseLotsDialog = () => {
    setLotsEditMode(false);
    setOpenLotsDialog(false);
  };

  const handleCloseBatchDialog = () => {
    setBatchesEditMode(false);
    setOpenBatchDialog(false);
  };

  const handleCloseBatchViewDialog = () => {
    setOpenBatchViewDialog(false);
  };

  const {
    data: lots,
    error,
    isLoading,
  } = useSWR(
    release
      ? `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/lots`
      : null,
    fetcher,
  );

  const {
    data: batches,
    error: batchesError,
    isLoading: isBatchLoading,
  } = useSWR(
    release
      ? `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/batches`
      : null,
    fetcher,
  );
  const handleCreateLot = (create, release) => {
    setLotsEditMode(false);
    setOpenLotsDialog(create);
    setSelectedRelease(release);
  };

  const handleCreateBatch = (create, release) => {
    setBatchesEditMode(false);
    setSelectedRelease(release);
    setOpenBatchDialog(create);
  };

  const handleEditLot = (release, lot) => {
    setSelectedRelease(release);
    setSelectedLot(lot);
    setLotsEditMode(true);
    setOpenLotsDialog(true);
  };
  const handleViewBatch = (release, batch) => {
    setSelectedRelease(release);
    setSelectedBatch(batch);
    setOpenBatchViewDialog(true);
  };

  const handleEditBatch = (release, batch) => {
    setBatchesEditMode(true);
    setSelectedRelease(release);
    setSelectedBatch(batch);
    setOpenBatchDialog(true);
  };

  const handleGenerateCode = (batchId) => {
    axios
      .post(`${process.env.REACT_APP_CODEGEN_URL}/codegen/batches/${batchId}`)
      .then((res) => {
        setStatus({ state: "success", message: "Code Generated Successfully" });
      })
      .catch((err) => {
        console.log(err);
        setStatus({ state: "error", message: "Unable to Generate code" });
      });
  };
  if (error || batchesError) return <Custom500 statusCode={500} />;
  if (isLoading || isBatchLoading) return <Loader open={isLoading} />;
  return (
    <>
      <div className="grid grid-cols-2 gap-2 divide-x divide-dashed">
        <div>
          <div className="flex items-stretch items-end">
            <Typography variant="h6" className="ml-2 grow">
              Lots
            </Typography>
            {CODEGEN_STATE_EDIT.includes(release.state) && (
              <CreateButton
                size="small"
                label="Add new lot"
                onClick={() => handleCreateLot(true, release)}
              />
            )}
          </div>
          <List dense={true}>
            {lots.map((lot) => (
              <>
                {lot.entity === "LOT" && (
                  <div key={lot.id}>
                    <ListItem
                      key={lot.id}
                      secondaryAction={
                        <EditIconButton
                          onClick={() => {
                            handleEditLot(release, lot);
                          }}
                        />
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <LocalDrinkIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`Name: ${lot.name}`}
                        secondary={`Alloted codes: ${lot.codesAlloted}`}
                      />
                      <ListItemText primary={`Id: ${lot.id}`} />
                    </ListItem>
                    <Divider className="p-3" orientation="horizontal" />
                  </div>
                )}
              </>
            ))}
          </List>
        </div>
        <div>
          <div className="flex items-stretch items-end">
            <Typography variant="h6" className="ml-2 grow">
              Batches
            </Typography>
            {lots.length > 0 && CODEGEN_STATE_EDIT.includes(release.state) && (
              <CreateButton
                size="small"
                label="Add new batch"
                onClick={() => handleCreateBatch(true, release)}
              />
            )}
          </div>
          <List dense={true}>
            {batches.map((batch) => (
              <>
                {batch.entity === "BATCH" && (
                  <div key={batch.id}>
                    <ListItem
                      key={batch.id}
                      secondaryAction={
                        CODEGEN_STATE_EDIT.includes(release.state) ? (
                          <EditIconButton
                            onClick={() => {
                              handleEditBatch(release, batch);
                            }}
                          />
                        ) : (
                          <ViewIconButton
                            onClick={() => {
                              handleViewBatch(release, batch);
                            }}
                          />
                        )
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <ListIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`Org: ${batch.receipientOrgName}`}
                        secondary={`Codes per file: ${batch.codesPerFile}`}
                      />
                      <ListItemText primary={`Id: ${batch.id}`} />
                    </ListItem>
                    <Divider className="p-3" orientation="horizontal" />
                  </div>
                )}
              </>
            ))}
          </List>
        </div>
      </div>
      <LotsForm
        open={openLotsDialog}
        editMode={lotsEditMode}
        handleClose={handleCloseLotsDialog}
        programId={programId}
        lot={selectedLot}
        release={selectedRelease}
        program={program}
      />
      <BatchForm
        lots={lots}
        editMode={batchesEditMode}
        programId={programId}
        open={openBatchDialog}
        handleClose={handleCloseBatchDialog}
        release={selectedRelease}
        batch={selectedBatch}
        program={program}
      />
      <BatchView
        lots={lots}
        programId={programId}
        open={openBatchViewDialog}
        handleClose={handleCloseBatchViewDialog}
        release={selectedRelease}
        batch={selectedBatch}
      />
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
    </>
  );
}
