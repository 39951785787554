import * as React from 'react';

import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { grey } from '@mui/material/colors';

export default function FormCheckBox({ name, control, label, rules, disabled }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className='flex gap-x-24 p-2'>
          <span className='p-1 w-64'>{label}</span>
          <Checkbox
            size='large'
            className='place-self-start w-8'
            onChange={onChange}
            checked={value}
            value={value}
            disabled={disabled}
          />
          <FormHelperText className='text-red-500'>{error?.message}</FormHelperText>
        </div>
      )}
    />
  );
}
