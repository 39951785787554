"use client";

import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import PromotionForm from "./PromotionForm";
import DeleteDialog from "./DeleteDialog";
import { EditIconButton } from "../components/EditButton";
import Loader from "../components/Loader";
import useSWR, { mutate } from "swr";
import Custom500 from "../error/500";
import fetcher from "../utils/fetcher";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
export default function PromotionTable({ ownerGroup }) {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_PROGRAM_MGMT_URL}/promotions`,
    fetcher,
  );

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 300,
      minWidth: 300,
    },
    {
      field: "name",
      headerName: "Name",
      width: 350,
      minWidth: 350,
    },
    {
      field: "desc",
      headerName: "Description",
      width: 250,
      minWidth: 250,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      minWidth: 100,
      valueFormatter: (params) =>
        dayjs(params?.value).tz(params.row?.localStartTimezone).format("MM/DD/YYYY"),
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      minWidth: 100,
      valueGetter: (params) =>
        dayjs(params?.value).tz(params.row?.localEndTimezone).format("MM/DD/YYYY"),
    },
    {
      field: "createdBy",
      headerName: "User",
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 100,
      minWidth: 100,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const row = params.row;
          setSelectedRow(row);
          setEditMode(true);
          setOpen(true);
        };

        return <EditIconButton onClick={onClick} />;
      },
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   sortable: false,
    //   width: 100,
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       const row = params.row;
    //       setSelectedRow(row);
    //       setOpenDeleteDialog(true);
    //     };

    //     return <DeleteIconButton onClick={onClick} />;
    //   },
    // },
  ];
  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  return (
    <>
      <DataGrid
        className="min-h-96"
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'desc' }],
          }
        }}
        rows={data}
        getRowId={(row) => row.id}
        columns={columns}
        checkboxSelection={false}
        onSelectionModelChange={(itm) => console.log(itm)}
        autosizeOptions={{
          columns: ["code", "name", "description"],
          includeOutliers: true,
          includeHeaders: false,
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
      <PromotionForm
        open={open}
        handleClose={handleClose}
        editMode={editMode}
        promotion={selectedRow}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        promotion={selectedRow}
      />
    </>
  );
}
