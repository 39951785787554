"use client";

import Paper from "@mui/material/Paper";
import React from "react";
import ReportsList from "./ReportsList";

function Reports() {
  return (
    <>
      <Paper className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start" elevation={ 6 }>
        <p className="text-xl ml-5 mb-3 mt-3">Reports</p>
      </Paper>
      <ReportsList />
    </>
  );
}

export default Reports;
