"use client";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import useSWR, { mutate } from "swr";
import FormCheckboxGroup from "../components/FormCheckboxGroup";
import FormCheckBox from "../components/FormChekBox";
import FormInput from "../components/FormInput";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { axiosPost, axiosPut } from "../utils/axios";
import fetcher from "../utils/fetcher";
import Custom500 from "../error/500";

const defaultValues = {
  name: "",
  realtimeCodesEnabled: false,
  members: []
};


export default function OrganizationForm({
  open,
  handleClose,
  isNewOrganization,
  organization,
  ownerGroup
}) {
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit
  } = useForm({
    defaultValues
  });
  const config = { label: "label", value: "value" };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [status, setStatus] = useState({});

  const { data, error, isLoading } = useSWR(
    !isNewOrganization ? `${process.env.REACT_APP_DIST_URL}/orgs/${organization.id}` : null,
    fetcher
  );

  const { data: ownerGroupMembers } = useSWR(
    ownerGroup
      ? `${process.env.REACT_APP_ACCESS_MGMT_URL}/owner-groups/${ownerGroup}/users`
      : null,
    fetcher
  );

  // Save new organization
  const onSubmit = handleSubmit((inputs) => {
    console.log('inputs::::', inputs);
    isNewOrganization ? createOrganization(inputs) : updateOrganization(inputs);
  });

  function createOrganization(payload) {
    setIsSubmitting(true);
    payload.ownerGroup = ownerGroup;
    axiosPost(`${process.env.REACT_APP_DIST_URL}/orgs`, payload)
      .then(({ data }) => {
        console.log(data);
        setStatus({ state: "success", message: "Organization created successfully" });
      })
      .catch((error) => {
        console.log(error);
        setStatus({ state: "error", message: "Unable to create organization" });
        const message = error.response.data.message.split(".");
        setStatus({ state: "error", message: message[4] || "Unable to create organization" });
      })
      .finally(() => {
        closeForm();
      });
  }

  function updateOrganization(payload) {
    setIsSubmitting(true);
    payload.ownerGroup = ownerGroup;
    console.log('payload::::', payload);
    axiosPut(`${process.env.REACT_APP_DIST_URL}/orgs/${organization.id}`, payload)
      .then(({ data }) => {
        console.log(data);
        setStatus({ state: "success", message: "Organization updated successfully" });
      })
      .catch((error) => {
        console.log(error);
        setStatus({ state: "error", message: "Unable to update organization" });
        const message = error.response.data.message.split(".");
        setStatus({ state: "error", message: message[4] || "Unable to update organization" });
      })
      .finally(() => {
        closeForm();
      });
  }

  let members = [];
  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={ isLoading } />;
  if (!isNewOrganization) {
    const organizationData = data.organization;
    const fields = ["name", "realtimeCodesEnabled"];
    fields.forEach((field) => {
      setValue(field, organizationData[field]);
    });
    const members = data.members.map((member) => member.userId);
    // const members = data.members.map((member) => ({
    //   label: `${member.firstName} ${member.lastName}`,
    //   value: member.userId
    // }));
    setValue("members", members);
  }
  if (ownerGroupMembers) {
    members = ownerGroupMembers.map((member) => ({
      label: `${member.firstName} ${member.lastName}`,
      value: member.userId
    }));
  }

  const closeForm = () => {
    reset(defaultValues);
    handleClose();
    setIsSubmitting(false);
    mutate(`${process.env.REACT_APP_DIST_URL}/orgs`);
    return;
  };

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={ open } onClose={ handleClose }>
        <DialogTitle>{isNewOrganization ? "Create a new Organization" : "Edit Organization"}</DialogTitle>
        <DialogContent>
          <form>
            <FormInput
              name="name"
              rules={ { required: { value: true, message: "Organization Name is Mandatory " } } }
              control={ control }
              label="Organization Name"
            />
            <FormCheckBox
              name="realtimeCodesEnabled"
              control={ control }
              label="Can Recieve Realtime Codes* "
              error={ !!errors.realtimeCodesEnabled }
            />
            <FormCheckboxGroup
              control={ control }
              name="members"
              options={ members }
              rules={ { required: { value: true, message: "Please select atleast one Member" } } }
              label="Select Members"
            />
          </form>
          {isSubmitting && <Loader open={isSubmitting} />}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={ handleClose }>
            Cancel
          </Button>
          <Button variant="contained" onClick={ onSubmit }>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      { status.state && (
        <Notification
          message={ status.message }
          type={ status.state }
          open={ true }
          handleClose={ () => setStatus({}) }
        />
      ) }
    </>
  );
}
