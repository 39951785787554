"use client";

import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import fetcher from "../../utils/fetcher";
import FormSelect from "../FormSelect";
import Loader from "../Loader";
import { axiosGet } from "../../utils/axios";
import Custom500 from "../../error/500";

const defaultValues = {
  role: "",
  user: "",
};

export default function SwitchUser({ open, handleClose }) {
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
  });

  const roleId = watch("role");
  let userList = [];

  const [roles, setRoles] = useState();
  useEffect(() => {
    axiosGet(`${process.env.REACT_APP_ACCESS_MGMT_URL}/roles`).then(({ data }) => {
      if (data.length > 0) {
        const roleList = data.map((role) => ({
          label: role.desc,
          value: role.id,
        }));
        setRoles(roleList);
      }
    });
  }, [open]);
  // const {
  //   data: roles,
  //   error,
  //   isLoading,
  // } = useSWR(`${process.env.REACT_APP_ACCESS_MGMT_URL}/roles`, fetcher);

  const {
    data: users,
    error: userError,
    isLoading: userLoading,
  } = useSWR(
    roleId ? `${process.env.REACT_APP_ACCESS_MGMT_URL}/roles/${roleId}/users` : null,
    fetcher,
  );

  // if (error) return <Custom500 statusCode={500} />;
  // if (isLoading) return <Loader open={isLoading} />;
  if (userError) return <Custom500 statusCode={500} />;
  if (userLoading) return <Loader open={userLoading} />;
  // Save new organization
  const onSubmit = handleSubmit((inputs) => {
    let userData = users.filter((item) => {
      if (item.userId === inputs.user) {
        return {
          userId: item.userId,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          roleId: item.roleId,
        };
      }
    });
    axiosGet(`${process.env.REACT_APP_ACCESS_MGMT_URL}/users/${inputs.user}/rolesownergroups`)
      .then(({ data }) => {
        console.log(data);
        const encodedString = btoa(
          JSON.stringify(
            {
              user: userData[0],
              roleId: inputs.role,
              roles: data.roles,
              ownerGroups: data.ownerGroups,
              timestamp: Date.now(),
            },
            null,
            2,
          ),
        );
        console.log(encodedString);
        localStorage.setItem("impersonated", encodedString);
        const decodedString = atob(encodedString);
        console.log(decodedString);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        closeForm();
      });
  });

  if (users) {
    userList = users.map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.userId,
    }));
    userList.sort();
  }
  const closeForm = () => {
    reset(defaultValues);
    handleClose();
    return;
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle>Switch User</DialogTitle>
      <DialogContent>
        <form>
          <FormSelect
            rules={{
              required: { value: true, message: "This is a required field" },
            }}
            name="role"
            items={roles}
            control={control}
            label="Role"
            error={!!errors.role}
          />
          <FormSelect
            rules={{
              required: { value: true, message: "This is a required field" },
            }}
            name="user"
            items={userList}
            control={control}
            label="User"
            error={!!errors.user}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Impersonate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
