"use client";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { axiosPost, axiosPut } from "../utils/axios";
import fetcher from "../utils/fetcher";
import { useNavigate } from "react-router-dom";
import Custom500 from "../error/500";

const defaultValues = {
  name: "",
  desc: "",
  closureColor: "",
  closureDiameter: "",
};

const closureDiameter = [
  { label: "28mm", value: "28mm" },
  { label: "38mm", value: "38mm" },
  { label: "43mm", value: "43mm" },
];
export default function ClosureForm({ open, handleClose, editMode, closureType, ownerGroup }) {
  const [status, setStatus] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [closureTrademark, setClosureTrademark] = useState();
  const [closureBrand, setClosureBrand] = useState();
  const [closureBeverage, setClosureBeverage] = useState();
  const [productList, setProductList] = useState([]);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  let closureColorOptions = [];

  const {
    data: closureData,
    error,
    isLoading,
  } = useSWR(
    editMode ? `${process.env.REACT_APP_PROGRAM_MGMT_URL}/closure-types/${closureType.id}` : null,
    fetcher,
  );
  useEffect(() => {
    if (editMode) {
      setProductList(closureData?.closureDescriptor.products);
    }
  }, [closureData]);

  const { data: trademarks } = useSWR(
    `${process.env.REACT_APP_REFDATA_URL}/pdm/trademarks`,
    fetcher,
  );
  const { data: brands } = useSWR(
    closureTrademark
      ? `${process.env.REACT_APP_REFDATA_URL}/pdm/trademarks/${closureTrademark}/brands`
      : null,
    fetcher,
  );
  const { data: beverages } = useSWR(
    closureBrand
      ? `${process.env.REACT_APP_REFDATA_URL}/pdm/trademarks/${closureTrademark}/brands/${closureBrand}/beverages`
      : null,
    fetcher,
  );
  const { data: closureColors } = useSWR(
    `${process.env.REACT_APP_REFDATA_URL}/pdm/closures/colors`,
    fetcher,
  );

  if (closureColors) {
    closureColors.map((color) => {
      closureColorOptions.push({ label: color.name, value: color.id });
    });
  }
  const handleTradeMarkChange = (event) => {
    setClosureTrademark(event.target.value);
  };

  const handleBrandChange = (event) => {
    setClosureBrand(event.target.value);
  };

  const handleBeverageChange = (event) => {
    setClosureBeverage(event.target.value);
  };

  const handleOnRemoveBeverage = (beverage) => {
    // const productListCopy = [...productList];
    const productListCopy = productList.filter((product) => product.beverageCode !== beverage);
    setProductList(productListCopy);
    // productListCopy.forEach((product) => {
    //   if (product.beverageCode == beverage) {
    //     setProductList(productListCopy.filter(product));
    //   }
    // });
  };

  const handleAddProduct = () => {
    const filteredBeverage = beverages.filter((bev) => bev.id === closureBeverage);
    setProductList([
      ...productList,
      {
        trademarkCode: closureTrademark,
        brandCode: closureBrand,
        beverageCode: closureBeverage,
        beverageName: filteredBeverage[0].name,
      },
    ]);
    setClosureTrademark(null);
    setClosureBrand(null);
    setClosureBeverage(null);
  };

  // Save new promotion
  const onSubmit = handleSubmit((inputs) => {
    editMode ? updateClosureType(inputs) : createClosureType(inputs);
  });

  function createClosureType(payload) {
    setIsSubmitting(true);
    payload.closureDescriptor.products = productList;
    payload.ownerGroup = ownerGroup;
    if (productList.length > 0) {
      axiosPost(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/closure-types`, payload)
        .then(() => {
          setStatus({
            state: "success",
            message: "ClosureType created successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          setStatus({ state: "error", message: "Unable to create Closure Matrix" });
          const message = error.response.data.message.split(".");
          setStatus({ state: "error", message: message[4] || "Unable to create Closure Matrix" });
        })
        .finally(() => {
          closeForm();
        });
    } else {
      setIsSubmitting(false);
      setStatus({ state: "error", message: "Product List should not be empty" });
    }
  }

  function updateClosureType(payload) {
    setIsSubmitting(true);
    payload.closureDescriptor.products = productList;
    if (productList.length > 0) {
      axiosPut(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/closure-types/${closureType.id}`, payload)
        .then(() => {
          setStatus({
            state: "success",
            message: "ClosureType updated successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          setStatus({ state: "error", message: "Unable to update Closure" });
          const message = error.response.data.message.split(".");
          setStatus({ state: "error", message: message[4] || "Unable to update Closure" });
        })
        .finally(() => {
          closeForm();
        });
    } else {
      setStatus({ state: "error", message: "Product List should not be empty" });
    }
  }
  const closeForm = () => {
    reset(defaultValues);
    handleClose();
    setIsSubmitting(false);
    navigate(0);
    return;
  };

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  if (editMode) {
    const fields = [
      "name",
      "closureDescriptor.closureColorId",
      "closureDescriptor.closureDiameterId",
    ];
    fields.forEach((field) => {
      if (field.includes("closureDescriptor")) {
        const key = field.split(".")[1];
        setValue(field, closureData.closureDescriptor[key]);
      } else {
        setValue(field, closureData[field]);
      }
    });
  }
  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>Create a new Closure List</DialogTitle>
        <DialogContent>
          <form id="closureForm">
            <FormInput
              name="name"
              rules={{
                required: { value: true, message: "List Name is required." },
              }}
              control={control}
              label="List Name*"
            />
            <FormSelect
              name="closureDescriptor.closureColorId"
              items={closureColorOptions}
              control={control}
              label="Closure Color"
              error={!!errors.closureColor}
            />
            <FormSelect
              rules={{
                required: { value: true, message: "This is a required field" },
              }}
              name="closureDescriptor.closureDiameterId"
              items={closureDiameter}
              control={control}
              label="Closure Diameter*"
              error={!!errors.closureDiameter}
            />
            <div className="flex gap-x-24 p-2">
              <span className="p-1 w-64">Products*</span>
              <Paper variant="elevation" className="bg-teal-800 w-96 h-auto">
                <List dense={true}>
                  {productList?.map((product) => (
                    <ListItem
                      key={product.beverageCode}
                      secondaryAction={
                        <IconButton
                          color="error"
                          onClick={() => handleOnRemoveBeverage(product.beverageCode)}
                          aria-label="delete"
                          size="large"
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={product.beverageName} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </div>
          </form>

          <Divider />
          <span>Options</span>
          <form id="closureOptionsForm">
            <div className="flex gap-x-24 p-2">
              <span className="p-1 w-64">Closure Trademark</span>
              <Select className="w-96 h-10" defaultValue="" onChange={handleTradeMarkChange}>
                {trademarks &&
                  trademarks.map((item) => (
                    <MenuItem key={item.name} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div className="flex gap-x-24 p-2">
              <span className="p-1 w-64">Closure Brand</span>
              <Select className="w-96 h-10" defaultValue="" onChange={handleBrandChange}>
                {brands &&
                  brands.map((item) => (
                    <MenuItem key={item.name} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div className="flex gap-x-24 p-2">
              <span className="p-1 w-64">Closure Beverage</span>
              <Select className="w-96 h-10" defaultValue="" onChange={handleBeverageChange}>
                {beverages &&
                  beverages.map((item) => (
                    <MenuItem key={item.name} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <Button variant="contained" color="info" onClick={handleAddProduct}>
              Add
            </Button>
          </form>
          {isSubmitting && <Loader open={isSubmitting} />}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeForm}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} variant="contained" onClick={onSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
    </>
  );
}
