"use client";
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { axiosPost, axiosPut, axiosGet } from "../utils/axios";
import fetcher from "../utils/fetcher";
import Custom500 from "../error/500";
import { useNavigate } from "react-router-dom";

import {
  CODEGEN_STATE_STARTED,
  CODEGEN_STATE_EDIT,
  CODEGEN_STATE_INPROGRESS,
} from "./ReleaseUtils";

const defaultValues = {
  name: "",
  codesAlloted: "",
  pointValue: 0,
  pointType: "",
  promotion: "none",
  codeRedemptionRules: {
    validAttempts: "",
    validAttemptsPeriod: "",
    validAttemptsPeriodValue: "",
  },
  product: {
    trademarkName: "",
    trademarkId: "none",
    brandName: "",
    brandId: "none",
    beverageName: "",
    beverageId: "none",
    primaryContainerName: "",
    primaryContainerId: "none",
    secondaryPackageName: "",
    secondaryPackageId: "none",
    closureColorName: "",
    closureColorId: "none",
    closureDiameterName: "",
    closureDiameterId: "none",
    closureTypeId: "none",
    closureTypeName: "",
  },
};

const pointTypeOptions = [
  { label: "Regular", value: "REGULAR" },
  { label: "Bonus", value: "BONUS" },
  { label: "Special", value: "SPECIAL" },
];
const periodOptions = [
  { label: "Minutes", value: "MINUTE" },
  { label: "Hours", value: "HOUR" },
  { label: "Days", value: "CALENDAR_DAY" },
  { label: "Weeks", value: "CALENDAR_WEEK" },
  { label: "Months", value: "MONTH" },
  { label: "Years", value: "YEAR" },
  { label: "Lifetime", value: "LIFETIME" },
];
export default function LotsForm({
  open,
  handleClose,
  editMode,
  programId,
  release,
  lot,
  program,
}) {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues,
  });
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState({});
  const [productDisabledState, setProductDisabledState] = useState(false);
  // const [trademarks, setTradeMarks] = useState();
  // Watch PDM Values
  const trademark = watch("product.trademarkId");
  const brand = watch("product.brandId");
  const beverage = watch("product.beverageId");
  const primaryContainerId = watch("product.primaryContainerId");
  const secondaryPackageId = watch("product.secondaryPackageId");
  useEffect(() => {
    if (primaryContainerId && primaryContainerId !== "none") {
      setValue("product.secondaryPackageId", "none");
    }
  }, [primaryContainerId, setValue]);

  useEffect(() => {
    if (secondaryPackageId && secondaryPackageId !== "none") {
      setValue("product.primaryContainerId", "none");
    }
  }, [secondaryPackageId, setValue]);

  useEffect(() => {
    const closureTypeId = watch("product.closureTypeId");
    const selectedTrademark = watch("product.trademarkId");
    // Run this effect when closureTypeId changes and is not "none"
    if (closureTypeId !== "none") {
      // Reset all other fields to "none"
      setProductDisabledState(true);
      setValue("product.trademarkId", "none", { shouldValidate: true });
      setValue("product.brandId", "none", { shouldValidate: true });
      setValue("product.beverageId", "none", { shouldValidate: true });
      setValue("product.primaryContainerId", "none", { shouldValidate: true });
      setValue("product.secondaryPackageId", "none", { shouldValidate: true });
      setValue("product.closureColorId", "none", { shouldValidate: true });
      setValue("product.closureDiameterId", "none", { shouldValidate: true });
    } else {
      setProductDisabledState(false);
    }
    // If the trademark is "none", set the closure type to "none"
    // if (selectedTrademark !== "none") {
    //   setValue("product.closureTypeId", "none", { shouldValidate: true });
    // }
  }, [watch("product.closureTypeId"), watch("product.trademarkId"), setValue]);

  const {
    data: lotData,
    error,
    isLoading,
  } = useSWR(
    editMode
      ? `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/lots/${lot.id}`
      : null,
    fetcher,
  );

  useEffect(() => {
    if (editMode && lotData) {
      reset({
        name: lotData?.name,
        codesAlloted: lotData?.codesAlloted,
        pointValue: lotData?.pointValue || "0",
        pointType: lotData?.pointType || "none",
        promotion: lotData?.promotion || "none",
        codeRedemptionRules: {
          validAttempts: lotData?.codeRedemptionRules?.validAttempts,
          validAttemptsPeriod: lotData?.codeRedemptionRules?.validAttemptsPeriod,
          validAttemptsPeriodValue: lotData?.codeRedemptionRules?.validAttemptsPeriodValue,
        },
        product: {
          trademarkId: lotData?.product?.trademarkId || "none",
          brandId: lotData?.product?.brandId || "none",
          beverageId: lotData?.product?.beverageId || "none",
          primaryContainerId: lotData?.product?.primaryContainerId || "none",
          secondaryPackageId: lotData?.product?.secondaryPackageId || "none",
          closureColorId: lotData?.product?.closureColorId || "none",
          closureDiameterId: lotData?.product?.closureDiameterId || "none",
          closureTypeId: lotData?.product?.closureTypeId || "none",
        },
      });
    }
  }, [open, lotData]);

  useEffect(() => {
    if (CODEGEN_STATE_INPROGRESS.includes(release?.state)) {
      setStatus({
        state: "warning",
        message:
          "Code generation for this Lot has already started. Lot can be edited only after code generation is complete",
      });
    }
  }, [release]);
  //Promtions
  const { data: promotions } = useSWR(
    `${process.env.REACT_APP_PROGRAM_MGMT_URL}/promotions`,
    fetcher,
  );
  const promotionsOptions = [{ label: "None", value: "none" }];

  if (promotions) {
    if (editMode) {
      const promotionIdFromApi = getValues("promotion");
      if (promotionIdFromApi && promotionIdFromApi !== "none") {
        const promotionExists = promotions.find((promotion) => promotion.id === promotionIdFromApi);
        if (promotionExists) {
          const promotionsKV = promotions.map((promotion) => ({
            label: promotion.name,
            value: promotion.id,
          }));
          promotionsOptions.push(...promotionsKV);
        } else {
          setValue("promotion", "none");
        }
      }
    } else {
      const promotionsKV = promotions.map((promotion) => ({
        label: promotion.name,
        value: promotion.id,
      }));
      promotionsOptions.push(...promotionsKV);
    }
  }

  const { data: trademarks } = useSWR(
    `${process.env.REACT_APP_REFDATA_URL}/pdm/trademarks`,
    fetcher,
  );

  const trademarksOptions = [{ label: "None", value: "none" }];
  if (trademarks) {
    const trademarksKV = trademarks.map((trademark) => ({
      label: trademark.name,
      value: trademark.id,
    }));
    trademarksOptions.push(...trademarksKV);
  }

  // Brands
  const { data: brands } = useSWR(
    trademark ? `${process.env.REACT_APP_REFDATA_URL}/pdm/trademarks/${trademark}/brands` : null,
    fetcher,
  );

  const brandOptions = [{ label: "None", value: "none" }];
  if (brands) {
    const brandKV = brands.map((brand) => ({
      label: brand.name,
      value: brand.id,
    }));
    brandOptions.push(...brandKV);
  }
  // Beverages
  const { data: beverages } = useSWR(
    brand
      ? `${process.env.REACT_APP_REFDATA_URL}/pdm/trademarks/${trademark}/brands/${brand}/beverages`
      : null,
    fetcher,
  );

  const beverageOptions = [{ label: "None", value: "none" }];
  if (beverages) {
    const beverageKV = beverages.map((beverage) => ({
      label: beverage.name,
      value: beverage.id,
    }));
    beverageOptions.push(...beverageKV);
  }
  beverageOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

  // Primary Container
  const { data: primaryContainers } = useSWR(
    beverage ? `${process.env.REACT_APP_REFDATA_URL}/pdm/${beverage}/primary-containers` : null,
    fetcher,
  );
  const primaryContainersOptions = [{ label: "None", value: "none" }];

  if (primaryContainers) {
    const primaryContainersKV = primaryContainers.map((primaryContainer) => ({
      label: primaryContainer.name,
      value: primaryContainer.id,
    }));
    primaryContainersOptions.push(...primaryContainersKV);
  }

  // Secondary Packages
  const { data: secondaryPackages } = useSWR(
    beverage ? `${process.env.REACT_APP_REFDATA_URL}/pdm/${beverage}/secondary-packages` : null,
    fetcher,
  );
  const secondaryPackagesOptions = [{ label: "None", value: "none" }];

  if (secondaryPackages) {
    const secondaryPackagesKV = secondaryPackages.map((secondaryPackage) => ({
      label: secondaryPackage.name,
      value: secondaryPackage.id,
    }));
    secondaryPackagesOptions.push(...secondaryPackagesKV);
  }

  // Closure Color
  const { data: closureColors } = useSWR(
    `${process.env.REACT_APP_REFDATA_URL}/pdm/closures/colors`,
    fetcher,
  );
  const closureColorsOptions = [{ label: "None", value: "none" }];

  if (closureColors) {
    const closureColorsKV = closureColors.map((closureColor) => ({
      label: closureColor.name,
      value: closureColor.id,
    }));
    closureColorsOptions.push(...closureColorsKV);
  }

  // Closure Diameter
  const { data: closureDiameters } = useSWR(
    `${process.env.REACT_APP_REFDATA_URL}/pdm/closures/diameters`,
    fetcher,
  );
  const closureDiametersOptions = [{ label: "None", value: "none" }];

  if (closureDiameters) {
    const closureDiametersKV = closureDiameters.map((closureDiameter) => ({
      label: closureDiameter.name,
      value: closureDiameter.id,
    }));
    closureDiametersOptions.push(...closureDiametersKV);
  }

  // Closure Type
  const { data: closureTypes } = useSWR(
    `${process.env.REACT_APP_PROGRAM_MGMT_URL}/closure-types`,
    fetcher,
  );
  const closureTypeOptions = [{ label: "None", value: "none" }];

  if (closureTypes) {
    const closureTypesKV = closureTypes.map((closureType) => ({
      label: closureType.name,
      value: closureType.id,
    }));
    closureTypeOptions.push(...closureTypesKV);
  }
  closureTypeOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

  // Save new lot
  const onSubmit = handleSubmit((inputs) => {
    inputs.codesAlloted = Number(inputs.codesAlloted);
    inputs.pointValue = Number(inputs.pointValue);
    inputs.product.trademarkName = trademarksOptions.reduce((acc, el) => {
      if (el.value === trademark) {
        acc = el.label;
      }
      return acc;
    }, "none");
    inputs.product.brandName = brandOptions.reduce((acc, el) => {
      if (el.value === brand) {
        acc = el.label;
      }
      return acc;
    }, "none");

    inputs.product.beverageName = beverageOptions.reduce((acc, el) => {
      if (el.value === beverage) {
        acc = el.label;
      }
      return acc;
    }, "none");

    const selectedPrimaryContainer = inputs.product.primaryContainerId;
    inputs.product.primaryContainerName = primaryContainersOptions.reduce((acc, el) => {
      if (el.value === selectedPrimaryContainer) {
        acc = el.label;
      }
      return acc;
    }, "none");

    const selectedSecondaryPackageId = inputs.product.secondaryPackageId;
    inputs.product.secondaryPackageName = secondaryPackagesOptions.reduce((acc, el) => {
      if (el.value === selectedSecondaryPackageId) {
        acc = el.label;
      }
      return acc;
    }, "none");

    const selectedClosureColorId = inputs.product.closureColorId;
    inputs.product.closureColorName = closureColorsOptions.reduce((acc, el) => {
      if (el.value === selectedClosureColorId) {
        acc = el.label;
      }
      return acc;
    }, "none");

    const selectedClosureDiameterId = inputs.product.closureDiameterId;
    inputs.product.closureDiameterName = closureDiametersOptions.reduce((acc, el) => {
      if (el.value === selectedClosureDiameterId) {
        acc = el.label;
      }
      return acc;
    }, "none");

    const selectedClosureTypeId = inputs.product.closureTypeId;
    inputs.product.closureTypeName = closureTypeOptions.reduce((acc, el) => {
      if (el.value === selectedClosureTypeId) {
        acc = el.label;
      }
      return acc;
    }, "none");

    editMode ? updateLot(inputs) : createLot(inputs);
  });

  function createLot(payload) {
    setIsSubmitting(true);
    axiosPost(
      `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/lots`,
      payload,
    )
      .then(({ data }) => {
        console.log(data);
        setStatus({ state: "success", message: "Lot created successfully" });
        closeForm();
      })
      .catch((error) => {
        console.log(error);
        setStatus({ state: "error", message: "Unable to create lot" });
        const message = error.response?.data?.message;
        setStatus({
          state: "error",
          message: `Unable to create lot, please check your inputs - ${message}`,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  function updateLot(payload) {
    setIsSubmitting(true);
    axiosPut(
      `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/lots/${lot.id}`,
      payload,
    )
      .then(({ data }) => {
        console.log(data);
        setStatus({ state: "success", message: "Lot updated successfully" });
        closeForm();
      })
      .catch((error) => {
        console.log(error);
        setStatus({ state: "error", message: "Unable to update lot" });
        const message = error.response?.data?.message;
        setStatus({
          state: "error",
          message: `Unable to create/Update Lot, please check your inputs - ${message}`,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;

  const closeForm = () => {
    reset(defaultValues);
    handleClose();
    navigate(0);
  };
  const hasallocatedCodes = typeof(program.programSummary?.allocatedCodes) === "undefined" ? 0 : program.programSummary?.allocatedCodes;
  const hasallotedCodes = typeof(program.programSummary?.allotedCodes) === "undefined" ? 0 : program.programSummary?.allotedCodes;
  const usedCodes =  hasallocatedCodes + hasallotedCodes;
  const availableCodes = program.codeDescriptor?.maxCount - usedCodes;
  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={closeForm}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeForm} aria-label="close">
              <CloseIcon />
            </IconButton>
            {editMode ? (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit Lot
              </Typography>
            ) : (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Create a new Lot
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form>
            <FormInput
              name="name"
              rules={{
                required: { 
                  value: true, 
                  message: "This is a required field",
                },
                pattern: {
                  value: /^[a-zA-Z0-9 ]+$/,
                  message: "AlphaNumaric only No Special chars in Lot Name",
                },
              }}
              control={control}
              label="Lot Name*"
              error={!!errors.name}
            />
            <FormInput
              name="codesAlloted"
              rules={{
                required: { value: true, message: "This is a required field" },
              }}
              disabled={CODEGEN_STATE_STARTED.includes(release?.state)}
              control={control}
              label="Number of Codes*"
            />
            <p className="subpixel-antialiased italic underline decoration-sky-500">{`${availableCodes || 0} code available in program`}</p>
            <FormSelect
              name="promotion"
              rules={{
                required: { value: true, message: "This is a required field" },
              }}
              items={promotionsOptions}
              control={control}
              label="Promotion*"
            />
            <div className="grid grid-cols-4 gap-1">
              <CustomInput
                name="codeRedemptionRules.validAttempts"
                control={control}
                label="Max valid attempts"
              />
              <CustomInput
                name="codeRedemptionRules.validAttemptsPeriodValue"
                control={control}
                label="per"
              />
              <CustomSelect
                name="codeRedemptionRules.validAttemptsPeriod"
                items={periodOptions}
                control={control}
                label="period"
              />
            </div>
            <FormInput
              name="pointValue"
              rules={{
                valueAsNumber: true,
                required: { value: true, message: "This is a required field" },
              }}
              control={control}
              label="Point value"
            />
            <FormSelect
              name="pointType"
              items={pointTypeOptions}
              control={control}
              label="Point Type"
            />
            <span className="subpixel-antialiased italic underline decoration-sky-500">
              <b>Intended Use:</b> If Lot information includes closure information and the
              brand,trademark ,product, package is known please select all the attributes. If these
              attributes are not known please select the closure list.
            </span>
            <FormSelect
              name="product.trademarkId"
              items={trademarksOptions}
              control={control}
              label="Trademark"
              disabled={productDisabledState}
            />
            <FormSelect
              name="product.brandId"
              items={brandOptions}
              control={control}
              label="Brand"
              disabled={productDisabledState}
            />
            <FormSelect
              name="product.beverageId"
              items={beverageOptions}
              control={control}
              label="Product Beverage"
              disabled={productDisabledState}
            />
            <FormSelect
              name="product.primaryContainerId"
              items={primaryContainersOptions}
              control={control}
              label="Primary Container"
              rules={{
                validate: (value) => {
                  if (value && value !== "none") {
                    const secondaryPkg = getValues("product.secondaryPackageId");
                    return (
                      !secondaryPkg ||
                      secondaryPkg === "none" ||
                      "Only one of Primary Container or Secondary package needs to be selected"
                    );
                  }
                },
              }}
              disabled={productDisabledState}
            />
            <span className="text-center">Or</span>
            <FormSelect
              name="product.secondaryPackageId"
              items={secondaryPackagesOptions}
              control={control}
              label="Secondary Package"
              rules={{
                validate: (value) => {
                  if (value && value !== "none") {
                    console.log("here");
                    const primaryCont = getValues("product.primaryContainerId");
                    return (
                      !primaryCont ||
                      primaryCont === "none" ||
                      "Only one of Primary Container or Secondary package needs to be selected"
                    );
                  }
                },
              }}
              disabled={productDisabledState}
            />
            <FormSelect
              name="product.closureColorId"
              items={closureColorsOptions}
              control={control}
              label="Closure Color"
              disabled={productDisabledState}
            />
            <FormSelect
              name="product.closureDiameterId"
              items={closureDiametersOptions}
              control={control}
              label="Closure Diameter"
              disabled={productDisabledState}
            />
            <FormSelect
              name="product.closureTypeId"
              items={closureTypeOptions}
              control={control}
              label="Closure Type"
            />
          </form>
          {isSubmitting && <Loader open={isSubmitting} />}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeForm}>
            Cancel
          </Button>
          {!CODEGEN_STATE_INPROGRESS.includes(release?.state) && (
            <Button variant="contained" onClick={onSubmit} disabled={isSubmitting}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
    </>
  );
}
