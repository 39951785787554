"use client";

import Paper from "@mui/material/Paper";
import React from "react";
import { useSearchParams } from "react-router-dom";
import ReportResult from "../../ReportResult";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 250,
    minWidth: 250
  },
  {
    field: "name",
    headerName: "Name",
    width: 250,
    minWidth: 250
  },
  {
    field: "country",
    headerName: "Country",
    width: 100,
    minWidth: 100
  },
  {
    field: "used",
    headerName: "generated",
    width: 250,
    minWidth: 250
  },
  {
    field: "allocatedcodes",
    headerName: "Allocated",
    width: 250,
    minWidth: 250
  },
  {
    field: "availablecodes",
    headerName: "Available",
    width: 250,
    minWidth: 250
  },
  {
    field: "updatedby",
    headerName: "Requestor",
    width: 250,
    minWidth: 250
  }
];

function MarketReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const url = `${process.env.REACT_APP_REPORT_URL}/report/codegen/market?startDate=${startDate}&endDate=${endDate}`;
  return (
    <>
      <Paper
        className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start"
        elevation={ 6 }
      >
        <p className="text-xl ml-5 mb-5">CODE GEN Market Report</p>
      </Paper>
      <ReportResult columns={ columns } url={ url } programDetailsEnabled={ false } />
    </>
  );
}

export default MarketReport;
