export const defaultValues = {
  programSummary: {
    name: "",
    description: "",
    startDate: null,
    endDate: null,
    country: "",
    ownerGroup: "",
    localStartTimezone: "UTC",
    localEndTimezone: "UTC"
  },
  codeDescriptor: {
    profanityCheckEnabled: false,
    dictionaryIds: [],
    length: "",
    prefix: "",
    maxCount: "",
    luhnsChecksumEnabled: false,
    pointValue: 0,
    characterSet: {
      numbers: [
        {
          code: "0",
          synonym: "",
        },
        {
          code: "1",
          synonym: "",
        },
        {
          code: "2",
          synonym: "",
        },
        {
          code: "3",
          synonym: "",
        },
        {
          code: "4",
          synonym: "",
        },
        {
          code: "5",
          synonym: "",
        },
        {
          code: "6",
          synonym: "",
        },
        {
          code: "7",
          synonym: "",
        },
        {
          code: "8",
          synonym: "",
        },
        {
          code: "9",
          synonym: "",
        },
      ],
      upperCaseAlphabets: [
        { code: "A", synonym: "" },
        { code: "B", synonym: "" },
        { code: "C", synonym: "" },
        { code: "D", synonym: "" },
        { code: "E", synonym: "" },
        { code: "F", synonym: "" },
        { code: "G", synonym: "" },
        { code: "H", synonym: "" },
        { code: "I", synonym: "" },
        { code: "J", synonym: "" },
        { code: "K", synonym: "" },
        { code: "L", synonym: "" },
        { code: "M", synonym: "" },
        { code: "N", synonym: "" },
        { code: "O", synonym: "" },
        { code: "P", synonym: "" },
        { code: "Q", synonym: "" },
        { code: "R", synonym: "" },
        { code: "S", synonym: "" },
        { code: "T", synonym: "" },
        { code: "U", synonym: "" },
        { code: "V", synonym: "" },
        { code: "W", synonym: "" },
        { code: "X", synonym: "" },
        { code: "Y", synonym: "" },
        { code: "Z", synonym: "" },
      ],
      lowerCaseAlphabets: [
        { code: "a", synonym: "" },
        { code: "b", synonym: "" },
        { code: "c", synonym: "" },
        { code: "d", synonym: "" },
        { code: "e", synonym: "" },
        { code: "f", synonym: "" },
        { code: "g", synonym: "" },
        { code: "h", synonym: "" },
        { code: "i", synonym: "" },
        { code: "j", synonym: "" },
        { code: "k", synonym: "" },
        { code: "l", synonym: "" },
        { code: "m", synonym: "" },
        { code: "n", synonym: "" },
        { code: "o", synonym: "" },
        { code: "p", synonym: "" },
        { code: "q", synonym: "" },
        { code: "r", synonym: "" },
        { code: "s", synonym: "" },
        { code: "t", synonym: "" },
        { code: "u", synonym: "" },
        { code: "v", synonym: "" },
        { code: "w", synonym: "" },
        { code: "x", synonym: "" },
        { code: "y", synonym: "" },
        { code: "z", synonym: "" },
      ],
    },
  },
  codeRedemptionRules: {
    validAttemptsPeriodValue: 1,
    inValidAttempts: 10,
    inValidAttemptsPeriodValue: 10,
    validAttempts: 25,
    validAttemptsPeriod: "CALENDAR_DAY",
    inValidAttemptsPeriod: "MINUTE",
    blockPeriod: "",
    blockPeriodValue: "",
  },
  members: [],
};

export const numbers = [
  {
    label: "0",
    value: "0",
  },
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "6",
    value: "6",
  },
  {
    label: "7",
    value: "7",
  },
  {
    label: "8",
    value: "8",
  },
  {
    label: "9",
    value: "9",
  },
];

export const lowerCaseAlphabets = [
  { label: "a", value: "a" },
  { label: "b", value: "b" },
  { label: "c", value: "c" },
  { label: "d", value: "d" },
  { label: "e", value: "e" },
  { label: "f", value: "f" },
  { label: "g", value: "g" },
  { label: "h", value: "h" },
  { label: "i", value: "i" },
  { label: "j", value: "j" },
  { label: "k", value: "k" },
  { label: "l", value: "l" },
  { label: "m", value: "m" },
  { label: "n", value: "n" },
  { label: "o", value: "o" },
  { label: "p", value: "p" },
  { label: "q", value: "q" },
  { label: "r", value: "r" },
  { label: "s", value: "s" },
  { label: "t", value: "t" },
  { label: "u", value: "u" },
  { label: "v", value: "v" },
  { label: "w", value: "w" },
  { label: "x", value: "x" },
  { label: "y", value: "y" },
  { label: "z", value: "z" },
];

export const upperCaseAlphabets = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "E", value: "E" },
  { label: "F", value: "F" },
  { label: "G", value: "G" },
  { label: "H", value: "H" },
  { label: "I", value: "I" },
  { label: "J", value: "J" },
  { label: "K", value: "K" },
  { label: "L", value: "L" },
  { label: "M", value: "M" },
  { label: "N", value: "N" },
  { label: "O", value: "O" },
  { label: "P", value: "P" },
  { label: "Q", value: "Q" },
  { label: "R", value: "R" },
  { label: "S", value: "S" },
  { label: "T", value: "T" },
  { label: "U", value: "U" },
  { label: "V", value: "V" },
  { label: "W", value: "W" },
  { label: "X", value: "X" },
  { label: "Y", value: "Y" },
  { label: "Z", value: "Z" },
];

export const numbersPayload = [
  {
    code: "0",
    synonym: "",
  },
  {
    code: "1",
    synonym: "",
  },
  {
    code: "2",
    synonym: "",
  },
  {
    code: "3",
    synonym: "",
  },
  {
    code: "4",
    synonym: "",
  },
  {
    code: "5",
    synonym: "",
  },
  {
    code: "6",
    synonym: "",
  },
  {
    code: "7",
    synonym: "",
  },
  {
    code: "8",
    synonym: "",
  },
  {
    code: "9",
    synonym: "",
  },
];

export const lowerCaseAlphabetsPayload = [
  { code: "a", synonym: "" },
  { code: "b", synonym: "" },
  { code: "c", synonym: "" },
  { code: "d", synonym: "" },
  { code: "e", synonym: "" },
  { code: "f", synonym: "" },
  { code: "g", synonym: "" },
  { code: "h", synonym: "" },
  { code: "i", synonym: "" },
  { code: "j", synonym: "" },
  { code: "k", synonym: "" },
  { code: "l", synonym: "" },
  { code: "m", synonym: "" },
  { code: "n", synonym: "" },
  { code: "o", synonym: "" },
  { code: "p", synonym: "" },
  { code: "q", synonym: "" },
  { code: "r", synonym: "" },
  { code: "s", synonym: "" },
  { code: "t", synonym: "" },
  { code: "u", synonym: "" },
  { code: "v", synonym: "" },
  { code: "w", synonym: "" },
  { code: "x", synonym: "" },
  { code: "y", synonym: "" },
  { code: "z", synonym: "" },
];

export const upperCaseAlphabetsPayload = [
  { code: "A", synonym: "" },
  { code: "B", synonym: "" },
  { code: "C", synonym: "" },
  { code: "D", synonym: "" },
  { code: "E", synonym: "" },
  { code: "F", synonym: "" },
  { code: "G", synonym: "" },
  { code: "H", synonym: "" },
  { code: "I", synonym: "" },
  { code: "J", synonym: "" },
  { code: "K", synonym: "" },
  { code: "L", synonym: "" },
  { code: "M", synonym: "" },
  { code: "N", synonym: "" },
  { code: "O", synonym: "" },
  { code: "P", synonym: "" },
  { code: "Q", synonym: "" },
  { code: "R", synonym: "" },
  { code: "S", synonym: "" },
  { code: "T", synonym: "" },
  { code: "U", synonym: "" },
  { code: "V", synonym: "" },
  { code: "W", synonym: "" },
  { code: "X", synonym: "" },
  { code: "Y", synonym: "" },
  { code: "Z", synonym: "" },
];
