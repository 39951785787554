import * as React from 'react';

import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

export default function DisabledInput({ name, control, label, rules, multiline, type, defaultValue }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={ defaultValue }
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className='flex gap-x-24 p-2'>
          <span className='p-1 w-64'>{label}</span>
          <TextField
            className='w-96'
            size='small'
            error={!!error}
            multiline={multiline ? multiline.enabled : false}
            rows={multiline ? multiline.rows : undefined}
            onChange={onChange}
            value={value || ""}
            fullWidth
            variant='outlined'
            type={type ? type : 'text'}
            disabled
          />
          <FormHelperText className='text-red-500'>{error?.message}</FormHelperText>
        </div>
      )}
    />
  );
}
