
import React from "react";
import Releases from "./Releases";

export default function ReleasePanel(props) {
  const { children, value, index, programId, program, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `full-width-tabpanel-${index}` }
      aria-labelledby={ `full-width-tab-${index}` }
      { ...other }
    >
      {value === index && <Releases programId={programId} program={program} /> }
      
    </div>
  );
}
