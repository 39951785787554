"use client";

import React from "react";
import ReportResult from "../../ReportResult";
import Paper from "@mui/material/Paper";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);

const columns = [
  {
    field: "lotid",
    headerName: "Lot Id",
    width: 150,
    minWidth: 150,
  },
  {
    field: "programid",
    headerName: "Program Id",
    width: 250,
    minWidth: 250,
  },
  {
    field: "programName",
    headerName: "Program Name",
    width: 250,
    minWidth: 250,
  },
  {
    field: "consumerid",
    headerName: "Consumer Id",
    width: 250,
    minWidth: 250,
  },
  {
    field: "createdat",
    headerName: "Date",
    width: 250,
    minWidth: 250,
    valueFormatter: (params) => dayjs.utc(params?.value).format("MM/DD/YYYY"),
  },
  {
    field: "code",
    headerName: "Code",
    width: 250,
    minWidth: 250,
  },
  {
    field: "errorcode",
    headerName: "Status Code",
    width: 250,
    minWidth: 250,
  },
];

function ConsumerActivityRedemptionReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const consumerid = searchParams.get("consumerid");
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const url = `${process.env.REACT_APP_REPORT_URL}/report/redemption/consumer?consumerid=${consumerid}&startDate=${startDate}&endDate=${endDate}`;
  return (
    <>
      <Paper
        className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start"
        elevation={6}
      >
        <p className="text-xl ml-5 mb-5">Consumer Activity Report</p>
      </Paper>
      <ReportResult columns={columns} url={url} programDetailsEnabled={false} />
    </>
  );
}

export default ConsumerActivityRedemptionReport;
