"use client";

import React, { useState } from "react";
import { CreateButton } from "../components/CreateButton";
import { DataGrid } from "@mui/x-data-grid";
import CampaignForm from "./CampaignForm";
import { EditIconButton } from "../components/EditButton";
import Loader from "../components/Loader";
import useSWR, { mutate } from "swr";
import Custom500 from "../error/500";
import fetcher from "../utils/fetcher";
import dayjs from "dayjs"; 
import utc from "dayjs/plugin/utc";
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
dayjs.extend(utc);
dayjs.extend(timezone)
dayjs.extend(advanced)


export default function Campaigns({ programId }) {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_CAMPAIGN_URL}/campaigns?programId=${programId}`,
    fetcher,
  );

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 300,
      minWidth: 300,
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      minWidth: 250,
    },
    {
      field: "startDate",
      headerName: "Activated Date",
      width: 200,
      minWidth: 200,
      valueGetter: (params) => dayjs(params?.value).tz(params.row?.localActivateTimezone).format("MM/DD/YYYY"),
    },
    {
      field: "endDate",
      headerName: "Deactivated Date",
      width: 200,
      minWidth: 200,
      valueGetter: (params) => dayjs(params?.value).tz(params.row?.localInactivateTimezone).format("MM/DD/YYYY"),
    },
    {
      field: "code",
      headerName: "Code",
      width: 200,
      minWidth: 200,
    },
    {
      field: "createdBy",
      headerName: "Created By",
    },
    {
      field: "createdAt",
      headerName: "Created At (UTC)",
      width: 100,
      minWidth: 100,
      valueFormatter: (params) => dayjs.utc(params?.value).format("MM/DD/YYYY"),
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 100,
      minWidth: 100,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const row = params.row;
          mutate(`${process.env.REACT_APP_CAMPAIGN_URL}/campaigns/${row.id}`);
          setSelectedRow(row);
          setEditMode(true);
          setOpen(true);
        };

        return <EditIconButton onClick={onClick} />;
      },
    },
  ];
  if (error) {
    return <Custom500 statusCode={500} />;
  }
  if (isLoading) {
    return <Loader open={isLoading} />;
  }
  return (
    <>
      <div className="flex flex-row-reverse">
        <CreateButton
          label="Create New Campaign"
          onClick={() => {
            setOpen(true);
          }}
        />
      </div>
      <DataGrid
        rows={data}
        getRowId={(row) => row.id}
        columns={columns}
        checkboxSelection={false}
        onSelectionModelChange={(itm) => console.log(itm)}
        autosizeOptions={{
          columns: ["code", "name", "description"],
          includeOutliers: true,
          includeHeaders: false,
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
      <CampaignForm
        open={open}
        handleClose={handleClose}
        editMode={editMode}
        campaign={selectedRow}
        programId={programId}
      />
    </>
  );
}
