import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { EditIconButton } from "../components/EditButton";
import { CreateButton } from "../components/CreateButton";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReleaseForm from "./ReleaseForm";
import LotsAndBatches from "./LotsAndBatches";
import useSWR, { mutate } from "swr";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Loader from "../components/Loader";
import fetcher from "../utils/fetcher";
import Custom500 from "../error/500";
import Notification from "../components/Notification";
import { axiosPost } from "../utils/axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
import { Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const columns = [
  {
    field: "name",
    headerName: "Name",
  },
  {
    field: "createdDate",
    headerName: "Created Date",
  },
  {
    field: "startDate",
    headerName: "Start Date",
  },
  {
    field: "endDate",
    headerName: "End Date",
  },
  {
    field: "created",
    headerName: "Created",
  },
  {
    field: "state",
    headerName: "State",
  },
];

export default function Releases({ programId, program }) {
  const [status, setStatus] = useState({});
  const [selectedRelease, setSelectedRelease] = useState({});
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseReleaseDialog = () => {
    setOpenCreateDialog(false);
  };

  const {
    data: releases,
    error,
    isLoading,
  } = useSWR(`${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases`, fetcher);

  useEffect(() => {
    setRows(releases);
  }, [releases]);

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;

  const handleSearchRelease = (e) => {
    const filteredRows = releases.filter((row) => {
      return row.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setRows(filteredRows);
  };

  const handleGenerateCode = (releaseId) => {
    setIsSubmitting(true);

    axiosPost(`${process.env.REACT_APP_CODEGEN_URL}/codegen/releases/${releaseId}/batch-jobs`)
      .then((res) => {
        setStatus({ state: "success", message: "Code Generation request submitted successfully" });
        mutate(`${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases`);
      })
      .catch((err) => {
        console.log(err);
        setStatus({ state: "error", message: "⚠️ Unable to submit Code Generation request" });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const Row = (props) => {
    const { release, programId, program } = props;
    const [collapsed, setCollapsed] = useState(false);

    return (
      <React.Fragment>
        <TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            background: collapsed ? "#2f2f2f" : "#49565f",
          }}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="left">
            {release.id}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(release.id);
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </TableCell>
          <TableCell align="left">
            {release.name}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(release.name);
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </TableCell>
          <TableCell align="left">{dayjs.utc(release.createdAt).format("MM/DD/YYYY")}</TableCell>
          <TableCell align="left">
            {dayjs(release.startDate).tz(release.localActivateTimezone).format("MM/DD/YYYY")}
          </TableCell>
          <TableCell align="left">
            {dayjs(release.endDate).tz(release.localInactivateTimezone).format("MM/DD/YYYY")}
          </TableCell>
          <TableCell align="left">{release.created}</TableCell>
          <TableCell align="left">{release.state}</TableCell>
          <TableCell align="left">
            <EditIconButton
              onClick={() => {
                setSelectedRelease(release);
                setEditMode(true);
                setOpenCreateDialog(true);
              }}
            />
          </TableCell>

          {release.state === "READY_CODEGEN" ? (
            <TableCell align="left">
              <IconButton
                size="large"
                variant="contained"
                color="warning"
                onClick={() => handleGenerateCode(release.id)}
              >
                <Tooltip title="Submit for Code Generation">
                  <IntegrationInstructionsIcon />
                </Tooltip>
              </IconButton>
            </TableCell>
          ) : (
            <TableCell align="left">N/A</TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell className="w-fit" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={collapsed} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <LotsAndBatches programId={programId} release={release} program={program} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };
  return (
    <Paper>
      <div className="flex flex-row gap-12	m-2 pt-2">
        <TextField
          fullWidth
          focused
          size="small"
          color="info"
          placeholder="Search Release by name"
          id="release-search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="search release">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          onChange={handleSearchRelease}
        />
        <CreateButton
          className="w-96"
          size="small"
          label="Create New Release"
          onClick={() => {
            setOpenCreateDialog(true);
          }}
        />
      </div>
      <TableContainer className="overflow-x-auto" component={Paper}>
        <Table className="overflow-x-auto" aria-label="releases table" size="small">
          <TableHead className="text-white bg-tab-header">
            <TableRow>
              <TableCell />
              <TableCell className="text-white">Id</TableCell>
              {columns.map((column) => (
                <TableCell className="text-white" align="left" key={column.field}>
                  {column.headerName}
                </TableCell>
              ))}
              <TableCell className="text-white">Edit</TableCell>
              <TableCell className="text-white">Submit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Row key={row.id} programId={programId} release={row} program={program} />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="bg-tab-header"
        rowsPerPageOptions={[5, 25, 100]}
        component="div"
        count={rows && rows.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ReleaseForm
        open={openCreateDialog}
        handleClose={handleCloseReleaseDialog}
        editMode={editMode}
        release={selectedRelease}
        programId={programId}
        program={program}
      />
      {!editMode && (
        <ReleaseForm
          open={openCreateDialog}
          handleClose={handleCloseReleaseDialog}
          editMode={editMode}
          programId={programId}
          program={program}
        />
      )}
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
      {isSubmitting && <Loader open={isSubmitting} />}
    </Paper>
  );
}
