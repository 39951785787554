import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import BatchForm from "./forms/codegen/BatchForm";
import CodeVolumeOrgForm from "./forms/codegen/CodeVolumeOrgForm";
import LotForm from "./forms/codegen/LotForm";
import MarketForm from "./forms/codegen/MarketForm";
import RealTimeForm from "./forms/codegen/RealTimeForm";
import ConsumerActivityForm from "./forms/redemption/ConsumerActivityForm";
import RedemptionLotForm from "./forms/redemption/RedemptionLotForm";
import RedemptionProgramForm from "./forms/redemption/RedemptionProgramForm";
import TopCodeRedemptionForm from "./forms/redemption/TopCodeRedemptionForm";
import TopRedemptionAttemptsForm from "./forms/redemption/TopRedemptionAttemptsForm";

export default function ReportDialog({ open, handleClose, report }) {
  return (
    <div>
      <Dialog
        open={ open }
        fullWidth
        maxWidth="lg"
        onClose={ handleClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={ { position: "relative" } }>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={ handleClose } aria-label="close">
              <CloseIcon />
            </IconButton>

            <Typography sx={ { ml: 2, flex: 1 } } variant="h6" component="div">
              { report.name }
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { report.value === "COEDGEN_BATCH" && (<BatchForm />) }
            { report.value === "COEDGEN_MARKET" && (<MarketForm />) }
            { report.value === "COEDGEN_LOT" && (<LotForm />) }
            { report.value === "REDEMPTION_LOT" && (<RedemptionLotForm />) }
            { report.value === "REDEMPTION_TOP_PROGRAM" && (<RedemptionProgramForm />) }
            { report.value === "REDEMPTION_PINCODE" && (<ConsumerActivityForm />) }
            { report.value === "COEDGEN_ORGANIZATION" && (<CodeVolumeOrgForm />) }
            { report.value === "REDEMPTION_TOP_CONSUMER" && (<TopCodeRedemptionForm />) }
            { report.value === "REDEMPTION_TOP_ATTEMPTS" && (<TopRedemptionAttemptsForm />) }
            { report.value === "COEDGEN_REALTIME" && (<RealTimeForm />) }

          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
