"use client";

import Paper from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";
import { useSearchParams } from "react-router-dom";

import useSWR from "swr";
import CampaignPanel from "./CampaignPanel";
import ProgramDetails from "./ProgramDetails";
import ReleasePanel from "./ReleasePanel";
import Loader from "../components/Loader";
import { StyledTab, StyledTabs } from "../components/Tabs";
import Custom500 from "../error/500";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import fetcher from "../utils/fetcher";

function Release(props) {
  const [tabIndex, setTabIndex] = React.useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const programid = searchParams.get("programid");
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs/${programid}`,
    fetcher
  );

  if (error) return <Custom500 statusCode={ 500 } />;
  if (isLoading) return <Loader open={ isLoading } />;

  return (
    <>
      <ProgramDetails program={ data } />
      <Paper elevation={3} className="bg-paper mt-2">
        <Paper elevation={3} className="mb-3" sx={ { borderBottom: 1, borderColor: "divider" } }>
          <StyledTabs
            variant="fullWidth"
            value={ tabIndex }
            onChange={ handleChange }
          >
            <StyledTab label="Releases" />
            <StyledTab label="Campaigns" />
          </StyledTabs>
        </Paper>
        <ReleasePanel value={tabIndex} index={0} programId={programid} program={data} />
        <CampaignPanel value={ tabIndex } index={ 1 } programId={ programid }  program={data}/>
      </Paper>
    </>
  );
}

export default Release;

