import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

export default function DeleteDialog({ open, handleClose, program }) {


  return (
    <div>
      <Dialog
        open={ open }
        onClose={ handleClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          { "Do you want to Delete the program?" }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { JSON.stringify(program, null, 4) }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={ handleClose }>No</Button>
          <Button variant="contained" onClick={ handleClose } autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
