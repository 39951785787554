"use client";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState } from "react";
import useSWR from "swr";
import { v4 as uuid } from "uuid";
import ProgramDetails from "./ProgramDetails";
import Loader from "../components/Loader";
import Custom500 from "../error/500";

import fetcher from "../utils/fetcher";

export default function ReportResult({ programId, url, columns, programDetailsEnabled }) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { data, error, isLoading } = useSWR(url, fetcher);
  const {
    data: programs,
    error: programError,
    isLoading: programLoading,
  } = useSWR(!programId ? `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs` : null, fetcher);

  const {
    data: programData,
    error: programDataError,
    isLoading: programDataLoading,
  } = useSWR(
    programId ? `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs/${programId}` : null,
    fetcher,
  );

  const {
    data: oragnizations,
    error: organizationError,
    isLoading: organizationLoading
  } = useSWR(`${process.env.REACT_APP_DIST_URL}/orgs`, fetcher);

  const {
    data: releases,
    error: releasesError,
    isLoading: releasesLoading,
  } = useSWR(
    programId ? `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases` : null,
    fetcher,
  );

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  if (programError) return <Custom500 statusCode={500} />;
  if (programLoading) return <Loader open={programLoading} />;
  if (programDataError) return <Custom500 statusCode={500} />;
  if (programDataLoading) return <Loader open={programDataLoading} />;
  if (releasesError) return <Custom500 statusCode={500} />;
  if (releasesLoading) return <Loader open={releasesLoading} />;
  if (organizationError) return <Custom500 statusCode={500} />;
  if (organizationLoading) return <Loader open={organizationLoading} />;
  let reportData = data
  if (programId && reportData && releases) {
    reportData.map((row) => {
      if (row.releaseid) {
        const filteredRelease = releases.filter((release)=> row.releaseid == release.id)
        row.releaseName = filteredRelease[0].name
      }
    })
    reportData.map((row) => {
      if (row.organizationid) {
        const filteredOrg = oragnizations.filter((org)=> row.organizationid == org.id)
        row.organizationName = filteredOrg[0].name
      }
    })
  } else {
    reportData.map((row) => {
      if (row.programid) {
        const filteredProgram = programs.filter((program) => row.programid == program.id)
        if (filteredProgram.length > 0) {
          row.programName = filteredProgram[0].name
        }
      }
    })
  }
  return (
    <>
      {programDetailsEnabled && <ProgramDetails program={programData} report={data} />}
      <DataGrid
        rows={reportData}
        getRowId={(row) => row.auditid || row.pk || row.id || uuid()}
        columns={columns}
        autosizeOptions={{
          includeOutliers: true,
          includeHeaders: false,
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{ toolbar: GridToolbar }}
      />
    </>
  );
}
