"use client";

import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useForm } from "react-hook-form";
import FormDatePicker from "../components/FormDatePicker";
import { axiosPostMultipart } from "../utils/axios";
import { getUserId } from "../utils/session";
import Notification from "../components/Notification";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import useSWR from "swr";
import fetcher from "../utils/fetcher";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

function PublicKey() {
  const [uploadedFile, setUploadedFile] = useState(null); 
  const { register, control, errors, watch, handleSubmit, setError, clearErrors } = useForm();
  const [status, setStatus] = useState({});
  const userId = getUserId();
  const {
    data: userData,
    error,
    isLoading,
    setValue,
  } = useSWR(`${process.env.REACT_APP_ACCESS_MGMT_URL}/users/${userId}`, fetcher);
  
  useEffect(() => {
    setUploadedFile(watch("file"));
  }, [watch, userData, setValue]);
   
  // Format date to MM/DD/YYYY
  const formatDate = (date) => {
    return dayjs.utc(date).format("MM/DD/YYYY");
  };
    
  const onSubmit = handleSubmit(async (inputs) => {
    if (!inputs.file || !inputs.file[0]) {
      setStatus({
        state: "error",
        message: "Please upload a file",
      });
      return;
    }
    const form = new FormData();
    form.append("activatedAt", dayjs.utc(inputs.activatedAt));
    form.append("deActivateAt", dayjs.utc(inputs.deActivateAt));
    form.append("File", inputs.file[0]);
    if (!inputs.file[0].name.includes(".asc")) {
      setStatus({
        state: "error",
        message: "Upload a valid .asc file",
      });
    } else {
      axiosPostMultipart(
        `${process.env.REACT_APP_ACCESS_MGMT_URL}/users/${userId}/pgpPublicKeys`,
        form,
      )
        .then(({ data }) => {
          console.log(data);
          setStatus({
            state: "success",
            message: "PGP public key uploaded successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          setStatus({
            state: "error",
            message: "Unable to upload PGP Public Key",
          });
        });
    }
  });
  return (
    <>
      <Paper className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start" elevation={6}>
        <p className="text-xl ml-5 mb-3 mt-3">Update PGP Public Key File</p>
      </Paper>
      <Paper className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start" elevation={6}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-x-24 p-2">
            <div>Member Organization(s):</div>
            <div>
              {userData?.organizations?.length === 0 && (
                <li className="list-disc gap-x-24 p-1 ml-16">
                  You are not a member of any organizations
                </li>
              )}
              {userData?.organizations?.length > 0 &&
                userData?.organizations?.map((org) => (
                  <li className="list-disc	gap-x-24 p-1 ml-16" key={org.organizationId}>
                    {org.organizationName}
                  </li>
                ))}
            </div>
          </div>
          <div className="flex gap-x-24 p-2">
            <div className="p-1 w-64">File uploaded status:</div>
            <div>
              {userData?.isPgpPublicKey ? (
                <CheckCircleIcon color="success" />
              ) : (
                <>
                  <CancelIcon color="error" />
                  You must upload a valid PGP Public Key file before you can be selected as a Code
                  File recipient.
                </>
              )}
            </div>
          </div>
          <div>
            <input
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              accept="*.asc"
              type="file"
              {...register("file")}
            />
            <label className="flex gap-x-24 p-2" htmlFor="raised-button-file">
              <span className="p-1 w-64">Public Key File*</span>
              <Button
                className="w-96"
                color="info"
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload
              </Button>              
              {!uploadedFile && <span className="text-red-500">File is required</span>}              
            </label>
          </div>
          {userData?.isPgpPublicKey && (
            <>
              <div className="flex gap-x-24 p-2">
                <div className="p-1 w-64">Created At:</div>
                <div>{userData ? formatDate(userData.activatedAt) : ""}</div>
              </div>
              <div className="flex gap-x-24 p-2">
                <div className="p-1 w-64">Deactivated At:</div>
                <div>{userData ? formatDate(userData.deActivateAt) : ""}</div>
              </div>
            </>
          )}
          {/* <div className="flex gap-x-24 p-2">
            <p className="p-1 w-64">Uploaded File: </p>
            <p className="w-96 h-10">{uploadedFile?.[0]?.name}</p>
          </div> */}
          <FormDatePicker
            rules={{
              required: { value: true, message: "This is a required field" },
            }}
            name="activatedAt"
            control={control}
            label="Active On*"
            disablePast
          />
          <FormDatePicker
            rules={{
              required: { value: true, message: "This is a required field" },
            }}
            name="deActivateAt"
            control={control}
            label="Inactive On*"
            disablePast
          />
          <Button
            className="ml-8 mt-10 mb-5 w-96"
            color="secondary"
            variant="contained"
            onClick={onSubmit}
          >
            {userData?.isPgpPublicKey ? "Update" : "Save"}
          </Button>
        </form>
      </Paper>
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
    </>
  );
}

export default PublicKey;
