import Backdrop from "@mui/material/Backdrop";
import * as React from "react";

export default function Loader({ open }) {
  return (
    <div>
      <Backdrop
        sx={ { color: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 } }
        open={ open }
      >
        <span className="loader"></span>
      </Backdrop>
    </div>
  );
}
