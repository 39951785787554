import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={ 6 } ref={ ref } variant="filled" { ...props } />;
});

export default function Notification({ type, message, open, handleClose }) {
  return (
    <Snackbar
      anchorOrigin={ { vertical: "top", horizontal: "center" } }
      open={ open }
      autoHideDuration={ 6000 }
      onClose={ handleClose }
    >
      <Alert className="text-slate-50"  onClose={ handleClose } severity={ type } sx={ { width: "100%" } }>
        { message } 
      </Alert>
    </Snackbar>
  );
}
