import * as React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import Paper from "@mui/material/Paper";

export default function Custom500() {
  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0px 0px 5px #ccc",
          margin: "20px auto",
          textAlign: "center",
        }}
      >
        <ErrorIcon color="error" fontSize="large"/>
        <h1>Oops, we are taking a nap. Please visit after sometime!!</h1>
      </Paper>
    </div>
  );
}
