import { Auth } from "aws-amplify";

export const getAccessToken = async () => {
  const accessToken = await Auth.currentSession().then((res) => {
    let token = res.getAccessToken();
    let jwt = token.getJwtToken();
    return jwt;
  });
  return accessToken;
};

export const getUserId = () => {
  const userId = localStorage.getItem("userId");
  return userId;
};

export const getIdToken = async () => {
  const idToken = await Auth.currentSession().then((res) => {
    let token = res.getIdToken();
    let jwt = token.getJwtToken();
    return jwt;
  });
  return idToken;
};

export const getImpersonatedUser = async () => {
  return localStorage.getItem("impersonated");
};

export const getRoles = (user) => {
  const delegatedUser = localStorage.getItem("impersonated");
  if (delegatedUser) {
    const decodedUser = JSON.parse(atob(delegatedUser));
    console.log(decodedUser);
    return decodedUser.roleId;
  }

  const roles = JSON.parse(user.signInUserSession.idToken.payload.roles);
  const roleIds = roles.map((role) =>  role.id )
  return roleIds;
  // const adminRole = roles.find((role) => {
  //   return role.id === "SEC-IDM-CODES-ADMIN";
  // });
  // if (adminRole) {
  //   return adminRole.id;
  // } else {
  //   return roles[0].id;
  // }
};
