"use client";

import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import useSWR, { mutate } from "swr";
import DeleteDialog from "./DeleteDialog";
import OrganizationForm from "./OrganizationForm";
import { DeleteIconButton } from "../components/DeleteButton";
import { EditIconButton } from "../components/EditButton";
import Loader from "../components/Loader";
import Custom500 from "../error/500";

import fetcher from "../utils/fetcher";

export default function OrganizationTable({ ownerGroup }) {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const {
    data: orgs,
    error,
    isLoading,
  } = useSWR(`${process.env.REACT_APP_DIST_URL}/orgs`, fetcher);
  let filteredOrgData = [];
  if (orgs) {
    const temp = orgs.filter((org) => org.ownerGroup === ownerGroup);
    filteredOrgData.push(...temp);
  }
  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 300,
      minWidth: 300,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      minWidth: 300,
    },
    {
      field: "memberCount",
      headerName: "Member Count",
      width: 300,
      minWidth: 300,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const row = params.row;
          mutate(`${process.env.REACT_APP_DIST_URL}/orgs/${row.id}`);
          setSelectedRow(row);
          setEditMode(true);
          setOpen(true);
        };

        return <EditIconButton onClick={onClick} />;
      },
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       const row = params.row;
    //       mutate(`${process.env.REACT_APP_DIST_URL}/orgs/${row.id}`);
    //       setSelectedRow(row);
    //       setOpenDeleteDialog(true);
    //     };

    //     return <DeleteIconButton onClick={onClick} />;
    //   },
    // },
  ];
  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  return (
    <>
      <DataGrid
        rows={filteredOrgData}
        className="min-h-96"
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          }
        }}
        getRowId={(row) => row.id}
        columns={columns}
        checkboxSelection={false}
        onSelectionModelChange={(itm) => console.log(itm)}
        autosizeOptions={{
          columns: ["code", "organizationList", "total", "edit", "delete"],
          includeOutliers: true,
          includeHeaders: false,
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
      <OrganizationForm
        open={open}
        handleClose={handleClose}
        isNewOrganization={!editMode}
        organization={selectedRow}
        ownerGroup={ownerGroup}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        organization={selectedRow}
      />
    </>
  );
}
