"use client";

import React from "react";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import Loader from "../components/Loader";
import fetcher from "../utils/fetcher";
import { axiosGet, axiosPost } from "../utils/axios";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Custom500 from "../error/500";

function RealTimeTestForm() {
  const [result, setResult] = React.useState("");
  const defaultValues = {
    code: "",
  };

  const { setValue, register, control, errors, watch, handleSubmit, getValues } = useForm({
    defaultValues,
  });

  const selectedProgram = watch("program");
  const selectedPromotion = watch("promotion");
  const trademark = watch("trademark");
  const brand = watch("brand");
  const beverage = watch("beverage");
  // Fetch Programs
  const {
    data: programs,
    error: programError,
    isLoading: isProgramLoading,
  } = useSWR(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs?realtimeEnabled=true`, fetcher);

  // // Fetch Promotions
  // const {
  //   data: promotions,
  //   error: promotionError,
  //   isLoading: isPromotionLoading
  // } = useSWR(
  //   programs ? `${process.env.REACT_APP_PROGRAM_MGMT_URL}/promotions` : null,
  //   fetcher
  // );

  // // Trademark
  // const { data: trademarks } = useSWR(
  //   `${process.env.REACT_APP_REFDATA_URL}/pdm/trademarks`,
  //   fetcher
  // );

  // const trademarksOptions = [{ label: "None", value: "none" }];
  // if (trademarks) {
  //   const trademarksKV = trademarks.map((trademark) => ({
  //     label: trademark.name,
  //     value: trademark.id
  //   }));
  //   trademarksOptions.push(...trademarksKV);
  // }

  // // Brands
  // const { data: brands } = useSWR(
  //   trademark
  //     ? `${process.env.REACT_APP_REFDATA_URL}/pdm/trademarks/${trademark}/brands`
  //     : null,
  //   fetcher
  // );
  // const brandOptions = [{ label: "None", value: "none" }];
  // if (brands) {
  //   const brandKV = brands.map((brand) => ({
  //     label: brand.name,
  //     value: brand.id
  //   }));
  //   brandOptions.push(...brandKV);
  // }
  // // Beverages
  // const { data: beverages } = useSWR(
  //   brand
  //     ? `${process.env.REACT_APP_REFDATA_URL}/pdm/trademarks/${trademark}/brands/${brand}/beverages`
  //     : null,
  //   fetcher
  // );
  // const beverageOptions = [{ label: "None", value: "none" }];

  // if (beverages) {
  //   const beverageKV = beverages.map((beverage) => ({
  //     label: beverage.name,
  //     value: beverage.id
  //   }));
  //   beverageOptions.push(...beverageKV);
  // }

  // // Primary Container
  // const { data: primaryContainers } = useSWR(
  //   beverage
  //     ? `${process.env.REACT_APP_REFDATA_URL}/pdm/${beverage}/primary-containers`
  //     : null,
  //   fetcher
  // );
  // const primaryContainersOptions = [{ label: "None", value: "none" }];

  // if (primaryContainers) {
  //   const primaryContainersKV = primaryContainers.map((primaryContainer) => ({
  //     label: primaryContainer.name,
  //     value: primaryContainer.id
  //   }));
  //   primaryContainersOptions.push(...primaryContainersKV);
  // }

  // // Secondary Packages
  // const { data: secondaryPackages } = useSWR(
  //   beverage
  //     ? `${process.env.REACT_APP_REFDATA_URL}/pdm/${beverage}/secondary-packages`
  //     : null,
  //   fetcher
  // );
  // const secondaryPackagesOptions = [{ label: "None", value: "none" }];

  // if (secondaryPackages) {
  //   const secondaryPackagesKV = secondaryPackages.map((secondaryPackage) => ({
  //     label: secondaryPackage.name,
  //     value: secondaryPackage.id
  //   }));
  //   secondaryPackagesOptions.push(...secondaryPackagesKV);
  // }

  // // Closure Color
  // const { data: closureColors } = useSWR(
  //   `${process.env.REACT_APP_REFDATA_URL}/pdm/closures/colors`,
  //   fetcher
  // );
  // const closureColorsOptions = [{ label: "None", value: "none" }];

  // if (closureColors) {
  //   const closureColorsKV = closureColors.map((closureColor) => ({
  //     label: closureColor.name,
  //     value: closureColor.id
  //   }));
  //   closureColorsOptions.push(...closureColorsKV);
  // }

  // // Closure Diameter
  // const { data: closureDiameters } = useSWR(
  //   `${process.env.REACT_APP_REFDATA_URL}/pdm/closures/diameters`,
  //   fetcher
  // );
  // const closureDiametersOptions = [{ label: "None", value: "none" }];

  // if (closureDiameters) {
  //   const closureDiametersKV = closureDiameters.map((closureDiameter) => ({
  //     label: closureDiameter.name,
  //     value: closureDiameter.id
  //   }));
  //   closureDiametersOptions.push(...closureDiametersKV);
  // }

  if (programError) {
    return <Custom500 statusCode={500} />;
  }
  if (isProgramLoading) {
    return <Loader open={isProgramLoading} />;
  }
  const programOptions = programs.map((program) => ({
    label: `${program.programName}, ${program.name}`,
    value: program.id,
  }));
  // const promotionOptions = promotions.map((promotion) => ({
  //   label: promotion.name,
  //   value: promotion.id
  // }));

  programOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

  const onSubmit = handleSubmit((inputs) => {
    axiosGet(`${process.env.REACT_APP_CODE_MGMT_URL}/programs/${inputs.program}/realtime-codes`)
      .then((response) => {
        console.log("Generated Code:", response.data.code);
        if (response.data) {
          setResult(response.data.code);
          setValue("code", response.data.code);
        } else {
          setValue("code", "No codes available");
        }
      })
      .catch((error) => {
        setValue("code", "Error Occured");
        console.log(error);
      });
  });

  return (
    <>
      <Paper className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start" elevation={6}>
        <form>
          <FormSelect
            rules={{
              required: { value: true, message: "This is a required field" },
            }}
            name="program"
            items={programOptions}
            control={control}
            label="Program, Release"
          />
          {/* <FormSelect
            rules={ {
              required: { value: true, message: "This is a required field" }
            } }
            name="promotion"
            items={ promotionOptions }
            control={ control }
            label="Active Promotion"
          />
          <FormSelect
            rules={ {
              required: { value: true, message: "This is a required field" }
            } }
            name="trademark"
            items={ trademarksOptions }
            control={ control }
            label="Trademark"
          />
          <FormSelect
            rules={ {
              required: { value: true, message: "This is a required field" }
            } }
            name="brand"
            items={ brandOptions }
            control={ control }
            label="Brand"
          />
          <FormSelect
            rules={ {
              required: { value: true, message: "This is a required field" }
            } }
            name="beverage"
            items={ beverageOptions }
            control={ control }
            label="Beverage Product"
          />
          <FormSelect
            rules={ {
              required: { value: true, message: "This is a required field" }
            } }
            name="primaryContainer"
            items={ primaryContainersOptions }
            control={ control }
            label="Primary Container"
          />
          <FormSelect
            rules={ {
              required: { value: true, message: "This is a required field" }
            } }
            name="secondaryPackage"
            items={ secondaryPackagesOptions }
            control={ control }
            label="Secondary Package"
          />
          <FormSelect
            rules={ {
              required: { value: true, message: "This is a required field" }
            } }
            name="closureType"
            items={ [{ label: "none", value: "none" }] }
            control={ control }
            label="Closure Type"
          />
          <FormSelect
            rules={ {
              required: { value: true, message: "This is a required field" }
            } }
            name="closureColor"
            items={ closureColorsOptions }
            control={ control }
            label="Closure Color"
          />
          <FormSelect
            rules={ {
              required: { value: true, message: "This is a required field" }
            } }
            name="closureDiameter"
            items={ closureDiametersOptions }
            control={ control }
            label="Closure Width"
          /> */}
          <Divider orientation="horizontal" />
          <div className="flex flex-wrap">
            <FormInput name="code" control={control} label="Generated Code" />
            <IconButton
              aria-label="copy"
              onClick={() => {
                navigator.clipboard.writeText(result);
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </div>

          <Button className="m-5 w-96" color="secondary" variant="contained" onClick={onSubmit}>
            Test
          </Button>
        </form>
        {/* <div>
          <h2>Test Result:</h2>
          <p>{JSON.stringify(result)}</p>
        </div> */}
      </Paper>
    </>
  );
}

export default RealTimeTestForm;
