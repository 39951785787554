"use client";

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useSWR, { mutate } from "swr";
import Loader from "../components/Loader";
import { axiosPost } from "../utils/axios";
import fetcher from "../utils/fetcher";
import Custom500 from "../error/500";
import { CODEREGEN_STATE } from "./ReleaseUtils";
import Notification from "../components/Notification";

const defaultValues = {
  receipientOrg: "",
  codesPerFile: "",
  codesAlloted: "",
  batchItems: [],
};

export default function BatchView({
  open,
  handleClose,
  editMode,
  programId,
  release,
  lots,
  batch,
}) {
  const firstNum = lots[0]?.codesAlloted;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [status, setStatus] = useState({});
  const [receipientOrg, setRecepientOrg] = useState("");
  const {
    data: oragnizations,
    error: organizationError,
    isLoading: organizationLoading,
  } = useSWR(`${process.env.REACT_APP_DIST_URL}/orgs`, fetcher);

  const {
    data: batchData,
    error,
    isLoading,
  } = useSWR(
    open
      ? `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/batches/${batch.id}`
      : null,
    fetcher,
  );
  useEffect(() => {
    if (oragnizations) {
      const receipientOrgId = batchData?.batchDetails.receipientOrg;
      const org = oragnizations.find((org) => org.id === receipientOrgId);
      setRecepientOrg(org?.name);
    }
  }, [oragnizations, batchData]);

  const handleRegenerateCode = (batchItem) => {
    axiosPost(
      `${process.env.REACT_APP_CODEGEN_URL}/codegen/batches/${batchItem.batchId}/batch-items/${batchItem.id}/batch-jobs`,
    )
      .then((res) => {
        setStatus({ state: "success", message: "Code Generated Successfully" });
        mutate(`${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases`);
      })
      .catch((err) => {
        console.log(err);
        setStatus({ state: "error", message: "Unable to Generate code" });
      });
  };

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;

  const closeForm = () => {
    mutate(
      `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}/batches`,
    );
    handleClose();
    return;
  };
  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={closeForm}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeForm} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              View Batch
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className="flex gap-x-24 p-2">
            <span className="p-1 w-64">Reciepient Org: </span>
            <span className="w-96">{receipientOrg}</span>
          </div>
          <div className="flex gap-x-24 p-2">
            <span className="p-1 w-64">Codes Per File: </span>
            <span className="w-96">{batchData?.batchDetails.codesPerFile}</span>
          </div>
          {batchData?.batchItems.map((batchItem, index) => (
            <div key={batchItem.id}>
              <Divider className="p-3 slate-50" orientation="horizontal" />
              <div className="flex gap-x-24 p-2">
                <span className="p-1 w-64">Batch Item ID: </span>
                <span className="w-96">{batchItem.id}</span>
              </div>
              <div className="flex gap-x-24 p-2">
                <span className="p-1 w-64">Lot Id: </span>
                <span className="w-96">{batchItem.lotId}</span>
              </div>
              <div className="flex gap-x-24 p-2">
                <span className="p-1 w-64">Lot Name: </span>
                <span className="w-96">{batchItem.lotName}</span>
              </div>
              <div className="flex gap-x-24 p-2">
                <span className="p-1 w-64">Point Value: </span>
                <span className="w-96">{batchItem.pointValue}</span>
              </div>
              <div className="flex gap-x-24 p-2">
                <span className="p-1 w-64">Total Number of Codes: </span>
                <span className="w-96">{batchItem.noOfCodes}</span>
              </div>
              <Divider className="p-3 slate-50" orientation="horizontal" />
              <div className="flex gap-x-24 p-2">
                <span className="p-1 w-64">Number of Available Codes: </span>
                <span className="w-96">{firstNum - batchItem.noOfCodes}</span>
              </div>
              <Divider className="p-3 slate-50" orientation="horizontal" />
              {CODEREGEN_STATE.includes(release.state) && !release.realtimeCodesEnabled && (
                <Button variant="contained" onClick={() => handleRegenerateCode(batchItem)}>
                  Regenerate
                </Button>
              )}
            </div>
          ))}
        </DialogContent>
      </Dialog>
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
    </>
  );
}
