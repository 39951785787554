export const CODEGEN_STATE = [
  "NEW",
  "NOLOTS",
  "NOBATCHES",
  "READY_CODEGEN",
  "CODEGEN_QUEUED",
  "CODEGEN_INPROGRESS",
  "CODEGEN_COMPLETE",
  "CODEGEN_FAILED",
];

export const CODEGEN_STATE_EDIT = ["NEW", "NOLOTS", "NOBATCHES", "READY_CODEGEN"];

export const CODEGEN_STATE_STARTED = [
  "CODEGEN_QUEUED",
  "CODEGEN_INPROGRESS",
  "CODEGEN_COMPLETE",
  "CODEGEN_FAILED",
];

export const CODEREGEN_STATE = [
  "CODEGEN_QUEUED",
  "CODEGEN_INPROGRESS",
  "CODEGEN_COMPLETE",
  "CODEGEN_FAILED",
];

export const CODEGEN_STATE_INPROGRESS = [
  "CODEGEN_QUEUED",
  "CODEGEN_INPROGRESS",
]
