"use client";

import Paper from "@mui/material/Paper";
import React from "react";
import { useSearchParams } from "react-router-dom";
import ReportResult from "../../ReportResult";

const columns = [
  {
    field: "programid",
    headerName: "Program Id",
    width: 150,
    minWidth: 150
  },
  {
    field: "programName",
    headerName: "Program Name",
    width: 150,
    minWidth: 150
  },
  {
    field: "success_redemptions",
    headerName: "Number of Successful Redemptions",
    width: 250,
    minWidth: 250
  },
  {
    field: "failure_redemptions",
    headerName: "Number of failures",
    width: 250,
    minWidth: 250
  }
];

function ProgramRedemptionReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const url = `${process.env.REACT_APP_REPORT_URL}/report/redemption/program?startDate=${startDate}&endDate=${endDate}`;
  return (
    <>
      <Paper
        className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start"
        elevation={ 6 }
      >
        <p className="text-xl ml-5 mb-5">Code Redeemed by Program </p>
      </Paper>
      <ReportResult columns={ columns } url={ url } programDetailsEnabled={ false } />
    </>
  );
}

export default ProgramRedemptionReport;
