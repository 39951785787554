import * as React from "react";
import { Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

// {({ field: { onChange, value }, fieldState: { error }, formState })
export default function FormSelect({ name, control, label, items, rules, disabled }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <div className="flex gap-x-24 p-2">
          <span className="p-1 w-64">{label}</span>
          <Select
            {...field}
            className="w-96 h-10"
            defaultValue=""
            error={!!error}
            disabled={disabled}
          >
            {items.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className="text-red-500">{error?.message}</FormHelperText>
        </div>
      )}
    />
  );
}
