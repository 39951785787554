import { getIdToken, getImpersonatedUser } from "./session";

const fetcher = async (url) => {
  const idToken = await getIdToken();
  const delegatedUser = await getImpersonatedUser();
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${idToken}`,
  };
  if (delegatedUser) {
    headers["X-Api-Key"] = delegatedUser;
  }
  return fetch(url, {
    headers,
  })
    .then((res) => {
      if (!res.ok) {
        const error = new Error("An error occurred while fetching the data.");
        // Attach extra info to the error object.
        error.type = res.type;
        error.status = res.status;
        throw error;
      }
      return res.json();
    })
    .catch((error) => {
      error.info = error.json();
      throw error;
    });
};

export default fetcher;
