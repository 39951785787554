import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import KeyIcon from "@mui/icons-material/Key";
import IconButton from "@mui/material/IconButton";
import { EditButton } from "../components/EditButton";
import ProgramForm from "../programs/ProgramForm";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
export default function ProgramDetails({ program }) {
  const [showSecretKey, setShowSecretKey] = React.useState(false);
  const [programFormOpen, setProgramFormOpen] = React.useState(false);
  const onClick = () => {
    setProgramFormOpen(true);
  }
  const handleClose = () => {
    setProgramFormOpen(false);
  };
  const hasallocatedCodes = typeof(program.programSummary?.allocatedCodes) === "undefined" ? 0 : program.programSummary?.allocatedCodes;
  const hasallotedCodes = typeof(program.programSummary?.allotedCodes) === "undefined" ? 0 : program.programSummary?.allotedCodes;
  const usedCodes =  hasallocatedCodes + hasallotedCodes;
  const availableCodes = program.codeDescriptor?.maxCount - usedCodes;
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardActions>
        <Typography variant="h5" className="mb-5 mr-5 mt-5 ml-2" component="div">
          {program.programSummary.name}
        </Typography>
        <EditButton onClick={onClick} />
      </CardActions>
      <div className="grid grid-cols-3">
        <CardContent>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Id: {program.programSummary.id}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Start Date: {dayjs(program.programSummary.startDate).tz(program.programSummary.localStartTimezone).format("MM/DD/YYYY")}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            End Date: {dayjs(program.programSummary.endDate).tz(program.programSummary.localEndTimezone).format("MM/DD/YYYY")}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Secret:
            <IconButton onClick={() => setShowSecretKey(!showSecretKey)}>
              <KeyIcon />
              {!showSecretKey && <Typography color="text.secondary">***********************</Typography>}
            </IconButton>
            {showSecretKey && ( program.programSummary.secretKey || "Not available")}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Country: {program.programSummary.country}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Valid / Invalid Attempts: {program.codeRedemptionRules?.validAttempts} /{" "}
            {program.codeRedemptionRules.inValidAttempts}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Point value: {program.codeDescriptor.pointValue}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Code length (Excludes Prefix): {program.codeDescriptor.length}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Codes used: {usedCodes}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Available codes: {availableCodes || 0}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Max codes: {program.codeDescriptor?.maxCount}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Profanity check: {program.codeDescriptor?.profanityCheckEnabled ? "Yes" : "No"}
          </Typography>
        </CardContent>
      </div>
      <ProgramForm
        open={programFormOpen}
        handleClose={handleClose}
        isNewProgram={false}
        program={program.programSummary}
      />
    </Card>
  );
}
