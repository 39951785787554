"use client";

import React from "react";
import DownloadCodesTable from "./DownloadCodesTable";

function DownloadCodes() {
  return (
    <>
      <DownloadCodesTable />
    </>
  );
}

export default DownloadCodes;
