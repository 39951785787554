import React from "react";
import { getRoles } from "../utils/session";
import { NavLink } from "react-router-dom";

const Navigation = ({ user }) => {
  const roles = getRoles(user);
  return (
    <nav className="flex flex-wrap bg-nav-bar h-10 align-middle">
      <ul id="navigation" className="hidden md:flex gap-x-6 pl-20 text-slate-50 h-10">
        {roles.includes("SEC-IDM-CODES-ADMIN") && (
          <>
            <li >
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/programs">
                <p className="mt-2">Programs</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/organizations">
                <p className="mt-2">Organizations</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/promotions">
                <p className="mt-2">Promotions</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/closure">
                <p className="mt-2">Closure Matrix</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/queuedrequests">
                <p className="mt-2">Queued Requests</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/reports">
                <p className="mt-2">Reports</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/realtimetest">
                <p className="mt-2">Real Time Test</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/downloadcodes">
                <p className="mt-2">Download Codes</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/publickey">
                <p className="mt-2">Public Key</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/validate">
                <p className="mt-2">Validate</p>
              </NavLink>
            </li>
          </>
        )}

        {!roles.includes("SEC-IDM-CODES-ADMIN") && roles.includes("SEC-IDM-CODES-MKTUSERS") && (
          <>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/programs">
                <p className="mt-2">Programs</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/organizations">
                <p className="mt-2">Organizations</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/promotions">
                <p className="mt-2">Promotions</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/closure">
                <p className="mt-2">Closure Matrix</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/queuedrequests">
                <p className="mt-2">Queued Requests</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/reports">
                <p className="mt-2">Reports</p>
              </NavLink>
            </li>
          </>
        )}
        {!roles.includes("SEC-IDM-CODES-ADMIN") && roles.includes("SEC-IDM-CODES-RECIPIENTS") && (
          <>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/downloadcodes">
                <p className="mt-2">Download Codes</p>
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/publickey">
                <p className="mt-2">Public Key</p>
              </NavLink>
            </li>
          </>
        )}
        {!roles.includes("SEC-IDM-CODES-ADMIN") && roles.includes("SEC-IDM-CODES-SUPPORT") && (
          <>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/validate">
                <p className="mt-2">Validate</p>
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
