import Add from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import * as React from "react";

export const CreateButton = ({ onClick, label, size }) => (
  <Button
    className="m-1 w-72"
    size={ size ? size : "medium" }
    onClick={ onClick }
    variant="contained"
    color="primary"
    startIcon={ <Add /> }
  >
    { label }
  </Button>
);
