import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);

export default function ProgramDetails({ program, report }) {
  return (
    <Card sx={ { minWidth: 275 } }>
      <div className="grid grid-cols-2">
        <CardContent>
          <Typography sx={ { mb: 1.5 } } color="text.secondary">
            Program Id: { program.programSummary?.id }
          </Typography>
          <Typography sx={ { mb: 1.5 } } color="text.secondary">
            Program Name: { program.programSummary?.name }
          </Typography>
          <Typography sx={ { mb: 1.5 } } color="text.secondary">
            Country Code: { program.programSummary?.country }
          </Typography>
          <Typography sx={ { mb: 1.5 } } color="text.secondary">
            User: { program.programSummary?.createdBy }
          </Typography>
          <Typography sx={ { mb: 1.5 } } color="text.secondary">
            Program Total Codes: { program.codeDescriptor?.maxCount }
          </Typography>
        </CardContent>
        <CardContent>
          <Typography sx={ { mb: 1.5 } } color="text.secondary">
            Program Start Date: { dayjs.utc(program.programSummary?.startDate).format("MM/DD/YYYY") }
          </Typography>
          <Typography sx={ { mb: 1.5 } } color="text.secondary">
            Program End Date: { dayjs.utc(program.programSummary?.endDate).format("MM/DD/YYYY") }
          </Typography>
          <Typography sx={ { mb: 1.5 } } color="text.secondary">
            Report Start Date: { dayjs.utc(report.startDate).format("MM/DD/YYYY") }
          </Typography>
          <Typography sx={ { mb: 1.5 } } color="text.secondary">
            Report End Date: { dayjs.utc(report.endDate).format("MM/DD/YYYY") }
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}
