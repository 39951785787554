import React from "react";

function Logo() {
  return (
    <span className="inline-flex gap-x-3  items-center pl-20 p-2 mr-4 ">
      <img src="/Coca-Cola.png" className="object-scale-down h-12 w-24 " alt="Coca Coal MixCodes" />
      <span className="font-extrabold	text-xl	text-slate-950">Mix Codes</span>
    </span>
  );
}

export default Logo;
