"use client";

import React from "react";
import ReportResult from "../../ReportResult";
import Paper from "@mui/material/Paper";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);

const columns = [
  {
    field: "organizationName",
    headerName: "Organization Id",
    width: 250,
    minWidth: 250,
  },
  {
    field: "releaseName",
    headerName: "Release Id",
    width: 250,
    minWidth: 250,
  },
  {
    field: "noofcodes",
    headerName: "No. of Codes",
    width: 250,
    minWidth: 250,
  },
  {
    field: "programid",
    headerName: "Program Name",
    width: 250,
    minWidth: 250,
  },
  {
    field: "createdAt",
    headerName: "Created On",
    width: 100,
    minWidth: 100,
    valueFormatter: (params) => dayjs.utc(params?.value).format("MM/DD/YYYY"),
  },
];

function CodeVolumeReport() {
  const [searchParams] = useSearchParams();
  const country = searchParams.get("country");
  const programId = searchParams.get('programId');
  const orgId = searchParams.get('orgId');
  const ownerGroup = searchParams.get('ownerGroup');

  const url = `${process.env.REACT_APP_REPORT_URL}/report/codegen/codevolume?country=${country}&programId=${programId}&orgId=${orgId}&ownerGroup=${ownerGroup}`;
  return (
    <>
      <Paper
        className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start"
        elevation={6}
      >
        <p className="text-xl ml-5 mb-5">Code Volume Report</p>
      </Paper>
      <ReportResult programId={programId} columns={columns} url={url} programDetailsEnabled={false} />
    </>
  );
}

export default CodeVolumeReport;
