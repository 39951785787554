"use client";

import Paper from "@mui/material/Paper";
import React from "react";
import { useSearchParams } from "react-router-dom";
import ReportResult from "../../ReportResult";

const columns = [
  {
    field: "consumerid",
    headerName: "Consumer Id",
    width: 250,
    minWidth: 250
  },
  {
    field: "totalRedemptions",
    headerName: "Total Redemptions",
    width: 250,
    minWidth: 250
  }
];
function TopConsumerActivityReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const programId = searchParams.get("programId");
  const reportDuration = searchParams.get("reportDuration");
  const reportDate = searchParams.get("reportDate");
  const ownerGroup = searchParams.get("ownerGroup");

  const url = `${process.env.REACT_APP_REPORT_URL}/report/redemption/topconsumer?programId=${programId}&reportDuration=${reportDuration}&reportDate=${reportDate}&ownerGroup=${ownerGroup}`;
  return (
    <>
      <Paper
        className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start"
        elevation={ 6 }
      >
        <p className="text-xl ml-5 mb-5">Top Code Redemptions By Consumer</p>
      </Paper>
      <ReportResult programId={programId} columns={ columns } url={ url } programDetailsEnabled={ false } />
    </>
  );
}

export default TopConsumerActivityReport;
