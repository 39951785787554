import * as React from "react";
import { Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const FormDatePicker = ({ name, control, label, rules }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        rules={ rules }
        render={({ field,fieldState }) => (
          <div className="flex gap-x-24 p-2">
            <span className="p-1 w-64">{label}</span>
            <DatePicker
              {...field}
              slotProps={{
                textField: {
                  size: "small",
                  error: !!fieldState.error,
                },
              }}
              format="MM/DD/YYYY"
              className="w-96 h-10"
              autoOk
            />
            <FormHelperText className="text-red-500">{fieldState.error?.message}</FormHelperText>
          </div>
        )}
      />
    </LocalizationProvider>
  );
};

export default FormDatePicker;
