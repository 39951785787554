"use client";

import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../components/Loader";
import useSWR from "swr";
import Custom500 from "../error/500";
import fetcher from "../utils/fetcher";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);

export default function QueuedRequestsTable() {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_CODEGEN_URL}/codegen/batch-jobs `,
    fetcher,
  );
  const columns = [
    { field: "id", headerName: "ID", width: 300, minWidth: 300 },
    {
      field: "createdAt",
      headerName: "Queued On",
      width: 150,
      minWidth: 150,
      valueFormatter: (params) => dayjs.utc(params?.value).format("MM/DD/YYYY HH:mm"),
    },
    {
      field: "displayName",
      headerName: "Program/Release/Lot",
      width: 400,
      minWidth: 250,
    },
    {
      field: "createdByName",
      headerName: "Requestor",
      width: 200,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      minWidth: 100,
    },
    {
      field: "noOfCodesGenerated",
      headerName: "Codes",
      width: 100,
      minWidth: 100,
    },
    {
      field: "updatedAt",
      headerName: "Completed at",
      width: 150,
      minWidth: 150,
      valueFormatter: (params) => dayjs.utc(params?.value).format("MM/DD/YYYY HH:mm"),
    },
    {
      field: "message",
      headerName: "Job Status Message",
      width: 400,
      minWidth: 400,
    },
  ];
  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  return (
    <DataGrid
      rows={data}
      getRowId={(row) => row.id}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: 'createdAt', sort: 'desc' }],
        }
      }}
      checkboxSelection={false}
      onSelectionModelChange={(itm) => console.log(itm)}
      autosizeOptions={{
        columns: ["code", "name", "displayName"],
        includeOutliers: true,
        includeHeaders: false,
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
    />
  );
}
