'use client';

import React from 'react';
import ReportResult from '../../ReportResult';
import Paper from '@mui/material/Paper';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

const columns = [
  {
    field: 'releaseName',
    headerName: 'Release Name',
    width: 350,
    minWidth: 350,
  },
  {
    field: 'organizationName',
    headerName: 'Organization',
    width: 250,
    minWidth: 250,
  },
  {
    field: 'createdat',
    headerName: 'Creation Date',
    width: 100,
    minWidth: 100,
    valueFormatter: (params) => dayjs(params?.value).format('MM/DD/YYYY'),
  },
  {
    field: 'beginindex',
    headerName: 'Start Index',
    width: 250,
    minWidth: 250,
  },
  {
    field: 'endindex',
    headerName: 'End Index',
    width: 250,
    minWidth: 250,
  },
  {
    field: 'noofcodes',
    headerName: 'No. of Codes',
    width: 250,
    minWidth: 250,
  },
  {
    field: 'filedownloaded',
    headerName: 'Downloaded',
    width: 250,
    minWidth: 250,
    valueFormatter: (params) => (params?.value === "1" ? "True" : "No"),
  },
];

function BatchReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const country = searchParams.get('country');
  const programId = searchParams.get('programId');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const ownerGroup = searchParams.get('ownerGroup');

  const url = `${process.env.REACT_APP_REPORT_URL}/report/codegen/batch?country=${country}&programId=${programId}&startDate=${startDate}&endDate=${endDate}&ownerGroup=${ownerGroup}`;
  return (
    <>
      <Paper className='mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start' elevation={6}>
        <p className='text-xl ml-5 mb-5'>CODE GEN Batch Report</p>
      </Paper>
      <ReportResult programId={programId} columns={columns} url={url} programDetailsEnabled={true} />
    </>
  );
}

export default BatchReport;
