import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";

export default function OwnerGroup({ handleSelection, selectedValue, options }) {
  return(
    <>
      { options && options.length>0 && (
        <Select
          defaultValue={ options[0].id }
          className="w-96 h-10 m-5"
          onChange={ handleSelection }
          displayEmpty={ true }
          inputProps={ { "aria-label": "Without label" } }
        >
          { options.map((item) => (
            <MenuItem key={ item.id } value={ item.id }>{ item.id }</MenuItem>
          )) }
        </Select>
      ) }
    </>
  );
}
