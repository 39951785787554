import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Checkbox, TextField, FormHelperText, Box, FormControlLabel } from "@mui/material";
import { grey } from "@mui/material/colors";

const CheckboxWithTextField = ({ control, name, disabled, getValues }) => {
  const { fields, replace } = useFieldArray({
    control,
    name,
  });

  const handleSelectAll = (selectAll) => {
    const updatedValues = fields.map((field) => ({
      ...field,
      checked: selectAll,
    }));
    replace(updatedValues);
  };

  const isAllSelected = fields.every((field) => field.checked);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControlLabel
        control={
          <Checkbox
            onClick={() => handleSelectAll(!isAllSelected)}
            className="w-24"
            checked={isAllSelected}
            disabled={disabled}

          />
        }
        label={isAllSelected ? "Unselect All" : "Select All"}
      />
      {fields.map((item, index) => (
        <Box key={item.id} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Controller
            name={`${name}[${index}].checked`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    className="w-24"
                    disabled={disabled}
                    checked={field.value}
                    {...field}
                  />
                }
                label={item.code}
              />
            )}
          />
          <Controller
            name={`${name}[${index}].synonym`}
            control={control}
            rules={{
              validate: (value) => {
                if (value) {
                  const codeLength = getValues("codeDescriptor.length");
                  return value.length <= codeLength || "Should be lesser than code length";
                }
              },
              pattern: {
                value: /^[a-zA-Z0-9]+$/,
                message: "Alphabet or numeric values only",
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <TextField
                  disabled={disabled }
                  size="small"
                  {...field}
                  variant="outlined"
                />
                <FormHelperText className="text-red-500">
                  {fieldState.error?.message}
                </FormHelperText>
              </>
            )}
          />
        </Box>
      ))}
    </Box>
  );
};

export default CheckboxWithTextField;
