import Divider from "@mui/material/Divider";
import * as React from "react";
import CharComboField from "./CharComboField";

export default function CharacterSetForm({ control, watch, program, isNewProgram, setValue, getValues }) {
  return (
    <>
      <div className="grid grid-cols-3 gap-4"></div>
      <div className="grid grid-cols-12">
        <div className="col-span-3">
          <CharComboField
            control={control}
            name={`codeDescriptor.characterSet.numbers`}
            disabled={!isNewProgram && program.availableReleaseCount !== 0}
            getValues={getValues}
          />
        </div>
        <Divider orientation="vertical" />
        <div className="col-span-3">
          <CharComboField
            control={control}
            name={`codeDescriptor.characterSet.lowerCaseAlphabets`}
            disabled={!isNewProgram && program.availableReleaseCount !== 0}
            getValues={getValues}
          />
        </div>
        <Divider orientation="vertical" />
        <div className="col-span-3">
          <CharComboField
            control={control}
            name={`codeDescriptor.characterSet.upperCaseAlphabets`}
            disabled={!isNewProgram && program.availableReleaseCount !== 0}
            getValues={getValues}
          />
        </div>
      </div>
    </>
  );
}
