import React from "react";
import Banner from "./banner";
import Navigation from "./Navigation";

const Header = ({ user, signOut }) => {
  return (
    <div className="sticky top-0 z-50">
      <Banner user={ user } signOut={ signOut } />
      <Navigation user={ user }/>
    </div>
  );
};

export default Header;
