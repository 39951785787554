"use client";

import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";
import FormDateTimePicker from "../components/FormDateTimePicker";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { axiosPost, axiosPut } from "../utils/axios";
import fetcher from "../utils/fetcher";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import FormCheckBox from "../components/FormChekBox";
import Custom500 from "../error/500";
import { useNavigate } from "react-router-dom";
import timezones from "../utils/timezones";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const defaultValues = {
  summary: {
    name: "",
    code: "",
    startDate: null,
    endDate: null,
    localActivateTimezone: "UTC",
    localInactivateTimezone: "UTC"
  },
  details: {
    pointType: "",
    pointValue: "",
    promotion: "none",
    testCampaign: false,
    redemptionRules: {
      maxNoOfRedemptions: "",
      campaignRedemptionLimit: {
        maxRedemptions: "",
        maxRedemptionsPeriod: "",
        maxRedemptionsPeriodValue: "",
      },
      consumerRedemptionLimit: {
        maxRedemptions: "",
        maxRedemptionsPeriod: "",
        maxRedemptionsPeriodValue: "",
      },
    },
  },
};

const pointTypeOptions = [
  { label: "Regular", value: "REGULAR" },
  { label: "Bonus", value: "BONUS" },
  { label: "Special", value: "SPECIAL" },
];

const periodOptions = [
  { label: "Minutes", value: "MINUTE" },
  { label: "Hours", value: "HOUR" },
  { label: "Days", value: "CALENDAR_DAY" },
  { label: "Weeks", value: "CALENDAR_WEEK" },
  { label: "Months", value: "MONTH" },
  { label: "Years", value: "YEAR" },
  { label: "Lifetime", value: "LIFETIME" },
];
export default function CampaignForm({ open, programId, handleClose, editMode, campaign }) {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [status, setStatus] = useState({});
  const navigatge = useNavigate();

  const {
    data: campaignData,
    error,
    isLoading,
  } = useSWR(
    editMode ? `${process.env.REACT_APP_CAMPAIGN_URL}/campaigns/${campaign.id}` : null,
    fetcher,
  );

  useEffect(() => {
    if (editMode && campaignData) {
      reset({
        summary: {
          name: campaignData.summary.name,
          code: campaignData.summary.code,
          startDate: dayjs(campaignData.summary.startDate),
          endDate: dayjs(campaignData.summary.endDate),
          localActivateTimezone: campaignData.summary.localActivateTimezone || "UTC",
          localInactivateTimezone: campaignData.summary.localInactivateTimezone  || "UTC"
        },
        details: {
          pointType: campaignData.details.pointType,
          pointValue: campaignData.details.pointValue,
          promotion: campaignData.details.promotion || "none",
          testCampaign: campaignData.details.testCampaign,
          redemptionRules: {
            maxNoOfRedemptions: campaignData.details.redemptionRules.maxNoOfRedemptions,
            campaignRedemptionLimit: {
              maxRedemptions: campaignData.details.redemptionRules.campaignRedemptionLimit.maxRedemptions,
              maxRedemptionsPeriod: campaignData.details.redemptionRules.campaignRedemptionLimit.maxRedemptionsPeriod,
              maxRedemptionsPeriodValue: campaignData.details.redemptionRules.campaignRedemptionLimit.maxRedemptionsPeriodValue,
            },
            consumerRedemptionLimit: {
              maxRedemptions: campaignData.details.redemptionRules.consumerRedemptionLimit.maxRedemptions,
              maxRedemptionsPeriod: campaignData.details.redemptionRules.consumerRedemptionLimit.maxRedemptionsPeriod,
              maxRedemptionsPeriodValue: campaignData.details.redemptionRules.consumerRedemptionLimit.maxRedemptionsPeriodValue,
            },
          },
        },
      });
    }
  }, [open, campaignData]);

  //Promtions
  const { data: promotions } = useSWR(
    `${process.env.REACT_APP_PROGRAM_MGMT_URL}/promotions`,
    fetcher,
  );

  const promotionsOptions = [{ label: "None", value: "none" }];
  if (promotions) {
    const promotionsKV = promotions.map((promotion) => ({
      label: promotion.name,
      value: promotion.id,
    }));
    promotionsOptions.push(...promotionsKV);
  }

  // Save new campaign
  const onSubmit = handleSubmit((inputs) => {
    inputs.details.pointValue = Number(inputs.details.pointValue);
    inputs.details.testCampaign = Boolean(inputs.details.testCampaign);
    console.log(inputs);
    editMode ? updateCampaign(inputs) : createCampaign(inputs);
  });

  function createCampaign(payload) {
    setIsSubmitting(true);
    axiosPost(`${process.env.REACT_APP_CAMPAIGN_URL}/programs/${programId}/campaigns`, payload)
      .then(({ data }) => {
        console.log(data);
        setStatus({
          state: "success",
          message: "Campaign created successfully",
        });
        closeForm();
      })
      .catch((error) => {
        console.log(error);
        setStatus({ state: "error", message: "Unable to create campaign" });
        const message = error.response.data.message.split(".");
        setStatus({ state: "error", message: message[4] || "Unable to create campaign" });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  function updateCampaign(payload) {
    setIsSubmitting(true);
    console.log(JSON.stringify(payload),"!!!!!!!!!!!!!!");
    axiosPut(`${process.env.REACT_APP_CAMPAIGN_URL}/campaigns/${campaign.id}`, payload)
      .then(({ data }) => {
        console.log(data);
        setStatus({
          state: "success",
          message: "Campaign updated successfully",
        });
        closeForm();
      })
      .catch((error) => {
        console.log(error);
        setStatus({ state: "error", message: "Unable to update campaign" });
        const message = error.response.data.message.split(".");
        setStatus({ state: "error", message: message[4] || "Unable to update campaign" });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  
  const closeForm = () => {
    reset(defaultValues);
    handleClose();
    navigatge(0);
  };

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeForm} aria-label="close">
              <CloseIcon />
            </IconButton>
            {editMode ? (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Campaign #: {campaign.name}
              </Typography>
            ) : (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Create New Campaign
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form>
            <FormInput
              name="summary.name"
              rules={{
                required: { value: true, message: "Please specify a name for the Campaign" },
              }}
              control={control}
              label="Campaign Name"
            />
            <FormInput
              name="summary.code"
              rules={{
                required: { value: true, message: "Viral code is required." },
              }}
              control={control}
              label="Viral Code"
            />
            <FormCheckBox
              name="details.testCampaign"
              control={control}
              label="Mark as TEST Campaign"
            />
            <FormInput
              name="details.pointValue"
              control={control}
              label="Point value"
              type="number"
            />
            <FormSelect
              name="details.pointType"
              items={pointTypeOptions}
              control={control}
              label="Point Type"
            />
            <FormSelect
              name="summary.localActivateTimezone"
              items={timezones}
              control={control}
              label="Activate Timezone"
              disabled={editMode}
            />
            <FormDateTimePicker
              rules={{
                required: { value: true, message: "Start Date is required." },
              }}
              name="summary.startDate"
              control={control}
              label="Activate Date"
              disablePast
              timezone={watch("summary.localActivateTimezone")}
              disabled={editMode}
            />
            <FormSelect
              name="summary.localInactivateTimezone"
              items={timezones}
              control={control}
              label="Deactivate Timezone"
            />
            <FormDateTimePicker
              rules={{
                required: { value: true, message: "End Date is required." },
              }}
              name="summary.endDate"
              control={control}
              label="Deactivate Date"
              disablePast
              timezone={watch("summary.localInactivateTimezone")}
            />
            <FormSelect
              name="details.promotion"
              items={promotionsOptions}
              control={control}
              label="Promotion"
            />
            <FormInput
              name="details.redemptionRules.maxNoOfRedemptions"
              rules={{
                required: {
                  value: true,
                  message: "Number should be a number greater than 0 !",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Number should be a number greater than 0 !",
                },
              }}
              control={control}
              label="Max No. of Redemptions"
            />
            <div className="grid grid-cols-3 gap-4">
              <CustomInput
                name="details.redemptionRules.campaignRedemptionLimit.maxRedemptions"
                control={control}
                label="Campaign Limits"
              />
              <CustomInput
                name="details.redemptionRules.campaignRedemptionLimit.maxRedemptionsPeriodValue"
                control={control}
                label="per"
              />
              <CustomSelect
                name="details.redemptionRules.campaignRedemptionLimit.maxRedemptionsPeriod"
                items={periodOptions}
                control={control}
                label="period"
              />
              <CustomInput
                name="details.redemptionRules.consumerRedemptionLimit.maxRedemptions"
                control={control}
                label="Consumer Limits"
              />
              <CustomInput
                name="details.redemptionRules.consumerRedemptionLimit.maxRedemptionsPeriodValue"
                control={control}
                label="per"
              />
              <CustomSelect
                name="details.redemptionRules.consumerRedemptionLimit.maxRedemptionsPeriod"
                items={periodOptions}
                control={control}
                label="period"
              />
            </div>
          </form>
          {isSubmitting && <Loader open={isSubmitting} />}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeForm}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} variant="contained" onClick={onSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
    </>
  );
}
