"use client";
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";
import FormCheckBox from "../components/FormChekBox";
import FormDateTimePicker from "../components/FormDateTimePicker";
import FormInput from "../components/FormInput";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { axiosPost, axiosPut } from "../utils/axios";
import fetcher from "../utils/fetcher";
import Custom500 from "../error/500";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import timezones from "../utils/timezones";
import FormSelect from "../components/FormSelect";
import { useNavigate } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(isBetween);

const defaultValues = {
  name: "",
  startDate: null,
  endDate: null,
  realtimeCodesEnabled: false,
  testRelease: false,
  localActivateTimezone: "UTC",
  localInactivateTimezone: "UTC",
  state: "",
};

const periodOptions = [
  { label: "Days", value: "CALENDAR_DAY" },
  { label: "Weeks", value: "CALENDAR_WEEK" },
  { label: "Months", value: "MONTH" },
  { label: "Years", value: "YEAR" },
  { label: "Hours", value: "HOUR" },
  { label: "Minutes", value: "MINUTE" },
];
export default function ReleaseForm({ open, handleClose, editMode, release, programId, program }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm({
    defaultValues,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigatge = useNavigate();
  const [status, setStatus] = useState({});
  const realtimeCodesEnabled = watch("realtimeCodesEnabled");
  const {
    data: releaseData,
    error,
    isLoading,
  } = useSWR(
    editMode
      ? `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}`
      : null,
    fetcher,
  );

  const programStartDate = dayjs(program?.programSummary?.startDate).subtract(1, "day");
  const programEndDate = dayjs(program?.programSummary?.endDate).add(1, "day");
  // Save new release
  const onSubmit = handleSubmit((inputs) => {
    if (inputs.realTimeCodeRedemptionRules && inputs.realTimeCodeRedemptionRules.validAttempts) {
      inputs.realTimeCodeRedemptionRules.validAttempts = Number(
        inputs.realTimeCodeRedemptionRules.validAttempts,
      );
    }
    if (
      inputs.realTimeCodeRedemptionRules &&
      inputs.realTimeCodeRedemptionRules.validAttemptsPeriodValue
    ) {
      inputs.realTimeCodeRedemptionRules.validAttemptsPeriodValue = Number(
        inputs.realTimeCodeRedemptionRules.validAttemptsPeriodValue,
      );
    }
    editMode ? updateRelease(inputs) : createRelease(inputs);
  });

  useEffect(() => {
    if (editMode && releaseData) {
      if (releaseData.realTimeCodeRedemptionRules) {
        reset({
          name: releaseData.name,
          startDate: dayjs(releaseData.startDate),
          endDate: dayjs(releaseData.endDate),
          realtimeCodesEnabled: releaseData.realtimeCodesEnabled,
          testRelease: releaseData.testRelease,
          localActivateTimezone: releaseData.localActivateTimezone || "UTC",
          localInactivateTimezone: releaseData.localInactivateTimezone || "UTC",
          realTimeCodeRedemptionRules: {
            validAttempts: releaseData.realTimeCodeRedemptionRules.validAttempts,
            validAttemptsPeriod: releaseData.realTimeCodeRedemptionRules.validAttemptsPeriod,
            validAttemptsPeriodValue:
              releaseData.realTimeCodeRedemptionRules.validAttemptsPeriodValue,
            realtimeGenEndDate: releaseData.realTimeCodeRedemptionRules.realtimeGenEndDate
              ? dayjs(releaseData.realTimeCodeRedemptionRules.realtimeGenEndDate)
              : null,
          },
          state: releaseData.state,
        });
      } else {
        reset({
          name: releaseData.name,
          startDate: dayjs(releaseData.startDate),
          endDate: dayjs(releaseData.endDate),
          realtimeCodesEnabled: releaseData.realtimeCodesEnabled,
          testRelease: releaseData.testRelease,
          localActivateTimezone: releaseData.localActivateTimezone || "UTC",
          localInactivateTimezone: releaseData.localInactivateTimezone || "UTC",
          state: releaseData.state,
        });
      }
    }
  }, [open, releaseData]);
  function createRelease(payload) {
    setIsSubmitting(true);
    payload.programId = programId;

    if (!payload.realtimeCodesEnabled) {
      delete payload.realTimeCodeRedemptionRules;
    }
    axiosPost(`${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases`, payload)
      .then(({ data }) => {
        console.log(data);
        setStatus({
          state: "success",
          message: "Release created successfully",
        });
        closeForm();
      })
      .catch((error) => {
        console.log(error);
        const message = error.response.data.message.split(".");
        setStatus({ state: "error", message: message[4] || "Unable to create release" });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  function updateRelease(payload) {
    payload.programId = programId;

    if (!payload.realtimeCodesEnabled) {
      delete payload.realTimeCodeRedemptionRules;
    }
    setIsSubmitting(true);
    axiosPut(
      `${process.env.REACT_APP_RELEASE_MGMT_URL}/programs/${programId}/releases/${release.id}`,
      payload,
    )
      .then(({ data }) => {
        console.log(data);
        setStatus({
          state: "success",
          message: "Release updated successfully",
        });
        closeForm();
      })
      .catch((error) => {
        const message = error.response.data.message.split(".");
        setStatus({ state: "error", message: `Unable to update release - ${message}` });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;

  const closeForm = () => {
    reset(defaultValues);
    handleClose();
    navigatge(0);
    return;
  };

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={closeForm}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeForm} aria-label="close">
              <CloseIcon />
            </IconButton>
            {editMode ? (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit Release
              </Typography>
            ) : (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Create a new Release
              </Typography>
            )}
          </Toolbar>
        </AppBar>

        <DialogContent>
          <form>
            <FormInput
              name="name"
              rules={{
                required: { 
                  value: true, 
                  message: "Release Name is a mandatory field !" 
                },
                pattern: {
                  value: /^[a-zA-Z0-9 ]+$/,
                  message: "AlphaNumaric only No Special chars in Lot Name",
                },
              }}
              control={control}
              label="Release Name"
            />
            <FormSelect
              name="localActivateTimezone"
              items={timezones}
              control={control}
              label="Activate Timezone"
            />
            <FormDateTimePicker
              rules={{
                required: { value: true, message: "Start Date is required." },
                validate: {
                  programDate: (value) => {
                    if (value) {
                      return (
                        value.isBetween(programStartDate, programEndDate) ||
                        "Activation Date must be between the Program Start and End Dates"
                      );
                    }
                  },
                  todaysDate: (value) => {
                    if (value && !editMode) {
                      return (
                        value.isAfter(dayjs().subtract(1, "day")) ||
                        "Activation date should be greater than todays date"
                      );
                    }
                  },
                },
              }}
              name="startDate"
              control={control}
              label="Active Date"
              timezone={watch("localActivateTimezone")}
            />
            <FormSelect
              name="localInactivateTimezone"
              items={timezones}
              control={control}
              label="Deactivate Timezone"
            />
            <FormDateTimePicker
              rules={{
                required: { value: true, message: "End Date is required." },
                validate: {
                  programDate: (value) => {
                    if (value) {
                      return (
                        value.isBetween(programStartDate, programEndDate) ||
                        "Deactivation Date must be between the Program Start and End Dates"
                      );
                    }
                  },
                  activationDate: (value) => {
                    if (value) {
                      const startDate = getValues("startDate");
                      return (
                        value.isAfter(startDate) ||
                        "Deactivation Date cannot be less than or equal to Activation Date"
                      );
                    }
                  },
                  todaysDate: (value) => {
                    if (value && !editMode) {
                      return (
                        value.isAfter(dayjs()) ||
                        "Deactivation Date should be greater than todays date"
                      );
                    }
                  },
                },
              }}
              name="endDate"
              control={control}
              label="Deactivate Date"
              timezone={watch("localInactivateTimezone")}
            />
            <FormCheckBox
              name="testRelease"
              control={control}
              label="Mark as test release"
              error={!!errors.testRelease}
            />
            <FormCheckBox
              name="realtimeCodesEnabled"
              control={control}
              label="Distribute as Real Time Codes"
              error={!!errors.testRelease}
            />
            {realtimeCodesEnabled && (
              <>
                <div className="grid grid-cols-3 gap-4">
                  <CustomInput
                    name="realTimeCodeRedemptionRules.validAttempts"
                    control={control}
                    label="Realtime Code limits"
                    rules={{
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Please enter a number",
                      },
                      valueAsNumber: true,
                    }}
                  />
                  <CustomInput
                    name="realTimeCodeRedemptionRules.validAttemptsPeriodValue"
                    control={control}
                    label="per *"
                    rules={{
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Please enter a number",
                      },
                      valueAsNumber: true,
                    }}
                  />
                  <CustomSelect
                    name="realTimeCodeRedemptionRules.validAttemptsPeriod"
                    items={periodOptions}
                    control={control}
                    label="period"
                  />
                </div>
                <FormDateTimePicker
                  rules={{
                    validate: {
                      programDate: (value) => {
                        if (value) {
                          return (
                            value.isBetween(programStartDate, programEndDate) ||
                            "Deactivation Date must be between the Program Start and End Dates"
                          );
                        }
                      },
                      activationDate: (value) => {
                        if (value) {
                          const startDate = getValues("startDate");
                          return (
                            value.isAfter(startDate) ||
                            "Deactivation Date cannot be less than or equal to Activation Date"
                          );
                        }
                      },
                      todaysDate: (value) => {
                        if (value && !editMode) {
                          return (
                            value.isAfter(dayjs()) ||
                            "Deactivation Date should be greater than todays date"
                          );
                        }
                      },
                    },
                  }}
                  name="realTimeCodeRedemptionRules.realtimeGenEndDate"
                  control={control}
                  label="Release End Date"
                  timezone={watch("timeZone")}
                />
              </>
            )}
          </form>
          {isSubmitting && <Loader open={isSubmitting} />}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeForm}>
            Cancel
          </Button>
          <Button variant="contained" disabled={isSubmitting} onClick={onSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
    </>
  );
}
