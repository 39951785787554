import React, { createContext, useContext, useEffect, useReducer } from "react";
import useSWR from "swr";
import fetcher from "../utils/fetcher";

const GlobalStateContext = createContext();

const initialState = {
  ownerGroups: null,
  roles: null,
};

const reducer = (state, action) => {
  switch (action.type) {
  case "OWNERGROUP":
    return { ...state, ownerGroups: action.payload };
  case "ROLES":
    return { ...state, roles: action.payload };
  default:
    return state;
  }
};

const GlobalStateProvider = ({ children, userAttr }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Set reference data in global state when it changes
  useEffect(() => {
    if (userAttr) {
      // const roleAndOwnerGroup = JSON.parse(userAttr.signInUserSession.idToken.payload.ownerGroup);
      // const roles = roleAndOwnerGroup.forEach(item => {
      //   if (item.entity === 'USERROLE') {
      //     item;
      //   }
      // });
      // const ownerGroups = roleAndOwnerGroup.forEach(item => {
      //   if (item.entity === 'USEROWNERGROUP') {
      //     item;
      //   }
      // });

      let roles = JSON.parse(userAttr.signInUserSession.idToken.payload.roles);
      let ownerGroups = JSON.parse(userAttr.signInUserSession.idToken.payload.ownerGroups);

      dispatch({ type: "OWNERGROUP", payload: ownerGroups });
      dispatch({ type: "ROLES", payload: roles });
    }
  }, [userAttr]);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};

export { GlobalStateProvider, useGlobalState };
