import Divider from "@mui/material/Divider";
import * as React from "react";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import FormDateTimePicker from "../components/FormDateTimePicker";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import countries from "../utils/countries";
import timezones from "../utils/timezones";
import { useGlobalState } from "../utils/GlobalStateContext";

const periodOptions = [
  { label: "Days", value: "CALENDAR_DAY" },
  { label: "Weeks", value: "CALENDAR_WEEK" },
  { label: "Months", value: "MONTH" },
  { label: "Years", value: "YEAR" },
  { label: "Hours", value: "HOUR" },
  { label: "Minutes", value: "MINUTE" },
];
export default function SummaryForm({ control, errors, getValues, watch }) {
  const { state: globalState } = useGlobalState();
  const { ownerGroups } = globalState;
  let ownerGroupOptions = [];
  if (ownerGroups.length > 0) {
    ownerGroups.map((ownerGroup) =>
      ownerGroupOptions.push({ label: ownerGroup.id, value: ownerGroup.id }),
    );
  }
  return (
    <>
      <FormInput
        name="programSummary.name"
        rules={{
          required: {
            value: true,
            message: "Program Name is a mandatory field!"
          },
          pattern: {
            value: /^[a-zA-Z0-9 ]+$/,
            message: "AlphaNumaric only No Special chars in Lot Name",
          },
        }}
        control={control}
        label="Program Name*"
      />
      <FormInput name="programSummary.desc" control={control} label="Program Description" />
      <FormSelect
        name="programSummary.localStartTimezone"
        items={timezones}
        control={control}
        label="Start Date Timezone"
      />
      <FormDateTimePicker
        rules={{ required: { value: true, message: "Start Date is a mandatory field !" } }}
        name="programSummary.startDate"
        control={control}
        label="Start Date*"
        disablePast
        timezone={watch("programSummary.localStartTimezone")}
      />
      <FormSelect
        name="programSummary.localEndTimezone"
        items={timezones}
        control={control}
        label="End Date Timezone"
      />
      <FormDateTimePicker
        rules={{
          required: { value: true, message: "End Date is a mandatory field !" },
          validate: (value) => {
            const startDate = getValues("programSummary.startDate");
            if (value && startDate) {
              return value > startDate || "End Date must be greater than Start Date";
            }
          },
        }}
        name="programSummary.endDate"
        timezone={watch("programSummary.localEndTimezone")}
        control={control}
        label="End Date*"
        disablePast
      />

      <FormSelect
        rules={{ required: { value: true, message: "Country is a mandatory field !" } }}
        name="programSummary.country"
        items={countries}
        control={control}
        label="Active Market*"
      />
      <FormSelect
        name="programSummary.ownerGroup"
        rules={{ required: { value: true, message: "Program Ownergroup is a mandatory field!" } }}
        items={ownerGroupOptions}
        control={control}
        label="Owner Group*"
      />
      <Divider className="m-5">Redemption rules</Divider>
      <div className="grid grid-cols-3 gap-4">
        <CustomInput
          name="codeRedemptionRules.validAttempts"
          rules={{
            required: { value: true, message: "This is a required field" },
            validate: (value) => value > 0 || "Valid Attempts must be a number greater than 0 !",
            pattern: {
              value: /^\d+$/,
              message: "Please enter a number",
            },
          }}
          control={control}
          label="Max valid attempts*"
        />
        <CustomInput
          name="codeRedemptionRules.validAttemptsPeriodValue"
          rules={{
            required: { value: true, message: "This is a required field" },
            validate: (value) => value > 0 || "Valid Period must be a number greater than 0 !",
            pattern: {
              value: /^\d+$/,
              message: "Please enter a number",
            },
          }}
          control={control}
          label="per *"
        />
        <CustomSelect
          rules={{ required: { value: true, message: "This is a required field" } }}
          name="codeRedemptionRules.validAttemptsPeriod"
          items={periodOptions}
          control={control}
          label="period"
        />
        <CustomInput
          name="codeRedemptionRules.inValidAttempts"
          rules={{
            required: { value: true, message: "This is a required field" },
            validate: (value) => value > 0 || "Invalid attempts must be a number greater than 0 !",
            pattern: {
              value: /^[0-9]+$/,
              message: "Please enter a number",
            },
          }}
          control={control}
          label="Max invalid attempts*"
        />
        <CustomInput
          name="codeRedemptionRules.inValidAttemptsPeriodValue"
          rules={{
            required: { value: true, message: "This is a required field" },
            validate: (value) => value > 0 || "Invalid Period must be a number greater than 0 !",
            pattern: {
              value: /^[0-9]+$/,
              message: "Please enter a number",
            },
          }}
          control={control}
          label="per *"
        />
        <CustomSelect
          rules={{ required: { value: true, message: "This is a required field" } }}
          name="codeRedemptionRules.inValidAttemptsPeriod"
          items={periodOptions}
          control={control}
          label="period"
        />
        <CustomSelect
          name="codeRedemptionRules.blockPeriod"
          items={periodOptions}
          control={control}
          label="Block attempts for period"
        />
        <CustomInput
          name="codeRedemptionRules.blockPeriodValue"
          control={control}
          label="Block attempts for"
          rules={{
            validate: (value) => {
              if (value) {
                return value > 0 || "Block attempts for must be a number greater than 0 !";
              } else {
                return true;
              }
            },
            pattern: {
              value: /^[0-9]+$/,
              message: "Please enter a number",
            },
            valueAsNumber: true,
          }}
        />
      </div>
    </>
  );
}
