import React from "react";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";

const ResultRow = ({ result }) => {
  const [open, setOpen] = React.useState(false);
  const isValidAndRedeemable = result.codeValidationDetails && !result.errorCode;

  return (
    <ul className="ml-10 mb-2 list-disc">
      {/***
       * No Error Code - Success, display the program and lot details
       * If error code exists - Show the message from response
       */}
      {result.errorCode && <li className="ml-7">{result.message}</li>}
      {isValidAndRedeemable && (
        <>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography variant="h9" className="text-green-600"gutterBottom>
            {result?.codeValidationDetails?.message ? result?.codeValidationDetails?.message : `The code ${result.codeValidationDetails.code} is valid and redeemable.`}
          </Typography>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Program Information
                </Typography>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Program name:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.programName}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Program ID:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.programId}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Program Start date:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {dayjs(result.codeValidationDetails.programStartDate).format('DD/MM/YY hh:mm')}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Program End date:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {dayjs(result.codeValidationDetails.programEndDate).format('DD/MM/YY hh:mm')}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Code Length:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.codeLength}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Max Number Of Codes:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.maxCodes}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Valid Attempts:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.validAttempts}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Invalid Attempts:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.invalidAttempts}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Batch Information
                </Typography>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Release Name:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.lot.releaseName}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Lot Name:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.lot.lotName}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Code Activation Date:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {dayjs(result.codeValidationDetails.lot.activateDate).format('DD/MM/YY hh:mm')}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Code Deactivation Date:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {dayjs(result.codeValidationDetails.lot.inactivateDate).format('DD/MM/YY hh:mm')}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Recipient Organization:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.lot.organizationName}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Lot ID:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.lot.lotId}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Active:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.lot.active ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Expired:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.lot.expired ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Release ID:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.lot.releaseId}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Organization ID:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.lot.organizationId}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Realtime Code:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.lot.realtimeCode ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Test Release:</strong>
                      </td>
                      <td style={{ paddingLeft: "90px" }}>
                        {result.codeValidationDetails.lot.testRelease ? "Yes" : "No"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Code Information
                </Typography>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Index:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>{result.codeValidationDetails.index}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Point Value:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>
                        {result.codeValidationDetails.lot.pointValue}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Trademark:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>
                        {result.codeValidationDetails.lot.bevProdPkg.trademarkName || 'None'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Brand:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>
                        {result.codeValidationDetails.lot.bevProdPkg.brandName || 'None'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Beverage:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>
                        {result.codeValidationDetails.lot.bevProdPkg.bevProdName || 'None'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Primary Conatiner:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>
                        {result.codeValidationDetails.lot.bevProdPkg.primaryContainerName || 'None'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Secondary Package:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>
                        {result.codeValidationDetails.lot.bevProdPkg.secondaryPackageName || 'None'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Closure Color:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>
                        {result.codeValidationDetails.lot.bevProdPkg.closureColorName || 'None'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Closure Diameter:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>
                        {result.codeValidationDetails.lot.bevProdPkg.closureDiameterName || 'None'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  MIXCodes
                </Typography>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Already Redeemed?:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>
                        {result.codeValidationDetails.redeemed ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>ConsumerId:</strong>
                      </td>
                      <td style={{ paddingLeft: "120px" }}>
                        {result.codeValidationDetails?.consumerId || ""}
                      </td>
                    </tr>
                    {result.codeValidationDetails.redemtionDetail && (
                      <tr>
                        <td>
                          <strong>Redeemed On:</strong>
                        </td>
                        <td style={{ paddingLeft: "120px" }}>
                          {result.codeValidationDetails.redemtionDetail.redeemedOn}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Collapse>
        </>
      )}
    </ul>
  );
};

export default ResultRow;
