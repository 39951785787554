import * as React from "react";
import FormCheckBox from "../components/FormChekBox";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import FormMultiSelect from "../components/FormMultiSelect";
const codeLength = [
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "11",
    value: 11,
  },
  {
    label: "12",
    value: 12,
  },
  {
    label: "13",
    value: 13,
  },
  {
    label: "14",
    value: 14,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "16",
    value: 16,
  },
  {
    label: "17",
    value: 17,
  },
  {
    label: "18",
    value: 18,
  },
];

const profanityCheckOptions = [
  {
    label: "DEFAULT",
    value: "1",
  },
  {
    label: "GREAT_BRITAIN",
    value: "3",
  },
  {
    label: "TURKEY",
    value: "2",
  },
];

export default function CodeDescriptor({
  control,
  errors,
  getValues,
  program,
  isNewProgram,
  watch,
}) {
  const profaneCheckEnabled = watch("codeDescriptor.profanityCheckEnabled");
  return (
    <>
      <FormInput
        name="codeDescriptor.maxCount"
        rules={{
          required: {
            value: true,
            message: '"Maximum number of codes must be a number greater than 0 !',
          },
          pattern: {
            value: /^[0-9]+$/,
            message: '"Maximum number of codes must be a number greater than 0 !',
          },
        }}
        control={control}
        label="Maximum Number of Codes*"
        type="number"
        disabled={!isNewProgram}
      />
      <FormInput
        name="codeDescriptor.prefix"
        control={control}
        label="Code Prefix"
        rules={{
          validate: (value) => {
            if (value) {
              const codeLength = getValues("codeDescriptor.length");
              return (
                value.length <= codeLength || "Code Prefix length must less than the code length"
              );
            }
          },
        }}
        disabled={!isNewProgram}
      />
      <FormSelect
        rules={{ required: { value: true, message: "Code Length is a mandatory field !" } }}
        name="codeDescriptor.length"
        items={codeLength}
        control={control}
        label="Code Length (Excludes Prefix)*"
        disabled={!isNewProgram}
      />
      <FormInput
        name="codeDescriptor.pointValue"
        rules={{
          required: { value: true, message: "Point Value is required." },
          pattern: {
            value: /^[0-9]+$/,
            message: "Please enter a number",
          },
          valueAsNumber: true,
        }}
        control={control}
        label="Default Point Value"
        disabled={!isNewProgram}
      />

      <FormCheckBox
        name="codeDescriptor.profanityCheckEnabled"
        control={control}
        label="Enable Profanity Check"
        disabled={!isNewProgram}
      />
      {profaneCheckEnabled && (
        <FormMultiSelect
          rules={{
            required: { value: profaneCheckEnabled, message: "Select a profanity dictionary" },
          }}
          name="codeDescriptor.dictionaryIds"
          items={profanityCheckOptions}
          control={control}
          label="Profanity Dictionary"
          disabled={!isNewProgram}
        />
      )}

      <FormCheckBox
        name="codeDescriptor.luhnsChecksumEnabled"
        control={control}
        label="Add Checksum Using Luhns Algorithm*"
        disabled={!isNewProgram}
      />
    </>
  );
}
