"use client";

import React, { useState } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import FormInput from "../components/FormInput";
import FormMultiSelect from "../components/FormMultiSelect";
import Loader from "../components/Loader";
import { axiosPost } from "../utils/axios";
import fetcher from "../utils/fetcher";
import Notification from "../components/Notification";
import Custom500 from "../error/500";
import ResultRow from "./ResultRow";
import { v4 as uuidv4 } from "uuid";
import Multiselect from "./Multiselect";

const defaultValues = {
  programList: [],
  codes: "",
};
const Validate = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [status, setStatus] = useState({});
  const [results, setResults] = useState([]);
  const [programList, setProgramList] = useState([]);
  const {
    data: programs,
    error,
    isLoading,
  } = useSWR(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs`, fetcher);

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  const programOptions = programs.map((program) => ({
    label: program.name,
    value: program.id,
  }));
  programOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

  const onSubmit = handleSubmit((inputs) => {
    setIsSubmitting(true);
    const codesArray = inputs.codes.split("\n");
    axiosPost(`${process.env.REACT_APP_CODE_MGMT_URL}/codes/validate`, {
      programs: programList,
      codes: codesArray,
    })
      .then((response) => {
        setStatus({
          state: "success",
          message: "Codes Validated successfully",
        });
        setResults(response.data);
      })
      .catch((error) => {
        setStatus({
          state: "error",
          message: "Codes validation error",
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });
  return (
    <>
      <Paper className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start" elevation={6}>
        <p className="text-xl ml-5 mb-3 mt-3">Validate Codes</p>
      </Paper>
      <Paper className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start" elevation={6}>
        <form>
          <Multiselect
            options={programOptions}
            label="Programs"
            setProgramList={setProgramList}
          />
          <FormInput
            name="codes"
            rules={{
              required: { value: true, message: "Please enter a code to validate!" },
            }}
            control={control}
            label="Code"
            multiline={{ enabled: true, rows: 3 }}
          />
          <Button className="m-5" disabled={isSubmitting} variant="contained" onClick={onSubmit}>
            Validate Code
          </Button>
        </form>
        {isSubmitting && <Loader open={isSubmitting} />}

        {status.state && (
          <Notification
            message={status.message}
            type={status.state}
            open={true}
            handleClose={() => setStatus({})}
          />
        )}
      </Paper>
      <Paper className="ml-auto justify-start mb-3 mt-3" elevation={3}>
        <Typography className="text-xl pt-5 ml-5 mb-3">Validation Results</Typography>
        {results && results?.map((result) => <ResultRow key={uuidv4()} result={result} />)}
      </Paper>
    </>
  );
};

export default Validate;
