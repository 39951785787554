"use client";

import Paper from "@mui/material/Paper";
import React from "react";
import QueuedRequestsTable from "./QueuedRequestsTable";

function QueuedRequests() {
  return (
    <>
      <Paper className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start" elevation={ 6 }>
        <p className="text-xl ml-5 mb-5">Queued Requests</p>
      </Paper>
      <QueuedRequestsTable />
    </>
  );
}

export default QueuedRequests;
