"use client";

import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";
import FormDateTimePicker from "../components/FormDateTimePicker";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { axiosPost, axiosPut } from "../utils/axios";
import fetcher from "../utils/fetcher";
import Custom500 from "../error/500";
import dayjs from "dayjs";
import timezones from "../utils/timezones";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const defaultValues = {
  id: "",
  name: "",
  desc: "",
  startDate: null,
  endDate: null,
  programId: "",
  localStartTimezone: "UTC",
  localEndTimezone: "UTC"
};

export default function PromotionForm({ open, handleClose, editMode, promotion }) {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState({});
  const {
    data: promotionData,
    error,
    isLoading,
  } = useSWR(
    editMode
      ? `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs/${promotion.programId}/promotions/${promotion.id}`
      : null,
    fetcher,
  );

  const {
    data: programs,
    error: programError,
    isLoading: isProgramLoading,
  } = useSWR(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs`, fetcher);


  useEffect(() => {
    if (promotionData) {
      reset({
        id: promotionData.id,
        name: promotionData.name,
        desc: promotionData.desc,
        startDate: dayjs(promotionData.startDate),
        endDate:  dayjs(promotionData.endDate),
        programId: promotionData.programId,
        localStartTimezone: promotionData.localStartTimezone || "UTC",
        localEndTimezone: promotionData.localEndTimezone || "UTC"
      });
    }
  }, [open, promotionData]);

  // Save new promotion
  const onSubmit = handleSubmit((inputs) => {
    editMode ? updatePromotion(inputs) : createPromotion(inputs);
  });

  function createPromotion(payload) {
    setIsSubmitting(true);
    axiosPost(
      `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs/${payload.programId}/promotions`,
      payload,
    )
      .then(({ data }) => {
        console.log(data);
        setStatus({
          state: "success",
          message: "Promotion created successfully",
        });
      })
      .catch((error) => {
        console.log(error);
        setStatus({ state: "error", message: "Unable to create promotion" });
        const message = error.response.data.message.split(".");
        setStatus({ state: "error", message: message[4] || "Unable to create promotion" });
      })
      .finally(() => {
        closeForm();
      });
  }

  function updatePromotion(payload) {
    setIsSubmitting(true);
    axiosPut(
      `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs/${payload.programId}/promotions/${payload.id}`,
      payload,
    )
      .then(({ data }) => {
        console.log(data);
        setStatus({
          state: "success",
          message: "Promotion updated successfully",
        });
      })
      .catch((error) => {
        console.log(error);
        setStatus({ state: "error", message: "Unable to update promotion" });
        const message = error.response.data.message.split(".");
        setStatus({ state: "error", message: message[4] || "Unable to update promotion" });
      })
      .finally(() => {
        closeForm();
      });
  }

  if (error || programError) return <Custom500 statusCode={500} />;
  if (isLoading || isProgramLoading) return <Loader open={isLoading} />;
  let programOptions  = []
  if (programs) {
    programOptions = programs.map((program) => ({
      label: program.name,
      value: program.id,
    }));
  }
  programOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

  const closeForm = () => {
    reset(defaultValues);
    handleClose();
    setIsSubmitting(false);
    mutate(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/promotions`);
  };

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeForm} aria-label="close">
              <CloseIcon />
            </IconButton>
            {editMode ? (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Promotion #: {promotion.name}
              </Typography>
            ) : (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Create New Promotion
              </Typography>
            )}
          </Toolbar>
        </AppBar>{" "}
        <DialogContent>
          <form>
            <FormInput
              name="name"
              rules={{
                required: { value: true, message: "Promotion Name is Mandatory " },
              }}
              control={control}
              label="Promotion Name"
            />
            <FormSelect
              rules={{
                required: { value: true, message: "Please select Program" },
              }}
              name="programId"
              items={programOptions}
              control={control}
              label="Parent Program"
            />
            <FormInput
              name="desc"
              rules={{
                required: { value: true, message: "Description is required." },
                maxLength: 500,
              }}
              control={control}
              label="Promotion Description"
            />
            <FormSelect
              name="localStartTimezone"
              items={timezones}
              control={control}
              label="Start Date Timezone"
            />
            <FormDateTimePicker
              rules={{
                required: { value: true, message: "Start Date is a mandatory field !" },
              }}
              name="startDate"
              control={control}
              label="Start Date"
              disablePast
              timezone={watch("localStartTimezone")}
            />
            <FormSelect
              name="localEndTimezone"
              items={timezones}
              control={control}
              label="End Date Timezone"
            />
            <FormDateTimePicker
              rules={{
                required: { value: true, message: "End Date is a mandatory field !" },
              }}
              name="endDate"
              control={control}
              label="End Date"
              disablePast
              timezone={watch("localEndTimezone")}
            />
          </form>
          {isSubmitting && <Loader open={isSubmitting} />}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeForm}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} variant="contained" onClick={onSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
    </>
  );
}
