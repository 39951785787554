import React from "react";
import Campaigns from "./Campaigns";

export default function CampaignPanel(props) {
  const { children, value, index, programId, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `full-width-tabpanel-${index}` }
      aria-labelledby={ `full-width-tab-${index}` }
      { ...other }
    >
      { value === index && <Campaigns className="minh-100" programId={ programId }/> }
    </div>
  );
}
