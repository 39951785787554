"use client";

import React from "react";
import ReportResult from "../../ReportResult";
import Paper from "@mui/material/Paper";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);

const columns = [
  {
    field: "organizationName",
    headerName: "Organization",
    width: 250,
    minWidth: 250,
  },
  {
    field: "createdAt",
    headerName: "Creation Date",
    width: 100,
    minWidth: 100,
    valueFormatter: (params) => dayjs.utc(params?.value).format("MM/DD/YYYY"),
  },
  {
    field: "createdby",
    headerName: "Created By",
    width: 250,
    minWidth: 250,
  },
  {
    field: "code",
    headerName: "Code",
    width: 250,
    minWidth: 250,
  },
  {
    field: "consumerid",
    headerName: "Consumer",
    width: 250,
    minWidth: 250,
  },
  {
    field: "errorcode",
    headerName: "Status",
    width: 250,
    minWidth: 250,
  },
];

function RealtimeReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const country = searchParams.get('country');
  const programId = searchParams.get('programId');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const ownerGroup = searchParams.get('ownerGroup');

  const url = `${process.env.REACT_APP_REPORT_URL}/report/codegen/realtime?country=${country}&programId=${programId}&startDate=${startDate}&endDate=${endDate}&ownerGroup=${ownerGroup}`;
  return (
    <>
      <Paper
        className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start"
        elevation={6}
      >
        <p className="text-xl ml-5 mb-5">Realtime Code Generation Report</p>
      </Paper>
      <ReportResult programId={programId} columns={columns} url={url} programDetailsEnabled={true} />
    </>
  );
}

export default RealtimeReport;
