import axios from "axios";
import { getIdToken, getImpersonatedUser } from "./session";

export const axiosPost = async (url, payload) => {
  const idToken = await getIdToken();
  return  axios
    .post(
      url,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`
        }
      }
    );
};

export const axiosPut = async (url, payload) => {
  const idToken = await getIdToken();
  return  axios
    .put(
      url,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`
        }
      }
    );
};

export const axiosPostMultipart = async (url, formData) => {
  const idToken = await getIdToken();
  return  axios
    .post(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }
    );
};
export const axiosGet = async (url) => {
  const idToken = await getIdToken();
  return  axios
    .get(
      url,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`
        }
      }
    );
};
