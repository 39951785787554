import * as React from "react";
import Edit from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export const EditIconButton = ({ onClick }) => (
  <IconButton onClick={onClick} aria-label="edit" size="large" color="success">
    <Tooltip title="Edit">
      <Edit fontSize="inherit" />
    </Tooltip>
  </IconButton>
);

export const EditButton = ({ onClick }) => (
  <Button startIcon={<Edit />} onClick={onClick} variant="contained" color="success" size="small">
    Edit
  </Button>
);
