"use client";

import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";
import CharacterSetForm from "./CharacterSetForm";
import CodeDescriptor from "./CodeDescriptor";
import {
  defaultValues,
  numbersPayload,
  lowerCaseAlphabetsPayload,
  upperCaseAlphabetsPayload,
} from "./programDefaultValues";
import SummaryForm from "./SummaryForm";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { axiosPost, axiosPut } from "../utils/axios";
import fetcher from "../utils/fetcher";
import RiskChecker from "./RiskChecker";
import { useNavigate } from "react-router-dom";
import { charSetSanitize, validateCharAndSynonym } from "./ProgramUtils";
import Custom500 from "../error/500";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default function ProgramForm({
  open,
  handleClose,
  isNewProgram,
  program,
  editMode,
  members,
  ownerGroup,
}) {
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
    watch,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
  });
  const navigatge = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState({});
  const [expandDetails, setExapandDetails] = React.useState(true);
  const [expandDescriptor, setExpandDescriptor] = React.useState(true);
  const [charsetError, setCharsetError] = React.useState("");

  const {
    data: programData,
    error,
    isLoading,
  } = useSWR(
    !isNewProgram ? `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs/${program.id}` : null,
    fetcher,
  );

  useEffect(() => {
    if (!isNewProgram && programData) {
      const fields = [
        "programSummary.id",
        "programSummary.name",
        "programSummary.desc",
        "programSummary.startDate",
        "programSummary.endDate",
        "programSummary.country",
        "programSummary.ownerGroup",
        "programSummary.localStartTimezone",
        "programSummary.localEndTimezone",
        "codeDescriptor.profanityCheckEnabled",
        "codeDescriptor.dictionaryIds",
        "codeDescriptor.length",
        "codeDescriptor.prefix",
        "codeDescriptor.maxCount",
        "codeDescriptor.luhnsChecksumEnabled",
        "codeDescriptor.pointValue",
        "codeDescriptor.characterSet.numbers",
        "codeDescriptor.characterSet.upperCaseAlphabets",
        "codeDescriptor.characterSet.lowerCaseAlphabets",
        "codeRedemptionRules.validAttemptsPeriodValue",
        "codeRedemptionRules.inValidAttempts",
        "codeRedemptionRules.inValidAttemptsPeriodValue",
        "codeRedemptionRules.validAttempts",
        "codeRedemptionRules.validAttemptsPeriod",
        "codeRedemptionRules.inValidAttemptsPeriod",
        "codeRedemptionRules.blockPeriod",
        "codeRedemptionRules.blockPeriodValue",
        "members",
      ];
      fields.forEach((field) => {
        if (field.includes("programSummary")) {
          const key = field.split(".")[1];
          if (key.includes("Date")) {
            setValue(field, dayjs.utc(programData.programSummary[key]));
          } else {
            setValue(field, programData.programSummary[key]);
          }
        }
        if (field.includes("codeDescriptor")) {
          const key = field.split(".")[1];
          setValue(field, programData.codeDescriptor[key]);
        }
        if (field.includes("dictionaryIds")) {
          const key = field.split(".")[1];
          if (programData.codeDescriptor[key]) {
            setValue(field, programData.codeDescriptor[key]);
          } else {
            setValue(field, []);
          }
        }
        if (field.includes("codeRedemptionRules")) {
          const key = field.split(".")[1];
          setValue(field, programData.codeRedemptionRules[key]);
        }
        if (field.includes("characterSet")) {
          const key = field.split(".")[2];
          if (key === "numbers") {
            const editValues = programData.codeDescriptor.characterSet[key]; //First Array
            editValues.forEach((value) => {
              value.checked = true;
            });
            const filtered = numbersPayload.filter((item) => {
              return !editValues.some((editItem) => editItem.code === item.code);
            });
            const numOptions = editValues.concat(filtered); //mergeCharSet(numbersPayload, editValues); //Default Values
            setValue(
              field,
              numOptions.sort((a, b) => (a.code > b.code ? 1 : -1)),
            );
          }
          if (key === "upperCaseAlphabets") {
            const editValues = programData.codeDescriptor.characterSet[key];
            editValues.forEach((value) => {
              value.checked = true;
            });
            const filtered = upperCaseAlphabetsPayload.filter((item) => {
              return !editValues.some((editItem) => editItem.code === item.code);
            });
            const ucOptions = editValues.concat(filtered);
            setValue(
              field,
              ucOptions.sort((a, b) => (a.code > b.code ? 1 : -1)),
            );
          }
          if (key === "lowerCaseAlphabets") {
            const editValues = programData.codeDescriptor.characterSet[key];
            editValues.forEach((value) => {
              value.checked = true;
            });
            const filtered = lowerCaseAlphabetsPayload.filter((item) => {
              return !editValues.some((editItem) => editItem.code === item.code);
            });
            const lcOptions = editValues.concat(filtered);
            setValue(
              field,
              lcOptions.sort((a, b) => (a.code > b.code ? 1 : -1)),
            );
          }
        }
      });
    }
  }, [open, programData]);

  const handleDetailsChange = () => (event, isExpanded) => {
    setExapandDetails(!expandDetails);
  };
  const handleDescriptorChange = () => (event, isExpanded) => {
    setExpandDescriptor(!expandDescriptor);
  };

  const onSubmit = handleSubmit((inputs) => {
    inputs.codeDescriptor.maxCount = Number(inputs.codeDescriptor.maxCount);
    inputs.codeDescriptor.pointValue = Number(inputs.codeDescriptor.pointValue);
    inputs.codeRedemptionRules.validAttempts = Number(inputs.codeRedemptionRules.validAttempts);
    inputs.codeRedemptionRules.validAttemptsPeriodValue = Number(
      inputs.codeRedemptionRules.validAttemptsPeriodValue,
    );
    inputs.codeRedemptionRules.inValidAttempts = Number(inputs.codeRedemptionRules.inValidAttempts);
    inputs.codeRedemptionRules.inValidAttemptsPeriodValue = Number(
      inputs.codeRedemptionRules.inValidAttemptsPeriodValue,
    );
    isNewProgram ? createProgram(inputs) : updateProgram(inputs);
  });

  function createProgram(payload) {
    const santizedCharSet = charSetSanitize(payload.codeDescriptor.characterSet);
    payload.codeDescriptor.characterSet = santizedCharSet;
    if (
      santizedCharSet.numbers.length === 0 &&
      santizedCharSet.lowerCaseAlphabets.length === 0 &&
      santizedCharSet.upperCaseAlphabets.length === 0
    ) {
      setCharsetError(
        "⚠️ Character set should not be empty, please select the character set for code generation!!",
      );
    } else if (validateCharAndSynonym(santizedCharSet)) {
      setCharsetError(
        "⚠️ Invalid combination of characters and synonyms are entered. Please correct and create the program",
      );
    } else {
      setCharsetError("");
      setIsSubmitting(true);
      axiosPost(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs`, payload)
        .then(({ data }) => {
          console.log(data);
          setStatus({
            state: "success",
            message: "Program created successfully",
          });
          closeForm();
        })
        .catch((error) => {
          console.log(error);
          setStatus({ state: "error", message: "Unable to create program" });
          const message = error.response?.data?.message;
          setStatus({
            state: "error",
            message: `Unable to create program, please check your inputs - ${message}`,
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  }

  function updateProgram(payload) {
    setIsSubmitting(true);
    const santizedCharSet = charSetSanitize(payload.codeDescriptor.characterSet);
    payload.codeDescriptor.characterSet = santizedCharSet;
    if (
      santizedCharSet.numbers.length === 0 &&
      santizedCharSet.lowerCaseAlphabets.length === 0 &&
      santizedCharSet.upperCaseAlphabets.length === 0
    ) {
      setCharsetError(
        "⚠️ Character set should not be empty, please select the character set for code generation!!",
      );
    } else {
      setCharsetError("");
      axiosPut(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs/${program.id}`, payload)
        .then(({ data }) => {
          console.log(data);
          setStatus({
            state: "success",
            message: "Program updated successfully",
          });
          closeForm();
          navigatge(0);
        })
        .catch((error) => {
          console.log(error);
          setStatus({ state: "error", message: "Unable to update program" });
          const message = error.response?.data?.message;
          setStatus({
            state: "error",
            message: `Unable to update program, please check your inputs - ${message}`,
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  }

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;

  const closeForm = () => {
    reset({});
    handleClose();
    setIsSubmitting(false);
    mutate(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs`);
    return;
  };

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={closeForm}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeForm} aria-label="close">
              <CloseIcon />
            </IconButton>
            {!isNewProgram ? (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Program #: {program.name}
              </Typography>
            ) : (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Create New Program
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form>
            <Accordion
              expanded={expandDetails}
              onChange={handleDetailsChange()}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  STEP 1 - PROGRAM DETAILS
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Please provide program details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SummaryForm
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  watch={watch}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandDescriptor}
              onChange={handleDescriptorChange()}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  STEP 2 - CODE DESCRIPTOR
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>Provide code description</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CodeDescriptor
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  program={program}
                  isNewProgram={isNewProgram}
                  watch={watch}
                />
                <RiskChecker getValues={getValues} />
                <Divider className="m-5">Character set configuration</Divider>
                {charsetError && <p className="text-center text-red-500">{charsetError}</p>}
                <CharacterSetForm
                  control={control}
                  watch={watch}
                  program={program}
                  isNewProgram={isNewProgram}
                  setValue={setValue}
                  trigger={trigger}
                  getValues={getValues}
                />
              </AccordionDetails>
            </Accordion>
          </form>
          {isSubmitting && <Loader open={isSubmitting} />}
        </DialogContent>
        <DialogActions>
          {JSON.stringify(errors) !== "{}" && (
            <p className="text-center text-red-500">
              There are errors in form fields, please check
            </p>
          )}
          <Button variant="contained" onClick={closeForm}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {status.state && (
        <Notification
          message={status.message}
          type={status.state}
          open={true}
          handleClose={() => setStatus({})}
        />
      )}
    </>
  );
}
