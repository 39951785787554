"use client";

import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import useSWR from "swr";
import Loader from "../../../components/Loader";
import countries from "../../../utils/countries";
import fetcher from "../../../utils/fetcher";
import { useGlobalState } from "../../../utils/GlobalStateContext";
import Custom500 from "../../../error/500";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);

export default function CodeVolumeOrgForm() {
  const { state: globalState, dispatch } = useGlobalState();
  const { ownerGroups } = globalState;
  const [country, setCountry] = React.useState("");
  const [programId, setProgramId] = React.useState("");
  const [orgId, setOrgId] = React.useState("");
  const [ownerGroup, setOwnerGroup] = React.useState("");

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleProgramChange = (event) => {
    setProgramId(event.target.value);
  };
  const handleOrgChange = (event) => {
    setOrgId(event.target.value);
  };
  const handleOwnerGroupChange = (event) => {
    setOwnerGroup(event.target.value);
  };

  const {
    data: programs,
    error: programError,
    isLoading: isProgramLoading
  } = useSWR(
    `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs?country=${country}`,
    fetcher
  );

  const {
    data: orgs,
    error: orgsError,
    isLoading: isOrgLoading
  } = useSWR(`${process.env.REACT_APP_DIST_URL}/orgs`, fetcher);
  if (programError || orgsError) return <Custom500 statusCode={500} />;
  if (isProgramLoading || isOrgLoading)
    return <Loader open={ isProgramLoading || isOrgLoading } />;
  const programOptions = programs.map((program) => ({
    label: program.name,
    value: program.id
  }));
  programOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

  const orgOptions = orgs.map((org) => ({ label: org.name, value: org.id }));
  const handleRunReport = () => {
    window.open(
      `/reports/codegen/codevolume?country=${country}&programId=${programId}&orgId=${orgId}&ownerGroup=${ownerGroup}`,
      "_blank"
    );
  };
  return (
    <form onSubmit={ handleRunReport }>
      <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">Country</span>
        <Select
          className="w-96 h-10"
          labelId="report-country-select-id"
          id="report-country-select-id"
          value={ country }
          label="Country"
          onChange={ handleCountryChange }
        >
          { countries.map((country) => (
            <MenuItem key={ country.value } value={ country.value }>
              { country.label }
            </MenuItem>
          )) }
        </Select>
      </div>
      <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">Program</span>
        <Select
          className="w-96 h-10"
          labelId="report-program-id"
          id="report-program-id"
          value={ programId }
          label="Program"
          onChange={ handleProgramChange }
        >
          { programOptions.map((programOption) => (
            <MenuItem key={ programOptions.value } value={ programOption.value }>
              { programOption.label }
            </MenuItem>
          )) }
        </Select>
      </div>
      <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">Organization</span>
        <Select
          className="w-96 h-10"
          labelId="report-org-id"
          id="report-org-id"
          value={ orgId }
          label="Organization"
          onChange={ handleOrgChange }
        >
          { orgOptions.map((org) => (
            <MenuItem key={ org.value } value={ org.value }>
              { org.label }
            </MenuItem>
          )) }
        </Select>
      </div>
      {/* <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">Owner Group</span>
        <Select
          className="w-96 h-10"
          labelId="report-ownergroup-id"
          id="report-ownergroup-id"
          value={ ownerGroup }
          label="Program"
          onChange={ handleOwnerGroupChange }
        >
          { ownerGroups.map((ownerGroupItem) => (
            <MenuItem key={ ownerGroupItem.value } value={ ownerGroupItem.value }>
              { ownerGroupItem.label }
            </MenuItem>
          )) }
        </Select>
      </div> */}
      <Button type="submit" variant="contained">
        Run Report
      </Button>
    </form>
  );
}
