"use client";

import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ProgramForm from "./ProgramForm";
import DeleteDialog from "./DeleteDialog";
import { EditIconButton } from "../components/EditButton";
import Loader from "../components/Loader";
import useSWR, { mutate } from "swr";
import Custom500 from "../error/500";
import fetcher from "../utils/fetcher";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export default function ProgramTableGrid() {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleProgramClick = (params, event, details) => {
    navigate(`/release?programid=${params.id}`);
  };
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs`,
    fetcher,
  );

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  // let rows = [];
  // if (data) {

  // }
  const columns = [
    {
      field: "edit",
      headerName: "",
      sortable: false,
      width: 75,
      minWidth: 75,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const row = params.row;
          mutate(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs/${row.id}`);
          setSelectedRow(row);
          setEditMode(true);
          setOpen(true);
        };

        return <EditIconButton onClick={onClick} />;
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 300,
      minWidth: 300,
    },
    {
      field: "name",
      headerName: "Name",
      width: 350,
      minWidth: 350,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 200,
      minWidth: 200,
      valueGetter: (params) =>
        dayjs(params?.value).tz(params.row?.localStartTimezone).format("MM/DD/YYYY"),
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 200,
      minWidth: 200,
      valueGetter: (params) =>
        dayjs(params?.value).tz(params.row?.localEndTimezone).format("MM/DD/YYYY"),
    },
    {
      field: "maxCount",
      headerName: "Total Codes",
      width: 150,
      minWidth: 100,
      valueGetter: (params) => params.row.codeDescriptor.maxCount
    },
    {
      field: "allocatedCodes",
      headerName: "Used Codes",
      width: 100,
      minWidth: 150,
    },
    {
      field: "availableCodes",
      headerName: "Available Codes",
      width: 100,
      minWidth: 150,
    },
    {
      field: "availableReleaseCount",
      headerName: "Releases",
      width: 100,
      minWidth: 100,
    },
    {
      field: "createdBy",
      headerName: "Created By",
    },
    {
      field: "createdAt",
      headerName: "Created At",
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   sortable: false,

    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       const row = params.row;
    //       mutate(
    //         `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs/${row.id}`
    //       );
    //       setSelectedRow(row);
    //       setOpenDeleteDialog(true);
    //     };

    //     return <DeleteIconButton onClick={onClick} />;
    //   },
    // },
    // {
    //   field: "manage",
    //   headerName: "Manage",
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       const row = params.row;
    //       setSelectedRow(row);
    //       setOpenDeleteDialog(true);
    //     };

    //     return (
    //       <Link
    //         to={{
    //           pathname: "/release",
    //           search: `?programid=${params.row.id || params.row.prgId}`,
    //         }}
    //       >
    //         <Button variant="contained"> Manage </Button>
    //       </Link>
    //     );
    //   },
    // },
  ];
  return (
    <>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          }
        }}
        sx={{
          // disable cell selection style
          ".v5-MuiDataGrid-cell:focus": {
            outline: "none",
          },
          // pointer cursor on ALL rows
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
        columnVisibilityModel={{
          createdAt: false,
        }}
        disableColumnSelector
        rows={data}
        getRowId={(row) => row.id}
        columns={columns}
        checkboxSelection={false}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[10, 25, 100]}
        onRowClick={handleProgramClick} // here
      />
      <ProgramForm
        open={open}
        handleClose={handleClose}
        isNewProgram={!editMode}
        program={selectedRow}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        program={selectedRow}
      />
    </>
  );
}
