"use client";

import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import useSWR from "swr";
import Loader from "../../../components/Loader";
import countries from "../../../utils/countries";
import fetcher from "../../../utils/fetcher";
import { useGlobalState } from "../../../utils/GlobalStateContext";
import Custom500 from "../../../error/500";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default function TopCodeRedemptionForm() {
  const { state: globalState, dispatch } = useGlobalState();
  const { ownerGroups } = globalState;
  const [country, setCountry] = React.useState("");
  const [programId, setProgramId] = React.useState("");
  const [reportDuration, setReportDuration] = React.useState(null);
  const [reportDate, setReportDate] = React.useState(dayjs().add(1, "day").format("YYYY-MM-DD"));
  const [ownerGroup, setOwnerGroup] = React.useState("daily");

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleProgramChange = (event) => {
    setProgramId(event.target.value);
  };
  const handleReportDurationChange = (event) => {
    setReportDuration(event.target.value);
  };
  const handleReportDateChange = (value) => {
    setReportDate(dayjs.utc(value).format("YYYY-MM-DD"));
  };
  const handleOwnerGroupChange = (event) => {
    setOwnerGroup(event.target.value);
  };
  const {
    data: programs,
    error: programError,
    isLoading: isProgramLoading,
  } = useSWR(`${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs?country=${country}`, fetcher);

  if (programError) return <Custom500 statusCode={500} />;
  if (isProgramLoading) return <Loader open={isProgramLoading} />;
  const programOptions = programs.map((program) => ({
    label: program.name,
    value: program.id,
  }));
  programOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

  const handleRunReport = () => {
    window.open(
      `/reports/redemption/topconsumer?programId=${programId}&reportDuration=${reportDuration}&reportDate=${reportDate}&ownerGroup=${ownerGroup}`,
      "_blank",
    );
  };
  return (
    <form onSubmit={handleRunReport}>
      <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">Country</span>
        <Select
          className="w-96 h-10"
          labelId="report-country-select-id"
          id="report-country-select-id"
          value={country}
          label="Country"
          onChange={handleCountryChange}
        >
          {countries.map((country) => (
            <MenuItem key={country.value} value={country.value}>
              {country.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">Program</span>
        <Select
          className="w-96 h-10"
          labelId="report-program-id"
          id="report-program-id"
          value={programId}
          label="Program"
          onChange={handleProgramChange}
          required
        >
          {programOptions.map((programOption) => (
            <MenuItem key={programOptions.value} value={programOption.value}>
              {programOption.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">Report Duration</span>
        <RadioGroup
          className="w-96 h-10"
          row
          name="report-duration-radio-buttons-group"
          onChange={handleReportDurationChange}
        >
          <FormControlLabel value="daily" control={<Radio checked/>} label="Daily" />
          <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
        </RadioGroup>
      </div>
      <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">Report Date</span>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            slotProps={{ textField: { size: "small" } }}
            format="MM/DD/YYYY"
            className="w-96 h-10"
            autoOk
            onChange={handleReportDateChange}
            defaultValue={dayjs().add(1, "day")} 
          />
        </LocalizationProvider>
      </div>
      {/* <div className="flex gap-x-24 p-2">
        <span className="p-1 w-64">Owner Group</span>
        <Select
          className="w-96 h-10"
          labelId="report-ownergroup-id"
          id="report-ownergroup-id"
          value={ownerGroup}
          label="Program"
          onChange={handleOwnerGroupChange}
        >
          {ownerGroups.map((ownerGroupItem) => (
            <MenuItem key={ownerGroupItem.value} value={ownerGroupItem.value}>
              {ownerGroupItem.label}
            </MenuItem>
          ))}
        </Select>
      </div> */}
      <Button type="submit" variant="contained">
        Run Report
      </Button>
    </form>
  );
}
