"use client";

import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../components/Loader";
import useSWR, { mutate } from "swr";
import Custom500 from "../error/500";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import fetcher from "../utils/fetcher";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import Popover from "@mui/material/Popover";
import { axiosGet } from "../utils/axios";

export default function DownloadCodesTable() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [downloadLink, setDownloadLink] = React.useState("");

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const [paginationModel1, setPaginationModel1] = useState({
    pageSize: 10,
    page: 0,
  });

  const [paginationModel2, setPaginationModel2] = useState({
    pageSize: 10,
    page: 0,
  });

  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_DIST_URL}/batch-items/distributions`,
    fetcher,
  );

  // Check if data is undefined or null
  if (!data && isLoading) return <Loader open={isLoading} />;
  if (error) return <Custom500 statusCode={500} />;

  // Filter data based on fileDownloaded value
  const fileDownloaded1 = data ? data.filter((item) => item.fileDownloaded) : [];
  const fileDownloaded0 = data ? data.filter((item) => !item.fileDownloaded) : [];

  const columns = [
    {
      field: "fileName",
      headerName: "File Name",
      width: 350,
      minWidth: 350,
    },
    {
      field: "noOfCodes",
      headerName: "Codes",
      width: 100,
      minWidth: 100,
    },
    {
      field: "id",
      headerName: "File#",
      width: 300,
      minWidth: 300,
    },
    {
      field: "createdAt",
      headerName: "Created On (UTC)",
      width: 200,
      minWidth: 200,
      valueFormatter: (params) => dayjs(params?.value).format("MM/DD/YYYY T HH:MM:ss"),
    },
    {
      field: "fileURL",
      headerName: "Download Link",
      sortable: false,
      width: 200,
      minWidth: 200,
      renderCell: (params) => {
        const handleClick = (event) => {
          event.stopPropagation(); // don't select this row after clicking
          const row = params.row;
          axiosGet(
            `${process.env.REACT_APP_DIST_URL}/batch-items/distributions/${row.id}/fileurl`,
          ).then((response) => {
            const url = response.data.preSignedUrl;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${params.row.fileName}`);
            document.body.appendChild(link);
            link.click();
          });
          // fetcher(`${process.env.REACT_APP_DIST_URL}/batch-items/distributions/${row.id}/fileurl`);
        };

        return (
          <div>
            <Button variant="contained" color="warning" onClick={handleClick}>
              Download
            </Button>
          </div>
        );
      },
    },
  ];
  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;
  return (
    <>
      <Paper className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start" elevation={6}>
        <p className="text-xl ml-5 mb-3 mt-3">Files Not Downloaded</p>
      </Paper>
      <DataGrid
        rows={fileDownloaded0}
        className="h-96"
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          }
        }}
        getRowId={(row) => row.id}
        columns={columns}
        onSelectionModelChange={(itm) => console.log(itm)}
        autosizeOptions={{
          columns: ["code", "name", "description"],
          includeOutliers: true,
          includeHeaders: false,
        }}
        paginationModel={paginationModel1}
        onPaginationModelChange={setPaginationModel1}
      />
      <Paper
        className="mt-5 mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start"
        elevation={6}
      >
        <p className="text-xl ml-5 mb-3 mt-3">Files Already Downloaded</p>
      </Paper>
      <DataGrid
        rows={fileDownloaded1}
        getRowId={(row) => row.id}
        className="h-96"
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          }
        }}
        columns={columns}
        onSelectionModelChange={(itm) => console.log(itm)}
        autosizeOptions={{
          columns: ["code", "name", "description"],
          includeOutliers: true,
          includeHeaders: false,
        }}
        paginationModel={paginationModel2}
        onPaginationModelChange={setPaginationModel2}
      />
    </>
  );
}
